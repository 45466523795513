import { Request } from "../../hooks/Request";
import logotipo from "../../assets/LogotipoTC.png";
import { Circle } from "../../components/Animation";
import View from "../../components/GeneratePdf/View";
import { H2, H3, Texto } from "../../components/Text";
import { Button } from "../../components/Buttons";
import TableComplete from "../../components/Tables/TableComplete";
import { validateArray } from "../../utils/validate";
import Text from "../../components/GeneratePdf/Text";
import FormDefault from "../../components/Forms/FormDefault";
import { Page, Document, PDFViewer, Image, pdf } from "@react-pdf/renderer";
import CustomTablePDF from "../../components/GeneratePdf/CustomTablePDF";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { requestAuth } from "../../components/services/RequestService";
import ListDocument from "../../components/common/ListDocument";
import dayjs from "dayjs";
import Loading from "../../components/Animation/Loading";

const GenerateFileDelivery = (props) => {
  const { openModal, procedure } = props;
  function handleListDocuments(values) {
    if (values.atn_isNew) {
      handleAddContact(values, procedure.companyId);
    }
    openModal(
      <ListDocuments atn={values.atn} phone={values.phone} {...props} />
    );
  }

  async function handleAddContact(values, companyId) {
    await requestAuth("post", "/company/contact", {
      name: values.atn,
      CompanyId: companyId,
      phone: values.phone,
    });
  }

  return (
    <div>
      <FormDefault
        tittle="Datos de la entrega de documentos"
        fields={[
          {
            label: "Atención",
            name: "atn",
            typeInput: "select",
            typeInput: "selectNewOption",
            urlApi: `/company/${procedure.companyId}/contacts`,
            value: "name",
            labelOption: "name",
          },
          {
            label: "Teléfono",
            name: "phone",
            reference: "atn_isNew",
            condition: "true",
          },
        ]}
        initialValues={{
          atn: "",
          phone: "",
        }}
        validationSchema={Yup.object().shape({
          atn: Yup.string().required("Campo requerido"),
        })}
        onSubmit={(values) => handleListDocuments(values)}
        buttonName="Registrar"
      />
      <br />
    </div>
  );
};

const ListDocuments = (props) => {
  const { procedure, openModal, onClose, openDialog, dialogClose, atn, phone } =
    props;
  const { data, loading, call, setData } = Request({
    urlApi: `/tradecruz/${
      procedure.id || procedure.procedureId
    }/entregaDocumentos`,
  });
  const { data: dataContact, loading: loadingContact } = Request({
    urlApi: `/company/${procedure.companyId}/contacts`,
  });

  function handleDocument() {
    onClose();
    openDialog(
      <FileDelivery
        {...props}
        data={data}
        procedure={procedure}
        attention={
          dataContact.find((item) => item.name === atn) || {
            name: atn,
            phone: phone,
          }
        }
      />
    );
  }
  function handleForm(data) {
    openDialog(
      <FormAdd
        data={data}
        onClose={dialogClose}
        reload={call}
        procedureId={procedure.id || procedure.procedureId}
      />
    );
  }

  function handleDelete(document) {
    handleOnSubmit(document);
    // openDialog(
    //   <>
    //     <H3>¿Estas seguro que quieres quitar el archivo?</H3>
    //     <H3 className="font-normal">
    //       El archivo no se eliminara del tramite solo no se mostrara en la
    //       declaración jurada.
    //     </H3>
    //     <div className="flex gap-4 m-4">
    //       <Button
    //         onClick={dialogClose}
    //         className="w-full bg-[#fff] text-[#FBCE00] border-2 border-[#FBCE00]"
    //       >
    //         Cancelar
    //       </Button>
    //       <Button onClick={() => handleOnSubmit(document)} className="w-full">
    //         Quitar
    //       </Button>
    //     </div>
    //   </>
    // );
  }

  function handleOnSubmit(document) {
    const listDocument = data.historialReceptionVerificationDocuments.filter(
      (doc) => doc.counter !== document.counter
    );
    setData((a) => ({
      ...a,
      historialReceptionVerificationDocuments: listDocument,
    }));
    dialogClose();
  }
  if (!loadingContact) {
    return <Circle />;
  }
  if (!loading || data === undefined) {
    return <Circle />;
  }

  const AtnData = ({ attention = { name: atn, phone: phone } }) => {
    return (
      <>
        <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px]  font-normal text-white shadow-xl">
          <strong className="font-semibold  text-black">ATN:</strong>{" "}
          {attention.name}
        </Texto>
        <Texto className="bg-slate-400 p-[10px] rounded-md my-[15px]  font-normal text-white shadow-xl">
          <strong className="font-semibold  text-black">TELÉFONO:</strong>{" "}
          {attention.phone}
        </Texto>
      </>
    );
  };

  function handleAddDocument() {
    openModal(
      <>
        <div className="flex gap-2 items-center mb-2">
          <Button
            type="button"
            className="px-3 py-1.5"
            onClick={() => {
              openModal(<ListDocuments {...props} />);
            }}
          >
            <i className="fas fa-angle-left"></i>
          </Button>
          <H3>Regresar</H3>
        </div>

        <ListDocument
          addDocument={true}
          deleteDocument={false}
          procedure={procedure}
          openModal={openModal}
          onClose={onClose}
          openDialog={openDialog}
          dialogClose={dialogClose}
          step={1}
        />
      </>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <H2>Documentos presentados</H2>
        <Button type="button" className="px-2 py-2" onClick={handleDocument}>
          Generar constancia de entrega
        </Button>
      </div>
      <AtnData attention={dataContact.find((item) => item.name === atn)} />
      <div className="flex justify-end my-2">
        <Button
          type="button"
          className="px-2 py-1.5"
          onClick={handleAddDocument}
        >
          Adicionar documentos
        </Button>
      </div>
      <div
        className="p-4 my-4 text-sm text-yellow-800 rounded-lg bg-yellow-50"
        role="alert"
      >
        <span className="font-medium">Nota!</span> Al <b>quitar</b> un archivo
        no se eliminará del trámite solo no se mostrará en la declaración
        jurada.
      </div>

      <div className="w-full">
        <TableComplete
          header={[
            {
              name: "name",
              label: "Documento",
            },
            {
              name: "number",
              label: "Nro",
            },
            {
              name: "url",
              label: "Enlace",
              type: "custom",
              builder: (item) => {
                if (item) {
                  return (
                    <a
                      href={item}
                      className="text-[#1d4ed8] underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Abrir
                    </a>
                  );
                } else {
                  return "-";
                }
              },
            },
            {
              name: "status",
              label: "Estado",
            },
            {
              label: "Proveedor/Emisor",
              name: "provider",
            },
            {
              name: "add",
              type: "action",
              label: "Opciones",
              sticky: true,
              actions: [
                {
                  label: "Editar",
                  icon: "fas  fa-edit",
                  action: handleForm,
                  color: "text-[#1d4ed8]",
                },
                {
                  label: "Quitar",
                  icon: "fas fa-minus-circle",
                  action: handleDelete,
                  color: "text-red-500",
                },
              ],
            },
          ]}
          data={data.historialReceptionVerificationDocuments}
        />
      </div>
    </div>
  );
};

const FormAdd = ({ data, onClose, reload, procedureId }) => {
  async function uploadFile(values) {
    if (values.url) {
      let formData = new FormData();
      formData.append("Name", data.name);
      formData.append("File", values.url);
      formData.append("Status", "verified");
      formData.append("ProcedureId", procedureId);
      await requestAuth("post", "/files", formData)
        .then((res) => {
          onSubmit({ ...values, url: res.data.url, name: data.name });
        })
        .catch(() => {
          toast.error("Se produjo un error al subir el archivo");
        });
    } else {
      onSubmit({ ...values, url: "", name: data.name });
    }
  }

  async function onSubmit(values) {
    await requestAuth(
      data.id ? "put" : "post",
      data.id ? `/fileState/${data.id}` : "",
      // : `/fileState/procedure/${field.id}/field/${field.fieldId}`,
      values
    )
      .then(() => {
        reload();
        onClose();
        toast.success("Registro exitoso");
      })
      .catch(() => {
        toast.error("Se produjo un error al guardar");
      });
    if (values.provider_isNew) {
      await handleCreateOption(values);
    }
  }

  async function handleCreateOption(value) {
    await requestAuth("post", "/dropdown/option", {
      Name: value.provider,
      DropdownListLabel: "providers",
    });
  }

  return (
    <>
      <FormDefault
        tittle={data.name}
        fields={[
          {
            label: "Archivo",
            name: "url",
            type: "file",
            typeInput: "file",
          },
          {
            label: "Número",
            name: "number",
            placeholder: "Número",
            type: "text",
            typeInput: "input",
          },
          {
            label: "Estado",
            name: "status",
            type: "select",
            typeInput: "select",
            options: [
              {
                label: "Original",
                value: "Original",
              },
              {
                label: "Copia",
                value: "Copia",
              },
              {
                label: "Original o copia",
                value: "Original o copia",
              },
              {
                label: "Fotocopia simple",
                value: "Fotocopia simple",
              },
              {
                label: "Fotocopia legalizada",
                value: "Fotocopia legalizada",
              },
              {
                label: "No aplica",
                value: "No aplica",
              },
            ],
          },
          {
            label: "Emisor/Proveedor/Emisor",
            name: "provider",
            typeInput: "selectNewOption",
            urlApi: "/dropdown/providers/options",
            value: "name",
            labelOption: "name",
          },
        ]}
        initialValues={{
          url: data.url ?? null,
          number: data.number ?? "",
          status: data.status ?? "Original",
          provider: data.provider ?? "",
        }}
        validationSchema={Yup.object().shape({
          url: Yup.mixed(),
          number: Yup.string(),
          status: Yup.string(),
        })}
        onSubmit={(values) =>
          typeof values.url === "string"
            ? onSubmit({ ...values, name: data.name })
            : uploadFile(values)
        }
        buttonName="Registrar"
      />
      <br />
    </>
  );
};

const FileDelivery = (props) => {
  const { data, attention, procedure } = props;
  const { data: data2, call } = Request({
    urlApi: `/dataSet/procedure/${
      procedure.id || procedure.procedureId
    }/field/357/verifyFileStateExist`,
  });

  // function handleBreakDown(list) {
  //   let newList = [];
  //   if (validateArray(list)) {
  //     list.forEach((field) => {
  //       if (validateArray(field.fileStatusList)) {
  //         field.fileStatusList.forEach((file) => {
  //           newList.push(file);
  //         });
  //       }
  //     });
  //   }
  //   return newList;
  // }
  async function handleOnClick() {
    await pdf(MyDoc)
      .toBlob()
      .then((r) => {
        var file = new File([r], `Planilla-${dayjs().format("DD-MM-YYYY")}`);
        uploadFile(file);
      });
  }

  async function uploadFile(file) {
    let formData = new FormData();
    formData.append("Name", `Planilla-${dayjs().format("DD-MM-YYYY")}`);
    formData.append("File", file);
    formData.append(
      "originalName",
      `Planilla-${dayjs().format("DD-MM-YYYY")}.pdf`
    );
    formData.append("Status", "verified");
    formData.append("ProcedureId", procedure.id || procedure.procedureId);
    await requestAuth("post", "/files", formData)
      .then((res) => {
        handleRegister({
          url: res.data.url,
          name: `Planilla-${dayjs().format("DD-MM-YYYY")}`,
        });
      })
      .catch(() => {
        toast.error("Se produjo un error al subir el archivo");
      });
  }

  async function handleRegister(values) {
    await requestAuth(
      "post",
      `/fileState/procedure/${procedure.id || procedure.procedureId}/field/357`,
      values
    )
      .then(() => {
        toast.success("Registro exitoso");
        call();
      })
      .catch(() => {
        toast.error("Se produjo un error al guardar");
      });
  }
  const MyDoc = (
    <Document>
      <Page size="A4" style={{ padding: "20px" }}>
        <View borderContent justifyContent="space-between" alignItems="center">
          <View width="25%">
            {/* <Image src={logotipo} style={{ height: "50px" }} /> */}
          </View>
          <Text textAlign="center" fontSize="12px" bold>
            CONSTANCIA DE ENTREGA DE DOCUMENTOS
          </Text>
          <View
            width="25%"
            flexDirection="column"
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <Text fontSize="15px" bold textAlign="end">
              {data.internNumber}
            </Text>
            <Text fontSize="10px" padding="5px 0 0 0">
              FECHA DE EMISIÓN:
            </Text>
            <Text fontSize="10px">{data.deliverDate}</Text>
          </View>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <View width="50%" flexDirection="column">
            <Text>Cliente: {data.client}</Text>
            <Text>ATN: {attention.name}</Text>
            <Text>Descripción: {data.product}</Text>
            <Text>Dirección: {data.clientAddress}</Text>
            <Text>Observación: {data.observacionesDeEnvíoDeDocumentos}</Text>
          </View>
          <View width="25%" flexDirection="column">
            <Text>NIT: {data.clientNit}</Text>
            <Text>Teléfono ATN: {attention.phone}</Text>
          </View>
          <View width="25%">
            <Text>Teléfono: {data.telefonoCompania}</Text>
          </View>
        </View>
        <CustomTablePDF
          fields={[
            {
              label: "N°",
              name: "counter",
              width: "5",
              counter: true,
            },
            {
              label: "Documento",
              name: "name",
              width: "40",
            },
            {
              label: "Estado",
              name: "status",
              width: "15",
            },
            {
              label: "Nro Documento",
              name: "number",
              width: "20",
            },
            {
              label: "Enlace",
              name: "url",
              type: "link",
              width: "20",
            },
          ]}
          border={{ right: true, bottom: true, left: true }}
          data={data.historialReceptionVerificationDocuments}
        />
        <Text textAlign="center" fontSize="10px" bold>
          Total Documentos: {data.counter}
        </Text>
        <View height="100px"></View>
        <View
          style={{ marginTop: "50px", marginButton: "10px" }}
          justifyContent="space-between"
        >
          <View flexDirection="column">
            <Text textAlign="center" fontSize="10px">
              Entregado por:
              ...................................................................
            </Text>
            <Text
              textAlign="center"
              fontSize="10px"
              style={{ marginTop: "10px" }}
            >
              Fecha:.................................................................................
            </Text>
            <Text
              textAlign="center"
              fontSize="10px"
              style={{ marginTop: "50px" }}
            >
              Entregado por:
              ...................................................................
            </Text>
            <Text
              textAlign="center"
              fontSize="10px"
              style={{ marginTop: "10px" }}
            >
              Fecha:.................................................................................
            </Text>
          </View>
          <View flexDirection="column">
            <Text textAlign="center" fontSize="10px">
              Recibido por:
              ...................................................................
            </Text>
            <Text
              textAlign="center"
              fontSize="10px"
              style={{ marginTop: "10px" }}
            >
              Fecha:.................................................................................
            </Text>
            <Text
              textAlign="center"
              fontSize="10px"
              style={{ marginTop: "50px" }}
            >
              Recibido por:
              ...................................................................
            </Text>
            <Text
              textAlign="center"
              fontSize="10px"
              style={{ marginTop: "10px" }}
            >
              Fecha:.................................................................................
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <div>
      <div className="flex justify-end mb-2">
        {data2?.existData ? (
          <Button className="py-2" onClick={handleOnClick}>
            ¿Constancia ya generada, desea generar una nueva?{" "}
            <i className="fas fa-cloud-upload-alt"></i>
          </Button>
        ) : (
          <Button className="py-2" onClick={handleOnClick}>
            Guardar constancia de entrega{" "}
            <i className="fas fa-cloud-upload-alt"></i>
          </Button>
        )}
      </div>
      <PDFViewer
        style={{
          width: 900,
          height: window.innerHeight - 100,
        }}
        children={MyDoc}
      />
    </div>
  );
};

export default GenerateFileDelivery;
