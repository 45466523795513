import { H3 } from "../../components/Text";
import { useEffect, useState } from "react";
import DragList from "../../components/Drags/DragList";
import { Request } from "../../hooks/Request";
import { Button } from "../../components/Buttons";
import { validateArray } from "../../utils/validate";
import { useModal } from "../../contexts/ModalContext";
import { useDialog } from "../../contexts/DialogContext";
import { DialogConfirmation } from "../../components/Modal";
import ListFields from "../../containers/Systems/ListFields";
import FormAddStep from "../../containers/Systems/FormAddStep";
import FormEditStep from "../../containers/Systems/FormEditStep";
import FormAddValidation from "../../containers/Systems/FormAddValidation";
import { requestAuth } from "../../components/services/RequestService";
import { toast } from "react-toastify";
import ListFieldsMail from "../../containers/Systems/ListFieldsMail";

const Procedures = () => {
  const [procedureTypeId, setprocedureTypeId] = useState(1);

  const { data } = Request({
    urlApi: `procedureType`,
  });

  return (
    <div>
      {validateArray(data) ? null : null}
      <div className="flex items-center gap-2">
        <H3 className="text-xl">Lista de pasos para:</H3>
        <select
          name="SearchSelect"
          className="bg-[#f97316] border border-gray-100 text-[#e2e8f0] text-md rounded-lg block px-2.5 py-2"
          onClick={(e) => {
            setprocedureTypeId(e.target.value);
          }}
        >
          {data?.map((value, key) => (
            <option key={key} value={value.id}>
              {value.name}
            </option>
          ))}
        </select>
      </div>
      <ListStep procedureTypeId={procedureTypeId} />
    </div>
  );
};

const ListStep = (props) => {
  const { procedureTypeId } = props;
  const { openModal, onClose } = useModal();

  const { data, call } = Request({
    urlApi: `/processstep/GetProcessStepsByProcedureTypeId/${procedureTypeId}`,
  });

  useEffect(() => {
    call();
  }, [procedureTypeId]);

  function handleAdd() {
    openModal(
      <FormAddStep
        {...props}
        step={{ step: 0 }}
        reload={call}
        onClose={onClose}
      />
    );
  }

  function handleOrder(list) {
    openModal(
      <DragList
        tittle="Ordenar pasos"
        data={list}
        orderFieldsActive={true}
        onSaveChange={(fields) => {
          const newFields = fields?.map((field, index) => ({
            Id: field.id,
            Order: index + 1,
          }));
          requestAuth("put", `processstep/order/${procedureTypeId}`, newFields)
            .then(() => {
              call();
              onClose();
              toast.success("Envio exitoso");
            })
            .catch(() => {
              toast.error("Se produjo un error al realizar los cambios");
            });
        }}
      />
    );
  }

  return (
    <>
      <div className="flex justify-end items-center mb-1">
        <Button
          onClick={() => handleOrder(data)}
          content="Ordenar pasos"
          className="px-2 py-1"
        >
          <i className="fas fa-sort-numeric-down"></i>
        </Button>
      </div>
      <div className="flex flex-col items-center">
        <div className="group relative">
          <div className="bg-red-500 text-white rounded-full px-5 py-0.5">
            Inicio
          </div>
          <Button
            onClick={handleAdd}
            className="absolute z-20 top-0 -right-6 invisible group-hover:visible
           px-2.5 py-1 text-sm bg-[#5f5f5fc9] text-white"
          >
            <i className="fas fa-plus"></i>
          </Button>
        </div>
        <div className="w-[1px] h-3 border border-black"></div>
        {validateArray(data)
          ? data.map((step, index) => {
            const stepPrev = index > 0 ? data[index - 1] : null;
            // console.log(stepPrev?.validator ? stepPrev : '', step)
            return (
              <Step
                {...props}
                key={index}
                step={step}
                call={call}
                index={index}
                listStep={data}
                validator={stepPrev?.validator}
              />
            );
          })
          : null}
        <div className="bg-red-500 text-white rounded-full px-5 py-0.5 mb-4">
          Fin
        </div>
      </div>
    </>
  );
};

const Step = (props) => {
  const { step, call, validator = null, listStep, index } = props;
  const { openModal, onClose } = useModal();
  const { openDialog, dialogClose } = useDialog();

  function handleAdd(step) {
    openModal(
      <FormAddStep {...props} step={step} reload={call} onClose={onClose} />
    );
  }

  function handleEdit(step) {
    openModal(
      <FormEditStep {...props} step={step} reload={call} onClose={onClose} />
    );
  }

  function handleRemove(step) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres eliminar este paso?</H3>}
        onClose={dialogClose}
        method="put"
        url={`/processstep/deleteProcessStep/${step.id}`}
        texBtn="Eliminar"
        handleSuccess={call}
      />
    );
  }

  function handleListFields(step) {
    openModal(
      <ListFields {...props} step={step} reload={call} onClose={onClose} />
    );
  }

  function handleListFieldsMail(step) {
    openModal(
      <ListFieldsMail {...props} step={step} reload={call} onClose={onClose} />
    );
  }

  function handleAddValidation(step) {
    openModal(
      <FormAddValidation
        {...props}
        step={step}
        reload={call}
        onClose={onClose}
      />
    );
  }

  function handleValidateRemove(validator) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres eliminar la validación?</H3>}
        onClose={dialogClose}
        method="delete"
        url={`/processStep/validator/${validator.processStepValidatorId}`}
        texBtn="Eliminar"
        handleSuccess={call}
      />
    );
  }

  const StepComponent = ({ item }) => (
    <>
      <div
        className={`group relative flex flex-col items-center justify-center ${""}`}
      >
        <div
          onClick={() => handleListFields(item)}
          className="w-fit px-3 py-2 rounded-md border-[2px] border-green-500 bg-white cursor-pointer group-hover:bg-slate-200"
        >
          <span className="bg-green-100 text-green-800 text-sm mr-2 px-2.5 py-0.5 rounded font-extrabold border border-green-200">
            {item.step}
          </span>{" "}
          {item.name}
        </div>
        <div className="absolute z-20 invisible flex gap-2 -top-7 group-hover:visible">
          <Button
            onClick={() => handleEdit(item)}
            className="px-2.5 py-1.5 text-sm bg-[#5f5f5fc9] text-white"
          >
            <i className="fas fa-edit"></i>
          </Button>
          <Button
            onClick={() => handleRemove(item)}
            className="px-2.5 py-1.5 text-sm bg-[#5f5f5fc9] text-white"
          >
            <i className="fas fa-trash"></i>
          </Button>
          <Button
            onClick={() => handleListFieldsMail(item)}
            className="px-2.5 py-1.5 text-sm bg-[#5f5f5fc9] text-white"
          >
            <i className="fas fa-envelope"></i>
          </Button>
        </div>
        <div className="absolute z-20 invisible -bottom-7 flex gap-2 group-hover:visible">
          <Button
            onClick={() => handleAdd(item)}
            className="px-2.5 py-1.5 text-sm bg-[#5f5f5fc9] text-white"
          >
            <i className="fas fa-plus"></i>
          </Button>
          {!item.validator && (
            <Button
              onClick={() => handleAddValidation(item)}
              className="px-2.5 py-1.5 text-sm bg-[#5f5f5fc9] text-white"
            >
              <i className="fas fa-code-branch"></i>
            </Button>
          )}
        </div>
      </div>
      <div className="w-[1px] h-4 border border-black"></div>
    </>
  );

  const validateContent =
    validator &&
      step.validator &&
      step.validator.fieldResume.id === validator.fieldResume.id &&
      validator.validation === step.validator.validation &&
      validator.operador === step?.validator?.operador
      ? false
      : true;

  return (
    <>
      <div
        className={`group relative flex flex-col items-center justify-center ${step.validator ? "hidden" : ""
          }`}
      >
        <div
          onClick={() => handleListFields(step)}
          className="w-fit px-3 py-2 rounded-md border-[2px] border-green-500 bg-white cursor-pointer group-hover:bg-slate-200"
        >
          <span className="bg-green-100 text-green-800 text-sm mr-2 px-2.5 py-0.5 rounded font-extrabold border border-green-200">
            {step.step}
          </span>{" "}
          {step.name}
        </div>
        <div className="absolute z-20 invisible flex gap-2 -top-7 group-hover:visible">
          <Button
            onClick={() => handleEdit(step)}
            className="px-2.5 py-1.5 text-sm bg-[#5f5f5fc9] text-white"
          >
            <i className="fas fa-edit"></i>
          </Button>
          <Button
            onClick={() => handleRemove(step)}
            className="px-2.5 py-1.5 text-sm bg-[#5f5f5fc9] text-white"
          >
            <i className="fas fa-trash"></i>
          </Button>
          <Button
            onClick={() => handleListFieldsMail(step)}
            className="px-2.5 py-1.5 text-sm bg-[#5f5f5fc9] text-white"
          >
            <i className="fas fa-envelope"></i>
          </Button>
        </div>
        <div className="absolute z-30 invisible -bottom-7 flex gap-2 group-hover:visible">
          <Button
            onClick={() => handleAdd(step)}
            className="px-2.5 py-1.5 text-sm bg-[#5f5f5fc9] text-white"
          >
            <i className="fas fa-plus"></i>
          </Button>
          {!step.validator && (
            <Button
              onClick={() => handleAddValidation(step)}
              className="px-2.5 py-1.5 text-sm bg-[#5f5f5fc9] text-white"
            >
              <i className="fas fa-code-branch"></i>
            </Button>
          )}
        </div>
      </div>
      {validateContent && (
        <div
          className={`h-4 border border-black ${step.validator && step.validator.fieldId ? "mb-3" : ""
            }`}
        ></div>
      )}
      {step.validator ? (
        <>
          <div
            className={`relative group border border-orange-400 bg-white px-4`}
          >
            <div className="z-10 flex flex-col items-center">
              {validateContent ? (
                <div
                  // onClick={handleListFields}
                  className="-mt-4 w-fit px-2 py-1 z-20 rounded-md border-2 border-orange-400 bg-white cursor-pointer"
                >
                  <strong>{step.validator.fieldResume.label}</strong> es{" "}
                  {step.validator.operador} a{" "}
                  {step.validator.validation.split("||").length > 1 ? (<div className="">{step.validator.validation.split("||").map((val) => (<><strong className="bg-orange-100 rounded-md border-2 border-orange-400 px-1 ml-2 text-sm w-auto">{val}</strong><br /></>))}</div>) : step.validator.validation.split("||").map((val) => (<strong className="bg-orange-100 rounded-md border-2 border-orange-400 px-1 ml-2 text-sm">{val}</strong>))}
                </div>
              ) : null}
              {/* <div className="absolute z-20 invisible flex gap-2 -bottom-7 group-hover:visible"> */}
              <div className="absolute z-20 flex flex-col gap-2 -right-4 invisible group-hover:visible">
                {/* <Button
                  onClick={handleEdit}
                  className="px-2.5 py-1.5 text-sm bg-orange-400 text-white"
                >
                  <i className="fas fa-edit"></i>
                </Button> */}
                <Button
                  onClick={() => handleValidateRemove(step.validator)}
                  className="px-2 py-1 text-sm bg-orange-400 text-white"
                >
                  <i className="fas fa-trash"></i>
                </Button>
              </div>
            </div>
            <div className="flex flex-col items-center">
              <div className="w-[1px] h-4 border border-black"></div>
              <StepComponent item={step} />
            </div>
          </div>
          {listStep[index + 1].validator &&
            step.validator &&
            listStep[index + 1].validator.fieldResume.id ===
            step.validator.fieldResume.id &&
            listStep[index + 1].validator.fieldResume.validation ===
            step.validator.fieldResume.validation ? null : (
            <div className="w-[1px] h-4 border border-black"></div>
          )}
          {/* {validator &&
          step.validator &&
          validator.fieldResume.id === step.validator.fieldResume.id ? (
            <div className="w-[1px] h-4 border border-black"></div>
          ) : null} */}
        </>
      ) : null}
    </>
  );
};

export default Procedures;
