import { Request } from "../../hooks/Request";
import { Circle } from "../../components/Animation";
// import { requestAuth } from '../../components/services/RequestService'
import View from "../../components/GeneratePdf/View";
import Text from "../../components/GeneratePdf/Text";
// import GeneratePdf from '../../components/GeneratePdf/GeneratePdf'
import { Page, Document, PDFViewer, Image } from "@react-pdf/renderer";
import Logadex from "../../assets/Logadex.jpg"

const PdfLiquidation = (props) => {

  const { procedure } = props;
  const { data: dataPagos, loading } = Request({
    urlApi: `/expenses?procedureId=${procedure.id}`,
  });
  const { data } = Request({
    urlApi: `/Logadex/procedure/${procedure.id}/someData`,
  });


  console.log("estos son mis datos: ",data)
  // console.log("pagos ",dataPagos)
  // console.log("datos extra ",dataExtra)

  let header=[];

  if(data?.dataSets.modDespacho === "MULTIMODAL"){
    header = [
      { 
        doc: "BILL OF LOADING", 
        tipo: "ORIGINAL" ,
        doc1: "CERTIFICADO DE ORIGEN", 
        tipo1: "ORIGINAL" 
      },
      { 
        doc: "FACTURA COMERCIAL", 
        tipo: "ORIGINAL" ,
        doc1: "AUTORIZACIONES/PERMISO", 
        tipo1: "ORIGINAL"
      },
      { 
        doc: "LISTA DE EMPAQUE", 
        tipo: "ORIGINAL" ,
        doc1: "DOCUMENTO PORTUARIO ASPB/ITI/SAAM", 
        tipo1: "ORIGINAL" 
      },
      { 
        doc: "POLIZA DE SEGURO", 
        tipo: "COPIA" ,
        doc1: "EXAMEN PREVIO", 
        tipo1: "ORIGINAL"
      },
      { 
        doc: "DAV", 
        tipo: "ORIGINAL" ,
        doc1: "HOJA DE TRABAJO EP", 
        tipo1: "ORIGINAL"
      },
      { 
        doc: "DOCUMENTO BANCARIO (PARA MAS DE 50000 BS)", 
        tipo: "COPIA",
        doc1: "CARTA DE TRADUCCIÓN", 
        tipo1: "COPIA" 
      },
      { 
        doc: "CERTIFICADO DE FLETE", 
        tipo: "COPIA",
        doc1: "FACTURA DE DESCONSOLIDACIÓN", 
        tipo1: "ORIGINAL" 
      },
      { 
        doc: "CRT", 
        tipo: "ORIGINAL",
        doc1: "CARTA DE TRADUCCIÓN", 
        tipo1: "COPIA" 
      },
      { 
        doc: "FACTURA DE TRANSPORTE", 
        tipo: "COPIA",
        doc1: "PARTE DE RECEPCIÓN", 
        tipo1: "ORIGINAL" 
      }
    ]

  }else if(data?.dataSets.modDespacho === "TERRESTRE"){
    header = [
      { 
        doc: "CRT", 
        tipo: "ORIGINAL",
        doc1: "CERTIFICADO DE ORIGEN", 
        tipo1: "ORIGINAL"
      },
      { 
        doc: "FACTURA COMERCIAL", 
        tipo: "ORIGINAL",
        doc1: "AUTORIZACIONES/PERMISO", 
        tipo1: "ORIGINAL" 
      },
      { 
        doc: "LISTA DE EMPAQUE", 
        tipo: "ORIGINAL",
        doc1: "EXAMEN PREVIO", 
        tipo1: "ORIGINAL" 
      },
      { 
        doc: "POLIZA DE SEGURO", 
        tipo: "COPIA",
        doc1: "HOJA DE TRABAJO EP", 
        tipo1: "ORIGINAL" 
      },
      { 
        doc: "DAV", 
        tipo: "ORIGINAL",
        doc1: "DOCUMENTOS DE EXPORTACIÓN (PAIS LIMITROFE)", 
        tipo1: "FOTOCOPIA" 
      },
      { 
        doc: "DOCUMENTOS DE ENTIDAD BANCARIA (PARA MAS DE 50000 BS)", 
        tipo: "COPIA",
        doc1: "CARTA DE TRADUCCIÓN", 
        tipo1: "COPIA" 
      },
      { 
        doc: "FACTURA DE TRANSPORTE", 
        tipo: "COPIA",
        doc1: "PARTE DE RECEPCIÓN", 
        tipo1: "ORIGINAL" 
      }
    ]
  }else if(data?.dataSets.modDespacho === "AEREO"){
    header = [
      { 
        doc: "GUIA AÉREA / COURIER", 
        tipo: "ORIGINAL",
        doc1: "CERTIFICADO DE ORIGEN", 
        tipo1: "ORIGINAL",
      },
      { 
        doc: "FACTURA COMERCIAL", 
        tipo: "ORIGINAL", 
        doc1: "AUTORIZACIONES/PERMISO", 
        tipo1: "ORIGINAL" 
      },
      { 
        doc: "LISTA DE EMPAQUE", 
        tipo: "ORIGINAL", 
        doc1: "EXAMEN PREVIO", 
        tipo1: "ORIGINAL" 
      },
      { 
        doc: "POLIZA DE SEGURO", 
        tipo: "COPIA", 
        doc1: "HOJA DE TRABAJO EP", 
        tipo1: "ORIGINAL" 
      },
      { 
        doc: "DAV", 
        tipo: "ORIGINAL" ,
        doc1: "DOCUMENTOS DE EXPORTACION (PAIS LIMITROFE)", 
        tipo1: "FOTOCOPIA" 
      },
      { 
        doc: "DOCUMENTO DE ENTIDAD BANCARIA (PARA MAS DE 50000 BS)", 
        tipo: "COPIA", 
        doc1: "CARTA DE TRADUCCIÓN", 
        tipo1: "COPIA" 
      },
      { 
        doc: "CERTIFICADO DE FLETE", 
        tipo: "COPIA", 
        doc1: "PARTE DE RECEPCIÓN", 
        tipo1: "ORIGINAL" 
      }
    ]
  }
  


  if (!loading) {
    return <Circle />;
  }

  const MyDoc = (
    <Document>
      <Page size="A4" style={{ padding: "20px" }}>
        {/* <View borderContent> */}
        <View>
          <View
            width="33%"
            // border={{ right: true }}
            justifyContent="center"
            alignItems="center"
          >
            <Image src={Logadex} style={{ width: '110px' }} />
          </View>
          <View
            justifyContent="center"
            alignItems="center" width="45%"
            flexDirection="column"
          >
            <Text padding="2px 3px">Avenida Jaimes Freyre Nro. 2354</Text>
            <Text padding="2px 3px">Edificio Torre Karito Piso 1 Of. 2 Zona Sopocachi Bajo</Text>
            <Text padding="2px 3px">Telfs. 2417290 71551797 72501500</Text>
            <Text fontSize="12px" bold>
              DESPACHO - {data?.dataSets.modDespacho}
            </Text>
            <Text fontSize="10px" bold>
              FORMULARIO N°01
            </Text>
          </View>

          <View width="33%" flexDirection="column">

            <View
              
              border={{ bottom: true }}
              alignItems="center"
              borderContent
            >
              <Text
                style={{ backgroundColor: "#dddddd" }}
                minWidth="30%"
                fontSize="10px"
                bold border={{ right: true }}>
                N° INTERNO
              </Text>
              <Text
                // minWidth="70%"
                fontSize="10px"
                // bold border={{ right: true }}
              >
                {
                  data?.dataSets.codigoDeInterno
                }
              </Text>
            </View>

          </View>
        </View>

        <View borderContent>
          <Text
            style={{ backgroundColor: "#dddddd" }}
            bold minWidth="130px"
            border={{ right: true }}
          >
            IMPORTADOR/CLIENTE
          </Text>

          <Text>{data?.company.razonSocial}</Text>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <Text
            style={{ backgroundColor: "#dddddd" }}
            bold minWidth="130px"
            border={{ right: true }}>
            PROVEEDOR
          </Text>
          <Text>{data?.dataSets.proveedor}</Text>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <View width="50%">
            <Text
              style={{ backgroundColor: "#dddddd" }}
              bold minWidth="130px"
              border={{ right: true }}>
              TIPO DE DESPACHO:
            </Text>
            <Text
              textAlign="center"
              style={{ backgroundColor: "#dddddd" }}
              bold
              border={{ right: true }}>
              ABREVIADO:
            </Text>
            <Text minWidth="30%">{data?.dataSets.tipoDespacho === "ABREBIADO" ? "X" : " "}</Text>
          </View>
          <View minWidth="50%">
            <Text
              bold
              border={{ right: true, left: true }}
              style={{ backgroundColor: "#dddddd" }}
            >
              ANTICIPADO:
            </Text>
            <Text minWidth="15%">{data?.dataSets.tipoDespacho === "ANTICIPADO" ? "X" : " "}</Text>
          </View>
          <View minWidth="50%">
            <Text
              style={{ backgroundColor: "#dddddd" }}
              bold border={{ right: true, left: true }}>
              GENERAL:
            </Text>
            <Text>
              {data?.dataSets.tipoDespacho === "GENERAL" ? "X" : " "}
            </Text>
          </View>
        </View>

        <View border={{ right: true, bottom: true, left: true }}>
          <Text
            style={{ backgroundColor: "#dddddd" }}
            bold minWidth="130px"
            border={{ right: true }}>
            PROCESADO POR:
          </Text>
          <Text >{data?.dataSets.procesadoPor}</Text>
        </View>
        {/* ---------------------bloque3----------------- */}

        <View
          style={{ marginTop: "5px", marginBottom: "5px" }}
          border={{ top: true, right: true, bottom: true, left: true }}>
          <View width="50%">
            <Text
              style={{ backgroundColor: "#dddddd" }}
              bold minWidth="130px"
              border={{ right: true }}>
              CANAL ASIGNADO:
            </Text>
            <Text
              padding="5px 5px 0 15px"
              minWidth="55px"
              style={{ backgroundColor: "#dddddd" }}
              bold border={{ right: true }}>
              ROJO:
            </Text>
            <Text minWidth="10%">{data?.dataSets.canal === "Canal Rojo" ? "X" : " "}</Text>
          </View>

          <View minWidth="50%">
            <Text
              padding="5px 0 0 7px"
              textAlign="center"
              minWidth="57px"
              bold
              border={{ right: true, left: true }}
              style={{ backgroundColor: "#dddddd" }}
            >
              AMARILLO:
            </Text>
            <Text minWidth="15%">{data?.dataSets.canal === "Canal Amarillo" ? "X" : " "}</Text>
          </View>
          <View minWidth="50%">
            <Text
              padding="5px 5px 0 12px"
              minWidth="51px"
              style={{ backgroundColor: "#dddddd" }}
              bold border={{ right: true, left: true }}>
              VERDE:
            </Text>
            <Text >{data?.dataSets.canal === "Canal Verde" ? "X" : " "}</Text>
          </View>
        </View>
        {/* ---------------------bloque4--------------- */}

        <View
          borderContent
          style={{ backgroundColor: "#dddddd" }}
        >
          <Text
            padding="5px 0 5px 210px"
            bold>
            DOCUMENTOS DE SOPORTE
          </Text>
        </View>

        <View border={{ right: true, bottom: true, left: true }}>
          {/* COLUMNA 1 */}
          <View width="18%" border={{right: true}} justifyContent="center" style={{ backgroundColor: "#eeeeee" }}>
            <Text bold fontSize="7px">
              DOCUMENTOS
            </Text>
          </View>
          <View width="8%" border={{right: true}} justifyContent="center" style={{ backgroundColor: "#eeeeee" }} >
            <Text
              fontSize="7px"
              padding="5px 5px 0 5px"
              bold >
              TIPO
            </Text>
          </View>
          <View width="8%" border={{right: true}} justifyContent="center" style={{ backgroundColor: "#eeeeee" }}>
            <Text
            fontSize="7px"
              // padding="5px 5px 0 5px"
              bold >
              ORIGINAL
            </Text>
          </View>
          <View width="9%" border={{right: true}} justifyContent="center" style={{ backgroundColor: "#eeeeee" }}>
            <Text
            fontSize="7px"
              // padding="5px 5px 0 5px"
              bold >
              FOTOCOPIA
            </Text>
          </View>
          <View width="7%" border={{right: true}} justifyContent="center" style={{ backgroundColor: "#eeeeee" }}>
            <Text
            fontSize="7px"
              // padding="5px 5px 0 5px"
              bold >
              COPIA
            </Text>
          </View>

          {/* COLUMNA 2 */}
          <View width="18%" border={{right: true}} justifyContent="center" style={{ backgroundColor: "#eeeeee" }}>
            <Text bold fontSize="7px">
              DOCUMENTOS
            </Text>
          </View>
          <View width="8%" border={{right: true}} justifyContent="center" style={{ backgroundColor: "#eeeeee" }}>
            <Text
              fontSize="7px"
              // padding="5px 5px 0 5px"
              bold >
              TIPO
            </Text>
          </View>
          <View width="8%" border={{right: true}} justifyContent="center" style={{ backgroundColor: "#eeeeee" }}>
            <Text
            fontSize="7px"
              // padding="5px 5px 0 5px"
              bold >
              ORIGINAL
            </Text>
          </View>
          <View width="7%" border={{right: true}} justifyContent="center" style={{ backgroundColor: "#eeeeee" }}>
            <Text
            fontSize="7px"
              // padding="5px 5px 0 5px"
              bold >
              COPIA
            </Text>
          </View>
          <View width="9%" justifyContent="center" style={{ backgroundColor: "#eeeeee" }}>
            <Text
            fontSize="7px"
              // padding="5px 5px 0 5px"
              bold >
              FOTOCOPIA
            </Text>
          </View>
          
        </View>

        {
          header?.map((item)=>(
            <View border={{ right: true, bottom: true, left: true }}>
              {/* COLUMNA 1 */}
              <View width="18%" border={{right: true}} justifyContent="flex-start" aligItems="center">
                <View alignItems="center">
                  <Text  fontSize="7px">
                    {item.doc}
                  </Text>
                </View>
                  
              </View>
              <View width="8%" border={{right: true}} justifyContent="flex-start" aligItems="center">
                <View alignItems="center">
                  <Text  fontSize="7px">
                    {item.tipo}
                  </Text>
                </View>

              </View>
              <View width="8%" border={{right: true}} justifyContent="center" aligItems="center">
                <View alignItems="center">
                  <Text
                    fontSize="7px"
                    // padding="5px 5px 0 5px"
                    bold >
                    {
                      data?.fileDocs.find(field => field.fieldlabel.trim() === item.doc)?.status.toUpperCase() === "ORIGINAL" ? "X" : " "
                    }
                  </Text>
                </View>
                
              </View>
              <View width="9%" border={{right: true}} justifyContent="center" aligItems="center">
                <View alignItems="center">
                  <Text
                    fontSize="7px"
                    // padding="5px 5px 0 5px"
                    bold >
                    {
                      data?.fileDocs.find(field => field.fieldlabel === item.doc)?.status.toUpperCase() === "FOTOCOPIA" ? "X" : " "
                    }
                  </Text>
                </View>
                
              </View>
              <View width="7%" border={{right: true}} justifyContent="center" aligItems="center">
                <View alignItems="center">
                  <Text
                    fontSize="7px"
                    // padding="5px 5px 0 5px"
                    bold >
                    {
                      data?.fileDocs.find(field => field.fieldlabel === item.doc)?.status.toUpperCase() === "COPIA" ? "X" : " "
                    }
                  </Text>
                </View>
                
              </View>

              {/* COLUMNA 2 */}
              <View width="18%" border={{right: true}} justifyContent="flex-start" aligItems="center">
                <View alignItems="center">
                  <Text  fontSize="7px">
                    {item.doc1}
                  </Text>
                </View>
              </View>
              <View width="8%" border={{right: true}} justifyContent="flex-start" aligItems="center">
                <View alignItems="center">
                  <Text  fontSize="7px">
                    {item.tipo1}
                  </Text>
                </View>
              </View>
              
              <View width="8%" border={{right: true}} justifyContent="center" aligItems="center">
                <View alignItems="center">
                  <Text
                    fontSize="7px"
                    // padding="5px 5px 0 5px"
                    bold >
                    {
                      data?.fileDocs.find(field => field.fieldlabel.trim() === item.doc1)?.status.toUpperCase() === "ORIGINAL" ? "X" : " "
                    }
                  </Text>
                </View>
                
              </View>
              <View width="7%" border={{right: true}} justifyContent="center" aligItems="center">
                <View alignItems="center">
                  <Text
                    fontSize="7px"
                    // padding="5px 5px 0 5px"
                    bold >
                    {
                      data?.fileDocs.find(field => field.fieldlabel === item.doc1)?.status.toUpperCase() === "COPIA" ? "X" : " "
                    }
                  </Text>
                </View>
                
              </View>
              <View width="9%"  justifyContent="center" aligItems="center">
                <View alignItems="center">
                  <Text
                    fontSize="7px"
                    // padding="5px 5px 0 5px"
                    bold >
                    {
                      data?.fileDocs.find(field => field.fieldlabel === item.doc1)?.status.toUpperCase() === "FOTOCOPIA" ? "X" : " "
                    }
                  </Text>
                </View>
                
              </View>

              
            </View>
          ))
        }

        
          

        

        {/* <CustomTablePDF

          fields={[
            {
              label: "DOCUMENTOS",
              name: "name",
              width: "20",
            },
            {
              label: "TIPO",
              name: "presentation",
              width: "9",
            },
            {
              label: "ORIGINAL",
              name: "status",
              // type: "link",
              width: "9",
            },
            {
              label: "FOTOCOPIA",
              name: "status",
              width: "11",
            },
            {
              label: "COPIA",
              name: "status",
              width: "9",
            },
            {
              label: "DOCUMENTOS",
              name: "status",
              width: "13",
            },
            {
              label: "TIPO",
              name: "provider",
              width: "9",
            },
            {
              label: "ORIGINAL",
              name: "provider",
              width: "9",
            },
            {
              label: "COPIA",
              name: "provider",
              width: "9",
            },
            {
              label: "FOTOCOPIA",
              name: "provider",
              width: "11",
            },
          ]}
          border={{ right: true, bottom: true, left: true }}
          // data={dataPagos.fileDocs}
          data={data?.fileDocs}
        /> */}
        {/* ----------------fila 7 veces--------------------- */}
        
        {/* -------------------BLOQUE 5--------------- */}
        <View
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#dddddd" }}
        >
          <Text
            padding="5px 0 5px 240px"
            bold>
            FORMULARIOS
          </Text>
        </View>

        <View border={{ right: true, bottom: true, left: true }}>
          <View width="50%">
            <Text bold minWidth="130px" border={{ right: true }}>
              N° DE DIM
            </Text>
            <Text>{data?.dataSets.nroDim || " "}</Text>
          </View>
          <View minWidth="50%">
            <Text
              padding="5px 5px 0 5px"
              minWidth="70px"
              bold border={{ right: true, left: true }}>
              N° DE DAM
            </Text>
            <Text>{data?.dataSets.nroDam || " "}</Text>
          </View>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <View width="50%">
            <Text bold minWidth="130px" border={{ right: true }}>
              N° DE DAV
            </Text>
            <Text>{data?.dataSets.nroDav || " "}</Text>
          </View>
          <View minWidth="50%">
            <Text minWidth="70px" bold border={{ right: true, left: true }}>
              VALOR $US
            </Text>
            <Text>{data?.dataSets.tipoCambio || " "}</Text>
          </View>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <Text bold minWidth="130px" border={{ right: true }}>
            N° DE EXAMEN PREVIO
          </Text>
          <Text>{data?.dataSets.nroExPrev || " "}</Text>
        </View>
        {/* <View
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#1a1a1a" }}
        ></View> */}
        {/* ------------------------------BLOQUE6---------------------- */}
        <View
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#dddddd" }}
        >
          <Text
            padding="5px 0 5px 240px"
            bold>
            LIQUIDACION
          </Text>
        </View>

        <View border={{ right: true, bottom: true, left: true }}>
          <View width="50%">
            <Text bold minWidth="130px" border={{ right: true }}>
              VALOR FOB $US
            </Text>
            <Text>{data?.dataSets.fobUsd || " "}</Text>
          </View>
          <View minWidth="50%">
            <Text
              padding="5px 25px 0 25px"
              bold border={{ right: true, left: true }}>
              GA BS
            </Text>
            <Text>{data?.dataSets.gaBs || " "}</Text>
          </View>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <View width="50%">
            <Text bold minWidth="130px" border={{ right: true }}>
              FLETE TERRESTRE $US
            </Text>
            <Text>{data?.dataSets.fleteUsd || " "}</Text>
          </View>
          <View minWidth="50%">
            <Text
              padding="5px 24px 0 25px"
              bold border={{ right: true, left: true }}>
              IVA BS
            </Text>
            <Text>{data?.dataSets.ivaBs || " "}</Text>
          </View>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <View width="50%">
            <Text bold minWidth="130px" border={{ right: true }}>
              SEGURO $US
            </Text>
            <Text>{data?.dataSets.seguroUsd || " "}</Text>
          </View>
          <View minWidth="50%">
            <Text
              padding="5px 21px 0 25px"
              bold border={{ right: true, left: true }}>
              ICE BS:
            </Text>
            <Text>{data?.dataSets.iceBs || " "}</Text>
          </View>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <View width="50%">
            <Text bold minWidth="130px" border={{ right: true }}>
              OTROS GASTOS $US
            </Text>
            <Text>{data?.dataSets.otrGastosUsd || " "}</Text>
          </View>
          <View minWidth="50%">
            <Text
              padding="5px 19px 0 20px"
              bold border={{ right: true, left: true }}>
              OTRO BS
            </Text>
            <Text>{" "}</Text>
          </View>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <View width="50%">
            <Text bold minWidth="130px" border={{ right: true }}>
              VALOR CIF $US
            </Text>
            <Text>{data?.dataSets.cifUsd || " "}</Text>
          </View>
          <View minWidth="50%">
            <Text
              padding="5px 4px 0 5px"
              bold border={{ right: true, left: true }}>
              FORMULARIO BS
            </Text>
            <Text>{" "}</Text>
          </View>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <View width="50%">
            <Text bold minWidth="130px" border={{ right: true }}>
              VALOR CIF BS
            </Text>
            <Text>{data?.dataSets.cifBs || " "}</Text>
          </View>
          <View minWidth="50%">
            <Text
              padding="5px 18px 0 20px"
              bold border={{ right: true, left: true }}
              style={{ backgroundColor: "#dddddd" }}>
              TOTAL BS
            </Text>
            <Text>{(data?.dataSets.cifBs + data?.dataSets.gaBs + data?.dataSets.ivaBs + data?.dataSets.iceBs) || " " }</Text>
          </View>
        </View>
        {/* -----------------BLOQUE 8-------------------------- */}
        <View
          borderContent
          style={{ marginTop: "5px" }}
        >
          <Text bold>
            OBSERVACIONES
          </Text>
        </View>
        {/* ----------------------BLOQUE 9------------------- */}
        <View
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#dddddd" }}
        >
          <Text
            padding="5px 0 5px 250px"
            bold>
            PAGOS
          </Text>
        </View>

        {
          dataPagos.map((item) => (
            <View border={{ right: true, bottom: true, left: true }}>
              <View width="50%">
                <Text bold minWidth="130px" >
                  {item.sigla} - {item.concepto}
                </Text>
                {/* <Text>{item.sigla}</Text> */}
              </View>
              <View minWidth="50%">
                <Text
                  padding="5px 4px 0 5px"
                  bold border={{ left: true }}>
                  {
                    item.monto.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                  } Bs.
                </Text>
              </View>
            </View>
          ))
        }


      </Page>
    </Document>
  );

  return (
    <div>
      <PDFViewer
        style={{
          width: 900,
          height: window.innerHeight - 100,
        }}
        children={MyDoc}
      />
    </div>
  );
};

export default PdfLiquidation;
