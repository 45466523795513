import * as Yup from "yup";
import { Request } from "../../hooks/Request";
import logotipo from "../../assets/LogotipoTC.png";
import { Circle } from "../../components/Animation";
import { toast } from "react-toastify";
import { requestAuth } from "../../components/services/RequestService";
import View from "../../components/GeneratePdf/View";
import { validateArray } from "../../utils/validate";
import Text from "../../components/GeneratePdf/Text";
import { useAuth } from "../../contexts/AuthContext";
import { useDialog } from "../../contexts/DialogContext";
import { Button } from "../../components/Buttons";
import FormDefault from "../../components/Forms/FormDefault";
import { Page, Document, PDFViewer, Image, pdf } from "@react-pdf/renderer";
import * as dayjs from "dayjs";
import { es } from "dayjs/locale/es";
dayjs.locale("es");

const RenderSettlement = (props) => {
  const { procedure } = props;
  const { openDialog, dialogClose } = useDialog();

  const { data, loading } = Request({
    urlApi: "/dropdown/user/accountNumbers",
  });

  function handleProform(values) {
    openDialog(
      <LiquidaciónProform {...props} accountNumber={values.account} />
    );
  }

  function handleAccount() {
    const list = data.map((item) =>
      item.accountNumber
        ? {
            label: `${item.accountNumber} - ${item.name} ${item.fatherLastName}`,
            value: item.accountNumber,
          }
        : null
    );
    return list.filter((n) => n);
  }

  if (!loading) {
    return <Circle />;
  }
  return (
    <FormDefault
      tittle="Elaborador por/ Nro de Cuenta Bancaria a Depositar"
      fields={[
        {
          label: "Atención",
          name: "account",
          typeInput: "select",
          options: handleAccount(),
          //   [
          //   {
          //     label: "Original",
          //     value: "Original",
          //   },
          //   {
          //     label: "Copia",
          //     value: "Copia",
          //   },
          //   {
          //     label: "Fotocopia simple",
          //     value: "Fotocopia simple",
          //   },
          //   {
          //     label: "Fotocopia legalizada",
          //     value: "Fotocopia legalizada",
          //   },
          // ],
          // typeInput: "selectNewOption",
          // urlApi: "/dropdown/user/accountNumbers",
          // value: "name",
          // labelOption: "name",
        },
      ]}
      initialValues={{
        account: "",
      }}
      validationSchema={Yup.object().shape({
        // atn: Yup.string().required("Campo requerido"),
      })}
      onSubmit={handleProform}
      buttonName="Registrar"
    />
  );
};

const LiquidaciónProform = (props) => {
  const { procedure, accountNumber } = props;
  const { user } = useAuth();
  const { data, loading } = Request({
    urlApi: `/tradecruz/${procedure.id || procedure.procedureId}/payroll`,
  });
  if (!loading) {
    return <Circle />;
  }

  async function handleOnClick() {
    await pdf(MyDoc)
      .toBlob()
      .then((r) => {
        var file = new File([r], `Planilla-${dayjs().format("DD-MM-YYYY")}`);
        uploadFile(file);
      });
  }

  async function uploadFile(file) {
    let formData = new FormData();
    formData.append("Name", `Planilla-${dayjs().format("DD-MM-YYYY")}`);
    formData.append("File", file);
    formData.append(
      "originalName",
      `Planilla-${dayjs().format("DD-MM-YYYY")}.pdf`
    );
    formData.append("Status", "verified");
    formData.append("ProcedureId", procedure.id || procedure.procedureId);
    await requestAuth("post", "/files", formData)
      .then((res) => {
        handleRegister({
          url: res.data.url,
          name: `Planilla-${dayjs().format("DD-MM-YYYY")}`,
        });
      })
      .catch(() => {
        toast.error("Se produjo un error al subir el archivo");
      });
  }

  async function handleRegister(values) {
    await requestAuth(
      "post",
      `/fileState/procedure/${procedure.id || procedure.procedureId}/field/725`,
      values
    )
      .then(() => {
        toast.success("Registro exitoso");
      })
      .catch(() => {
        toast.error("Se produjo un error al guardar");
      });
  }

  const MyDoc = (
    <Document>
      <Page size="A4" style={{ padding: "20px" }}>
        <View flexDirection="column" borderContent>
          <View padding="4px" style={{ backgroundColor: "#d38c19" }}></View>
          <View flexDirection="column">
            <View
              justifyContent="space-between"
              alignItems="center"
              padding="4px 0"
            >
              <View width="25%">
                {/* <Image src={logotipo} style={{ height: "50px" }} /> */}
              </View>
              <View
                width="60%"
                flexDirection="column"
                alignItems="flex-end"
                justifyContent="flex-end"
              >
                <Text
                  color="#004080"
                  padding="0px 3px"
                  textAlign="end"
                  fontSize="16px"
                  bold
                >
                  PLANILLA DE COBRANZA
                </Text>
                <Text
                  color="#d38c19"
                  padding="0px 3px"
                  fontSize="18px"
                  bold
                  textAlign="end"
                >
                  {data.codigoDeInterno}
                </Text>
                <Text fontSize="10px" padding="0px 3px" textAlign="end">
                  Santa Cruz {dayjs().format("DD")} de {dayjs().format("MMMM")}{" "}
                  del {dayjs().format("YYYY")}
                </Text>
              </View>
            </View>
            <View
              border={{ top: true }}
              // style={{ marginTop: "5px" }}
            >
              <View width="65%" flexDirection="column">
                <Text padding="2px 3px">
                  <Text padding="2px 3px" bold>
                    SEÑOR(ES):{" "}
                  </Text>
                  <Text padding="2px 3px">{data.senores}</Text>
                </Text>
              </View>
              <View width="35%">
                <Text padding="2px 3px">
                  <Text padding="2px 3px" bold>
                    NIT:{" "}
                  </Text>
                  <Text padding="2px 3px">{data.nit}</Text>
                </Text>
              </View>
            </View>
            <View
            // style={{ marginTop: "5px" }}
            >
              <View width="65%" flexDirection="column">
                <Text padding="2px 3px">
                  <Text padding="2px 3px" bold>
                    Doc. Embarque:{" "}
                  </Text>
                  <Text padding="2px 3px">{data.docEmbarque}</Text>
                </Text>
              </View>
              <View width="35%">
                <Text padding="2px 3px">
                  <Text padding="2px 3px" bold>
                    Int. Agencia:{" "}
                  </Text>
                  <Text padding="2px 3px">{data.intAgencia}</Text>
                </Text>
              </View>
            </View>

            <View
            // style={{ marginTop: "5px" }}
            >
              <View width="50%" flexDirection="column">
                <Text padding="2px 3px">
                  <Text padding="2px 3px" bold>
                    Facturas(s):{" "}
                  </Text>
                  <Text padding="2px 3px">{data.facturas}</Text>
                </Text>
              </View>
              <View width="25%">
                <Text padding="2px 3px">
                  <Text padding="2px 3px" bold>
                    Bultos:{" "}
                  </Text>
                  <Text padding="2px 3px">{data.bultos}</Text>
                </Text>
              </View>
              <View width="25%">
                <Text padding="2px 3px">
                  <Text padding="2px 3px" bold>
                    Peso:{" "}
                  </Text>
                  <Text padding="2px 3px">{data.peso}</Text>
                </Text>
              </View>
            </View>
            <View
              flexDirection="column"
              // style={{ marginTop: "5px" }}
            >
              <View>
                <View width="50%">
                  <Text padding="2px 3px">
                    <Text padding="2px 3px" bold>
                      Proveedor/Emisor:{" "}
                    </Text>
                    <Text padding="2px 3px">{data.proveedor}</Text>
                  </Text>
                </View>
              </View>
              <Text padding="2px 3px">
                <Text padding="2px 3px" bold>
                  Mercadería:{" "}
                </Text>
                <Text padding="2px 3px">{data.mercancia}</Text>
              </Text>
            </View>
            <View border={{ top: true, bottom: true }}>
              <View width="13%" flexDirection="column">
                <Text padding="2px 3px" bold>
                  Valor FOB
                </Text>
                <Text padding="2px 3px" bold>
                  Fletes I,II
                </Text>
                <Text padding="2px 3px" bold>
                  Seguro
                </Text>
                <Text padding="2px 3px" bold>
                  Otros Gastos
                </Text>
                <Text padding="2px 3px" bold>
                  Valor CIF
                </Text>
                <Text padding="2px 3px" bold>
                  T.c.
                </Text>
                <Text padding="2px 3px" bold>
                  Valor CIF
                </Text>
              </View>
              <View width="5%" flexDirection="column">
                <Text padding="2px 3px">$us.</Text>
                <Text padding="2px 3px">$us.</Text>
                <Text padding="2px 3px">$us.</Text>
                <Text padding="2px 3px">$us.</Text>
                <Text padding="2px 3px">$us.</Text>
                <Text padding="2px 3px">{data.tc}</Text>
                <Text padding="2px 3px">Bs.</Text>
              </View>
              <View
                width="12%"
                flexDirection="column"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Text padding="2px 3px">{data.valorFob}</Text>
                <Text padding="2px 3px">{data.flete1y2}</Text>
                <Text padding="2px 3px">{data.seguro}</Text>
                <Text padding="2px 3px">{data.otrosGastos}</Text>
                <Text padding="2px 3px">{data.valorCif}</Text>
                <Text padding="2px 3px"> </Text>
                <Text padding="2px 3px">{data.valorCifBs}</Text>
              </View>
              <View
                style={{ marginLeft: "5%" }}
                width="50%"
                flexDirection="column"
              >
                <Text padding="2px 3px">
                  <Text padding="2px 3px" bold>
                    Aduana:{" "}
                  </Text>
                  <Text padding="2px 3px">{data.aduana}</Text>
                </Text>
                <Text padding="2px 3px">
                  <Text padding="2px 3px" bold>
                    Modalidad:{" "}
                  </Text>
                  <Text padding="2px 3px">{data.modalidad}</Text>
                </Text>
                <Text padding="2px 3px">
                  <Text padding="2px 3px" bold>
                    Items:{" "}
                  </Text>
                  <Text padding="2px 3px">{data.items}</Text>
                </Text>
                <Text padding="2px 3px">
                  <Text padding="2px 3px" bold>
                    Tipo:{" "}
                  </Text>
                  <Text padding="2px 3px">{data.tipo}</Text>
                </Text>
                <Text padding="2px 3px">
                  <Text padding="2px 3px" bold>
                    DIM/DEX:{" "}
                  </Text>
                  <Text padding="2px 3px">{data.dim}</Text>
                </Text>
                <Text padding="2px 3px">
                  <Text padding="2px 3px" bold>
                    Nro. de factura:{" "}
                  </Text>
                  <Text padding="2px 3px">{data.nroFactura}</Text>
                </Text>
                <Text padding="2px 3px">
                  <Text padding="2px 3px" bold>
                    Fecha de Validación:{" "}
                  </Text>
                  <Text padding="2px 3px">{data.fechaValidacion}</Text>
                </Text>
              </View>
            </View>
            <View style={{ backgroundColor: "#dddddd" }}>
              <Text bold>A - Tributos y otros Conceptos Aduaneros</Text>
            </View>
            {data.aList.map(
              (element) =>
                element.description != null &&
                element.description !== "0" && (
                  <View justifyContent="space-between">
                    <View width="50%">
                      <Text padding="2px 3px">
                        {element.label} {element.recibo ? "*" : ""}
                      </Text>
                    </View>
                    <View width="50%" justifyContent="flex-end">
                      <Text padding="2px 3px" textAlign="end">
                        {element.description}
                      </Text>
                    </View>
                  </View>
                )
            )}
            <View justifyContent="flex-end">
              <View width="10%">
                <Text padding="2px 3px" bold>
                  Sub Total:
                </Text>
              </View>
              <View width="20%" justifyContent="flex-end">
                <Text padding="2px 3px" bold textAlign="end">
                  {data.aSubTotal}
                </Text>
              </View>
            </View>
            <View style={{ backgroundColor: "#dddddd" }}>
              <Text padding="2px 3px" bold>
                B - Otros Gastos de Importacion/Exportacion
              </Text>
            </View>
            {data.bList.map(
              (element) =>
                element.description != null &&
                element.description !== "0" && (
                  <View justifyContent="space-between">
                    <View width="50%">
                      <Text padding="2px 3px">
                        {element.label} {element.recibo ? "*" : ""}
                      </Text>
                    </View>
                    <View width="50%" justifyContent="flex-end">
                      <Text padding="2px 3px" textAlign="end">
                        {element.description}
                      </Text>
                    </View>
                  </View>
                )
            )}
            <View justifyContent="flex-end">
              <View width="10%">
                <Text padding="2px 3px" bold>
                  Sub Total:
                </Text>
              </View>
              <View width="20%" justifyContent="flex-end">
                <Text padding="2px 3px" bold textAlign="end">
                  {data.bSubTotal}
                </Text>
              </View>
            </View>
            <View style={{ backgroundColor: "#dddddd" }}>
              <Text padding="2px 3px" bold>
                C - Gastos de Operaciones
              </Text>
            </View>
            {data.cList.map(
              (element) =>
                element.description != null &&
                element.description !== "0" && (
                  <View justifyContent="space-between">
                    <View width="50%">
                      <Text padding="2px 3px">
                        {element.label} {element.recibo ? "*" : ""}
                      </Text>
                    </View>
                    <View width="50%" justifyContent="flex-end">
                      <Text padding="2px 3px" textAlign="end">
                        {element.description}
                      </Text>
                    </View>
                  </View>
                )
            )}
            <View justifyContent="flex-end">
              <View width="10%">
                <Text padding="2px 3px" bold>
                  Sub Total:
                </Text>
              </View>
              <View width="20%" justifyContent="flex-end">
                <Text padding="2px 3px" bold textAlign="end">
                  {data.cSubTotal}
                </Text>
              </View>
            </View>
            <View style={{ backgroundColor: "#dddddd" }}>
              <Text padding="2px 3px" bold>
                D - Honorarios Profesionales
              </Text>
            </View>
            {data.dList.map(
              (element) =>
                element.description != null &&
                element.description !== "0" && (
                  <View justifyContent="space-between">
                    <View width="50%">
                      <Text padding="2px 3px">
                        {element.label} {element.recibo ? "*" : ""}
                      </Text>
                    </View>
                    <View width="50%" justifyContent="flex-end">
                      <Text padding="2px 3px" textAlign="end">
                        {element.description}
                      </Text>
                    </View>
                  </View>
                )
            )}
            <View justifyContent="flex-end">
              <View width="10%">
                <Text padding="2px 3px" bold>
                  Sub Total:
                </Text>
              </View>
              <View width="20%" justifyContent="flex-end">
                <Text padding="2px 3px" bold textAlign="end">
                  {data.dSubTotal}
                </Text>
              </View>
            </View>
            <View
              flexDirection="column"
              padding="5px"
              border={{ top: true, bottom: true }}
              style={{ marginTop: "5px" }}
            >
              <View justifyContent="flex-end">
                <View width="17%">
                  <Text padding="3px 3px" bold>
                    Total Planilla $us. 6.85
                  </Text>
                </View>
                <View borderContent width="20%" justifyContent="flex-end">
                  <Text padding="3px 3px" bold textAlign="end">
                    {data.totalProformaSus}
                  </Text>
                </View>
              </View>
              <View justifyContent="flex-end">
                <View width="14%">
                  <Text padding="3px 3px" bold>
                    Total Planilla (Bs.)
                  </Text>
                </View>
                <View
                  border={{ right: true, left: true, bottom: true }}
                  width="20%"
                  justifyContent="flex-end"
                >
                  <Text padding="3px 3px" bold textAlign="end">
                    {data.totalProformaBs}
                  </Text>
                </View>
              </View>
              {/* {data.descuentosBs &&
              data.descuentosBs !== "-" &&
              data.descuentosBs !== "0" ? (
                <View justifyContent="flex-end">
                  <View width="12.5%">
                    <Text padding="3px 3px" bold>Descuento (Bs.)</Text>
                  </View>
                  <View
                    border={{ right: true, left: true, bottom: true }}
                    width="20%"
                    justifyContent="flex-end"
                  >
                    <Text padding="3px 3px" bold textAlign="end">
                      {data.descuentosBs}
                    </Text>
                  </View>
                </View>
              ) : null} */}
              <View justifyContent="flex-end">
                <View width="25%">
                  <Text padding="3px 3px" bold>
                    Gastos pagados por el importador
                  </Text>
                </View>
                <View
                  border={{ right: true, left: true, bottom: true }}
                  width="20%"
                  justifyContent="flex-end"
                >
                  <Text padding="3px 3px" bold textAlign="end">
                    {data.otherChargesPaidByTheImporter}
                  </Text>
                </View>
              </View>
              <View justifyContent="flex-end">
                <View width="12%">
                  <Text padding="3px 3px" bold>
                    Total Anticipos
                  </Text>
                </View>
                <View
                  border={{ right: true, left: true, bottom: true }}
                  width="20%"
                  justifyContent="flex-end"
                >
                  <Text padding="3px 3px" bold textAlign="end">
                    {data.totalAdvancePayments}
                  </Text>
                </View>
              </View>

              <View justifyContent="flex-end">
                {parseFloat(data.balanceOfPayroll) < 0 ? (
                  <View width="19%">
                    <Text padding="3px 3px" bold>
                      Saldo a favor Cliente (Bs.)
                    </Text>
                  </View>
                ) : parseFloat(data.balanceOfPayroll) > 0 ? (
                  <View width="20%">
                    <Text padding="3px 3px" bold>
                      Saldo a favor Agencia (Bs.)
                    </Text>
                  </View>
                ) : (
                  <View width="9%">
                    <Text padding="3px 3px" bold>
                      Saldo (Bs.)
                    </Text>
                  </View>
                )}

                <View
                  border={{ right: true, left: true, bottom: true }}
                  width="20%"
                  justifyContent="flex-end"
                >
                  <Text padding="3px 3px" bold textAlign="end">
                    {parseFloat(data.balanceOfPayroll) >= 0
                      ? data.balanceOfPayroll
                      : data.balanceOfPayroll.replace("-", "")}
                  </Text>
                </View>
              </View>
            </View>
            {data.clarifications ? (
              <Text padding="2px 3px" color="#004080" fontSize="7px" bold>
                ACLARACIONES: {data.clarifications}
              </Text>
            ) : null}
            <View>
              <View width="50%" flexDirection="column">
                <Text padding="2px 3px" color="#004080" bold>
                  BANCO UNION S.A. (Cta. Corriente)
                </Text>
                <Text padding="2px 3px">Cuenta En Bs.: {accountNumber}</Text>
                <Text padding="2px 3px">TRADECRUZ S.R.L</Text>
              </View>
              <View width="50%" flexDirection="column">
                <Text padding="2px 3px">
                  Elaborado por: {user.user.name} {user.user.fatherLastName}{" "}
                  {user.user.motherLastName}
                </Text>
                <Text padding="2px 3px">NIT: 375482020</Text>
                <Text padding="2px 3px">SWIFT: BAUNBO22</Text>
              </View>
            </View>
            <Text padding="2px 3px" fontSize="7px">
              Nota.: La presente Proforma esta sujeta a Cambio dentro del plazo
              requerido por Tradecruz SRL por variacion en el T./C.
            </Text>
          </View>
          <View
            flexDirection="column"
            padding="4px 0"
            justifyContent="center"
            alignItems="center"
            style={{ backgroundColor: "#002747" }}
          >
            <Text padding="2px 3px" color="white">
              4to. Anillo entre Av. Banzer y Beni Calle Tristan
              Languidey Nro. 19
            </Text>
            <Text padding="2px 3px" color="white">
              Teléfono: (591)3-3406135 Teléfono: (591)3-385332 - (591)3-3852168
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <div>
      <div className="flex justify-end mb-2">
        <Button className="py-2" onClick={handleOnClick}>
          Guardar planilla <i className="fas fa-cloud-upload-alt"></i>
        </Button>
      </div>
      <PDFViewer
        style={{
          width: 900,
          height: window.innerHeight - 100,
        }}
        children={MyDoc}
      />
    </div>
  );
};

export default RenderSettlement;
