import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { Request } from '../hooks/Request'

ChartJS.register(ArcElement, Tooltip, Legend)

export function GraficChart({ values, year }) {
  const { data: companyId } = Request({
    urlApi: values.id ? `/clientStats/companies/${values?.id}?` : null,
  })
  const data = {
    labels: ['Proceso', 'Concluidos'],
    datasets: [
      {
        label: '# of Votes',
        data: [
          // companyId?.totalStarted,
          companyId?.totalInProgres,
          companyId?.totalFinished,
        ],
        // data: [30, 40, 30],
        backgroundColor: ['rgba(5, 110, 252 )', 'rgba(2, 232, 255,0.5 )'],
        // borderColor: ['rgba(5, 76, 252 )', 'rgba(2, 209, 255 )'],
        borderWidth: 3,
        cutout: 50,
      },
    ],
  }
  return <Doughnut data={data} />
}
