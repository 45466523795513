import RenderPdf from "../../containers/ManegeProcedure/RenderPdf";
import GenerateRoadmap from "../../containers/ManegeProcedure/GenerateRoadmap";
import RenderLiquidaciónProforma from "../../containers/ManegeProcedure/RenderLiquidaciónProforma";
import GenerateFileDelivery from "../../containers/ManegeProcedure/GenerateFileDelivery";
import RenderSettlement from "../../containers/ManegeProcedure/RenderSettlement";
import GenerateReceipt from "../../containers/ManegeProcedure/GenerateReceipt";
// import GenerateTransportReceipt from "../../containers/ManegeProcedure/GenerateTransportReceipt";
import { useDialog } from "../../contexts/DialogContext";
import { useModal } from "../../contexts/ModalContext";
import { validateArray } from "../../utils/validate";
import { Tooltip } from "react-tippy";
import { H3 } from "../../components/Text";
import { DialogConfirmation } from "../../components/Modal";
import ListDocument from "../../components/common/ListDocument";
import ListDocumentVerify from "../../components/common/ListDocumentVerify";
import ViewFormStatus from "../../containers/ManegeProcedure/ViewFormStatus";
import { viewLinkExcel } from "../../utils/constGenerate";
import GenerateOtherReceipt from "../../containers/ManegeProcedure/GenerateOtherReceipt";
import RenderSettlementByExceptions from "../../containers/ManegeProcedure/RenderSettlementByExceptions";
import RenderSelectLogo from "../../containers/ManegeProcedure/RenderSelectLogo";
import PdfProform from "../../containers/ManegeProcedure/PdfProform";
import PdfLiquidation from "../../containers/ManegeProcedure/PdfLiquidation";

const TableLisDocument = ({ listButton, procedure, call }) => {
  const { openDialog, dialogClose } = useDialog();
  const { openModal, onClose } = useModal();

  function listDocumentPdf(procedure) {
    openDialog(
      <RenderPdf
        procedure={procedure}
        openModal={openModal}
        onClose={onClose}
        openDialog={openDialog}
        dialogClose={dialogClose}
      />
    );
  }
  function generateSettlementProforma(procedure) {
    openModal(
      <RenderLiquidaciónProforma
        procedure={procedure}
        openModal={openModal}
        onClose={onClose}
        openDialog={openDialog}
        dialogClose={dialogClose}
      />
    );
  }
  function handleDocument(procedure) {
    openModal(
      <ListDocument
        addDocument={true}
        procedure={procedure}
        openModal={openModal}
        onClose={onClose}
        openDialog={openDialog}
        dialogClose={dialogClose}
        step={parseInt(procedure.step)}
      />
    );
  }
  function generateRoadmap(procedure) {
    openDialog(
      <GenerateRoadmap
        procedure={procedure}
        openModal={openModal}
        onClose={onClose}
        openDialog={openDialog}
        dialogClose={dialogClose}
      />
    );
  }
  function handleDocumentVerify(procedure) {
    openModal(
      <ListDocumentVerify
        procedure={procedure}
        openModal={openModal}
        onClose={onClose}
        openDialog={openDialog}
        dialogClose={dialogClose}
      />
    );
  }
  function generateFileDelivery(procedure) {
    openModal(
      <GenerateFileDelivery
        procedure={procedure}
        openModal={openModal}
        onClose={onClose}
        openDialog={openDialog}
        dialogClose={dialogClose}
      />
    );
  }

  function generateReceipt(procedure) {
    openDialog(<GenerateReceipt procedure={procedure} />);
  }

  function generateOtherReceipt(procedure) {
    openDialog(<GenerateOtherReceipt procedure={procedure} />);
  }

  // function generateTransportReceipt(procedure) {
  //   openDialog(<GenerateTransportReceipt procedure={procedure} />);
  // }
  function SelectLogoTransportElement(procedure) {
    openModal(
      <RenderSelectLogo procedure={procedure} typeReceipt="transporte" />
    );
  }
  function SelectLogoElement(procedure) {
    openModal(<RenderSelectLogo procedure={procedure} typeReceipt="normal" />);
  }

  function handleReadProforma(procedure) {
    openDialog(
      <DialogConfirmation
        children={
          <H3>¿Estas seguro que quieres extraer los datos del excel?</H3>
        }
        onClose={dialogClose}
        method="post"
        url={`Tradecruz/procedure/${procedure.id}/readerXlsxProforma`}
        texBtn="Extraer"
        handleSuccess={() => generateSettlementProforma(procedure)}
      />
    );
  }

  function readTaxReceipt(procedure) {
    openDialog(
      <DialogConfirmation
        children={
          <H3>¿Estas seguro que quieres extraer los recibos de impuestos?</H3>
        }
        onClose={dialogClose}
        method="post"
        url={`/procedure/Receipt/${procedure.id}`}
        texBtn="Extraer"
        // handleSuccess={() => generateSettlementProforma(procedure)}
      />
    );
  }

  function generateSettlement(procedure) {
    openModal(<RenderSettlement procedure={procedure} />);
  }

  function generateSettlementException(procedure) {
    openModal(<RenderSettlementByExceptions procedure={procedure} />);
  }

  function handleStatus(procedure) {
    openModal(
      <ViewFormStatus
        reload={call}
        procedure={procedure}
        openModal={openModal}
        onClose={onClose}
        openDialog={openDialog}
        dialogClose={dialogClose}
      />
    );
  }

  function handleExpenseReport(procedure) {
    openDialog(
      <DialogConfirmation
        children={
          <H3>¿Estas seguro que quieres generar el reporte de gastos?</H3>
        }
        onClose={dialogClose}
        method="post"
        url={`/tradecruz/procedure/${procedure.id}/reportExpense`}
        texBtn="Generar"
        handleSuccess={(data) => {
          viewLinkExcel(data, openDialog);
        }}
      />
    );
  }

  function handleProform(procedure) {
    openDialog(<PdfProform procedure={procedure} />);
  }
  function handleLiquidation(procedure) {
    openDialog(<PdfLiquidation procedure={procedure} />);
  }

  let listGeneralButtons = [
    {
      label: "Generar informe de la recepción de documentos",
      icon: "fas fa-tasks",
      name: "trz-004-recepcionDeDocumentos",
      action: listDocumentPdf,
      color: "text-[#1d4ed8] pl-3",
    },
    {
      label: "Generar proforma",
      icon: "fas fa-coins",
      name: "trz-002-proforma",
      action: handleProform,
      color: "text-[#1d4ed8]",
    },
    {
      label: "Generar entrega de documentos",
      icon: "fas fa-file",
      name: "trz-005-entregaDeDocumentos",
      action: generateFileDelivery,
      color: "text-[#1d4ed8]",
    },
    {
      label: "Generar hoja de ruta",
      icon: "fas fa-route",
      name: "trz-001-hojaDeRuta",
      action: generateRoadmap,
      color: "text-[#1d4ed8] pr-5",
    },
    {
      label: "Ver documentos",
      icon: "fa-solid fa-folder",
      name: "trz-008-verDocumentos",
      action: handleDocument,
      color: "text-[#1d4ed8]",
    },
    {
      label: "Verificar documentos",
      icon: "fa-solid fa-list-check",
      name: "trz-009-verificarDocumentos",
      action: handleDocumentVerify,
      color: "text-[#1d4ed8]",
    },
    {
      label: "Leer proforma",
      icon: "fas fa-file-import",
      name: "trz-010-leerProforma",
      action: handleReadProforma,
      color: "text-[#1d4ed8]",
    },
    {
      label: "Generar liquidación",
      icon: "fas fa-file-invoice",
      name: "trz-011-liquidación",
      action: handleLiquidation,
      color: "text-[#1d4ed8]",
    },
    {
      label: "Generar liquidación (sin recibos)",
      icon: "fas fa-money-bill-wave",
      name: "trz-018-liquidación-sin-recibos",
      action: generateSettlementException,
      color: "text-[#1d4ed8]",
    },
    {
      label: "Leer recibo de impuestos",
      icon: "fas fa-cloud-upload-alt",
      name: "trz-012-leerReciboImpuestos",
      action: readTaxReceipt,
      color: "text-[#1d4ed8]",
    },
    {
      label: "Generar recibo",
      icon: "fas fa-receipt",
      name: "trz-013-generarRecibo",
      action: SelectLogoElement,
      color: "text-[#1d4ed8] pr-5",
    },
    {
      label: "Generar recibo (no marcados)",
      icon: "fas fa-file-invoice",
      name: "trz-017-generarOtroRecibo",
      action: generateOtherReceipt,
      color: "text-[#1d4ed8] pr-5",
    },
    {
      label: "Generar recibo de transporte",
      icon: "fas fa-shuttle-van",
      name: "trz-014-generarReciboDeTransporte",
      action: SelectLogoTransportElement,
      color: "text-[#1d4ed8] pr-5",
    },
    {
      label: "Cambiar tarea",
      icon: "fas fa-stream",
      name: "trz-015-formularioDeTarea",
      action: handleStatus,
      color: "text-[#1d4ed8] pr-5",
    },
    {
      label: "Reporte de gastos",
      icon: "fas fa-file-invoice-dollar",
      name: "trz-016-ReporteDeGastos",
      action: handleExpenseReport,
      color: "text-[#1d4ed8] pr-5",
    },
  ];

  return (
    <>
      {validateArray(listButton)
        ? listButton.map((btn, index) => {
            const button = listGeneralButtons.find(
              (element) => element.name === btn.Name
            );
            if (button === undefined) {
              return null;
            }
            return (
              <div
                key={index}
                className={`${button.color} text-xl cursor-pointer`}
                onClick={() => button.action(procedure)}
              >
                <Tooltip
                  title={button.label}
                  position="right"
                  trigger="mouseenter"
                >
                  <i className={button.icon + " p-1"}></i>
                </Tooltip>
              </div>
            );
          })
        : null}
    </>
  );
};

export default TableLisDocument;
