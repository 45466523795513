import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { Request } from '../../hooks/Request'
import { Texto, H3 } from '../../components/Text'
import { Button } from '../../components/Buttons'
import { Circle } from '../../components/Animation'
import { useDialog } from '../../contexts/DialogContext'
import { DialogConfirmation } from '../../components/Modal'
import FormDefault from '../../components/Forms/FormDefault'
import { requestAuth } from '../../components/services/RequestService'

const ListContact = ({ company }) => {
  const { openDialog, dialogClose } = useDialog()
  const { data, loading, call } = Request({
    urlApi: `/company/${company.id}/contacts`,
  })
  function createContact() {
    openDialog(
      <FormContact
        companyId={company.id}
        reload={call}
        onClose={dialogClose}
      />,
    )
  }

  function editContact(contact) {
    openDialog(
      <FormContact
        contact={contact}
        companyId={company.id}
        reload={call}
        onClose={dialogClose}
      />,
    )
  }

  function deleteDropdown(contact) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres eliminar en contacto?</H3>}
        onClose={dialogClose}
        method="delete"
        url={`/company/contact/${contact.id}`}
        texBtn="Eliminar"
        handleSuccess={call}
      />,
    )
  }

  if (!loading) {
    return <Circle />
  }
  return (
    <div>
      <div className="bg-slate-400 p-[10px] rounded-md my-[15px] text-white flex flex-col shadow-xl">
        <div className="flex justify-between w-full mb-1">
          <Texto className="font-semibold text-black mr-3">
            Contactos de la compañía
          </Texto>
          <Button onClick={createContact} className="px-2 py-1">
            <i className="fas fa-plus"></i>
          </Button>
        </div>
        {data.map((item) => (
          <div className="flex gap-1 mb-1">
            <div className="bg-[#ffffff65] text-black px-2 py-0.5 rounded-md  w-full">
              <h3>Nombre: {item.name}</h3>
              <h3>Teléfono: {item.phone}</h3>
              <h3>Correo electrónico: {item.email}</h3>
            </div>
            <Button
              onClick={() => editContact(item)}
              className="px-2 py-1 bg-[#FBCE00]"
            >
              <i className="fas fa-edit"></i>
            </Button>
            <Button
              onClick={() => deleteDropdown(item)}
              className="px-2 py-1 bg-[#eb4040]"
            >
              <i className="fas fa-trash"></i>
            </Button>
          </div>
        ))}
      </div>
    </div>
  )
}

const FormContact = (props) => {
  const { contact, companyId, onClose, reload } = props
  return (
    <>
      <FormDefault
        tittle={contact ? 'Editar contacto' : 'Crear nuevo contacto'}
        fields={[
          {
            label: 'Nombre del contacto',
            name: 'name',
          },
          {
            label: 'Teléfono de contacto',
            name: 'phone',
          },
          {
            label: 'Correo electronico',
            name: 'email',
          },
          {
            label: 'Contacto principal',
            name: 'main',
            type: 'checkbox',
          },
        ]}
        initialValues={{
          name: contact?.name ?? '',
          phone: contact?.phone ?? '',
          main: contact?.main ?? false,
          email: contact?.email ?? '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Este campo es obligatorio'),
        })}
        onSubmit={async (values) => {
          await requestAuth(
            contact ? 'put' : 'post',
            contact ? '/company/contact' : '/company/contact',
            contact
              ? {
                ...values,
                Id: contact.id,
                CompanyId: companyId,
              }
              : {
                ...values,
                CompanyId: companyId,
              },
          )
            .then(() => {
              onClose()
              reload()
              toast.success('Se creo con éxito')
            })
            .catch((response) => {
              toast.error(
                response.response.data
                  ? response.response.data
                  : 'Se produjo un error al crear',
              )
            })
        }}
        buttonName={contact ? 'Editar' : 'Crear'}
      />
      <br />
    </>
  )
}

export default ListContact
