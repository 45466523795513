import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { H1, H2, H3 } from "../../components/Text";
import * as Yup from 'yup'
import {
  adjuntarDex,
  adjuntarSimplificado,
  readDam,
  readDim,
  readParte,
} from "../../components/services/ReadFile";
import { Request } from "../../hooks/Request";
import { Circle } from "../../components/Animation";
import { Button } from "../../components/Buttons";
import { validateStatus, validateArray } from "../../utils/validate";
import { requestAuth } from "../../components/services/RequestService";
import CallServiceTable from "../../components/Tables/CallServiceTable";
import { useDialog } from "../../contexts/DialogContext";
import FormDefault from "../../components/Forms/FormDefault";

const SendProcedure = (props) => {
  const { procedure, reload, openModal, onClose, isInCharge } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data, loading } = Request({
    urlApi: `/processstep/${procedure.processStepId}/procedure/${procedure.id}?type=2`,
  });

  function saveDataProcedure(sendNextStep = true) {
    const listRead = data.fields.filter(
      (f) => f.type === "fileRead" && f.initial
    );
    setIsSubmitting(true);
    if (listRead && listRead.length > 0) {
      readDataProcedure(0, listRead, [], sendNextStep);
    } else {
      if (sendNextStep) {
        nextStep();
      }
      else {
        setIsSubmitting(false);
        reload();
        onClose();
        toast.success("Envio de correo(s) exitoso!");
      }
    }
  }

  function readDataProcedure(index, fields, result, sendNextStep) {
    if (index < fields.length) {
      switch (fields[index].url) {
        case "adjuntarDim":
          readDim(fields, index, procedure, result, readDataProcedure, sendNextStep);
          break;
        case "dimRegularizada":
          readDim(fields, index, procedure, result, readDataProcedure, sendNextStep);
          break;
        case "adjuntarDex":
          adjuntarDex(fields, index, procedure, result, readDataProcedure, sendNextStep);
          break;
        case "documentoDam":
          readDam(fields, index, procedure, result, readDataProcedure, sendNextStep);
          break;
        case "parteDeRecepcion":
          readParte(fields, index, procedure, result, readDataProcedure, sendNextStep);
          break;
        case "adjuntarDims":
          adjuntarSimplificado(fields, index, procedure, result, readDataProcedure, sendNextStep);
          break;
        case "adjuntarDexs":
          adjuntarSimplificado(fields, index, procedure, result, readDataProcedure, sendNextStep);
          break;
        default:
          break;
      }
    } else {
      const badResult = result.filter(
        (read) => !validateStatus(read.readStatus) && read.validate
      );

      if (validateArray(badResult)) {
        setIsSubmitting(false);
        reload();
        onClose();
        toast.error(badResult[0].readData);
      } else {
        if (sendNextStep) {
          nextStep();
        }
        else {
          setIsSubmitting(false);
          reload();
          onClose();
          toast.success("Envio de correo(s) exitoso!");
        }
      }
    }
  }

  function nextStep() {
    requestAuth("put", `dataset/NextStep/${procedure.id}`)
      .then(() => {
        setIsSubmitting(false);
        reload();
        onClose();
        toast.success("Envio exitoso!");
      })
      .catch(() => {
        setIsSubmitting(false);
        toast.error("El formulario no ha sido completado");
      });
  }

  function handleSendEmail() {
    openModal(
      <EmailParams
        saveDataProcedure={saveDataProcedure}
        openModal={openModal}
        procedure={procedure}
        reload={reload}
        onClose={onClose}
        isInCharge={isInCharge}
      />
    );
  }

  if (!loading) {
    return <Circle />;
  }

  return (
    <div className="flex flex-col justify-center py-[30px]">
      <H1>¿Estás seguro de que quieres enviar el trámite?</H1>
      {isSubmitting ? (
        <div className="w-full h-[100px] flex justify-center items-center">
          <svg className=" animate-spin h-[30px] mr-3" viewBox="0 0 24 24">
            <i className="text-white font-bold fa-solid fa-circle-notch "></i>
          </svg>
        </div>
      ) : (
        <>
          <button
            type="submit"
            className="my-[10px] md:shadow-[0_4px_4px_rgba(0,0,0,0.25)] rounded-[7px] font-semibold text-[20px] leading-6 bg-green-600 text-center text-white py-[15px]"
            onClick={handleSendEmail}
          >
            Enviar correo y continuar
          </button>
          <button
            type="submit"
            className="my-[10px] md:shadow-[0_4px_4px_rgba(0,0,0,0.25)] rounded-[7px] font-semibold text-[20px] leading-6 bg-[#618BCC] text-center text-white py-[15px]"
            onClick={saveDataProcedure}
          >
            Siguiente paso
          </button>
          <button
            type="button"
            onClick={() => onClose()}
            className="my-[10px] md:shadow-[0_4px_4px_rgba(0,0,0,0.25)] rounded-[7px] font-semibold text-[20px] leading-6 bg-[#FBCE00] text-center py-[15px]"
          >
            Cancelar
          </button>
        </>
      )}
    </div>
  );
};
const EmailParams = (props) => {
  const { procedure, openModal, isInCharge } = props;
  // const { openDialog, dialogClose } = useDialog();
  const { data, loading } = Request({
    urlApi: `/processstep/${procedure.processStepId}/mailParam?procedureId=${procedure.id}`,
  });

  function handleSend(values) {
    openModal(
      <SendProcedure
        {...props}
        // listField={listField}
        emailCompany={data.emailCompany}
      />
    );
  }
  function handleSubmit(values) {
    const title = values.title
    const content = values.content
    const subject = values.subject
    const footer = values.footer
    openModal(
      <EmailConfig
        {...props}
        // listField={listField}
        emailCompany={data.emailCompany}
        title={title}
        content={content}
        subject={subject}
        footer={footer}
        fieldList={data.fieldList}
        isInCharge={isInCharge}
      />
    );
  }

  if (!loading) {
    return <Circle />;
  }
  return (
    <div>
      {
        procedure.formCompleted ?
          data ?
            <FormDefault
              tittle={'Notas de correo'}
              fields={[
                {
                  label: "Títutlo",
                  name: "title",
                  placeholder: "Título...",
                  type: "text",
                  typeInput: "input",
                },
                {
                  label: "Asunto",
                  name: "subject",
                  placeholder: "Asunto...",
                  type: "textArea",
                  typeInput: "input",
                  rows: 2,
                },
                {
                  label: "Contenido",
                  name: "content",
                  placeholder: "Contenido...",
                  type: "textArea",
                  typeInput: "input",
                  rows: 3,
                },
                {
                  label: "Píe de página",
                  name: "footer",
                  placeholder: "Píe de página...",
                  type: "text",
                  typeInput: "input",
                },
              ]}
              initialValues={{
                title: data?.title ?? "",
                subject: data?.subject ?? "",
                content: data?.content ?? "",
                footer: data?.footer ?? "",
                // provider: data?.provider ?? "",
              }}
              onSubmit={handleSubmit}
              buttonName="Siguiente"
              disabled={true}
            />
            :
            <div>
              <div>
                <i className="fa fa-triangle-exclamation text-yellow-400" /> No existe plantilla de correo!
              </div>
              <div className="flex justify-start mt-4">
                <Button
                  className="py-2 font-medium bg-yellow-400"
                  content="Volver"
                  onClick={handleSend}
                >
                  Volver
                </Button>
              </div>
            </div>
          :
          <div>
            <div>
              <i className="fa fa-triangle-exclamation text-yellow-400" /> El formulario no se ha completado
            </div>
            <div className="flex justify-start mt-4">
              <Button
                className="py-2 font-medium bg-yellow-400"
                content="Volver"
                onClick={handleSend}
              >
                Volver
              </Button>
            </div>
          </div>
      }
    </div>
  );
};

const EmailConfig = (props) => {
  const { procedure, openModal, fieldList, isInCharge } = props;
  const [listField, setListField] = useState(fieldList);
  const { openDialog, dialogClose } = useDialog();
  const { data, loading } = Request({
    urlApi: `/processstep/${procedure.processStepId}/procedure/${procedure.id}?type=2`,
  });

  function handleList(item, isRemove) {
    if (!isRemove) {
      if (listField.find((element) => element.id === item.id)) {
      } else {
        setListField((a) => [...a, item]);
      }
    } else {
      const listResult = listField.filter((element) => element.id !== item.id);
      setListField(listResult);
    }
  }

  function handleViewField() {
    openDialog(
      <>
        <CallServiceTable
          urlApi="/field"
          header={[
            {
              name: "label",
              label: "Nombre del campo",
              filter: true,
            },
            {
              name: "type",
              label: "Tipo de campo",
              filter: false,
              type: "translate",
              translate: {
                text: "Texto",
                file: "Archivo",
                fileRead: "Archivo",
                number: "Numero",
                selectGet: "Lista desplegable",
                checkbox: "Casilla de verificación",
                textArea: "Caja de texto multi-línea",
                date: "Fecha",
              },
            },

            {
              name: "edit",
              type: "action",
              label: "Añadir",
              actions: [
                {
                  label: "Añadir campo",
                  icon: "fas fa-circle-plus",
                  action: (values) => {
                    handleList(values, false);
                    dialogClose();
                  },
                  color: "text-[#1d4ed8]",
                },
              ],
            },
          ]}
          statusPaination={true}
          showTable={false}
        />
        ,
      </>
    );
  }

  // function handleComeBack() {
  //   openModal(
  //     <EmailParams
  //       {...props}
  //       listField={listField}
  //       emailCompany={data.emailCompany}
  //     />
  //   );
  // }

  function handleSend() {
    openModal(
      <EmailCopyConfig
        {...props}
        listField={listField}
        emailCompany={data.emailCompany}
        isInCharge={isInCharge}
      />
    );
  }

  if (!loading) {
    return <Circle />;
  }
  return (
    <div>
      <H2>Campos a enviar en el correo</H2>
      {validateArray(data.fields) ? (
        <>
          <h3 className="font-semibold mb-2">Campos del paso:</h3>
          <div className="flex flex-wrap gap-1">
            {data.fields.map((item) => {
              const validateAdd = listField.find(
                (element) => element.id === item.id
              )
                ? true
                : false;

              if (item.type !== "completeDocuments") {
                return (
                  <div
                    key={item.id}
                    onClick={() => handleList(item, validateAdd)}
                    className={`${validateAdd ? "opacity-30 bg-blue-300" : "bg-white"
                      } px-3 py-1 rounded-lg cursor-pointer`}
                  >
                    {/* type */}
                    <h3 className="text-sm">
                      <span className="font-medium">{item.label}:</span>{" "}
                      {item.type === "subData" ? (
                        <TextSubData item={item} procedureId={procedure.id} />
                      ) : (
                        item.initial
                      )}{" "}
                    </h3>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </>
      ) : null}
      <H2>Campos globales</H2>
      {validateArray(fieldList) ? (
        <>
          <h3 className="font-semibold mb-2">Campos del paso:</h3>
          <div className="flex flex-wrap gap-1">
            {fieldList.map((item) => {
              const validateAdd = listField.find(
                (element) => element.id === item.id
              )
                ? true
                : false;

              if (item.type !== "completeDocuments") {
                return (
                  <div
                    key={item.id}
                    onClick={() => handleList(item, validateAdd)}
                    className={`${validateAdd ? "opacity-30 bg-blue-300" : "bg-white"
                      } px-3 py-1 rounded-lg cursor-pointer`}
                  >
                    {/* type */}
                    <h3 className="text-sm">
                      <span className="font-medium">{item.label}</span>{" "}
                      {item.type === "subData" ? (
                        <TextSubData item={item} procedureId={procedure.id} />
                      ) : (
                        item.initial
                      )}{" "}
                    </h3>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </>
      ) : null}
      <br />
      <div className="flex justify-between items-center mb-2">
        <h3 className="font-semibold">Campos seleccionados:</h3>
        <Button
          className="py-1 px-2 text-white"
          content="Añadir un campo de otro paso"
          onClick={handleViewField}
        >
          <i className="fas fa-stream"></i>
        </Button>
      </div>
      {validateArray(listField) ? (
        <>
          <div className="flex flex-wrap gap-1">
            {listField.map((item) => {
              if (item.type !== "completeDocuments") {
                return (
                  <div
                    key={item.id}
                    onClick={() => handleList(item, true)}
                    className={`bg-blue-200 px-3 py-1 rounded-lg cursor-pointer`}
                  >
                    <h3 className="text-sm">
                      <span className="font-medium">{item.label}:</span>{" "}
                      {item.type === "subData" ? (
                        <TextSubData item={item} procedureId={procedure.id} />
                      ) : (
                        item.initial
                      )}{" "}
                      <i className="fas fa-times text-red-600"></i>
                    </h3>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </>
      ) : null}
      <>
        {/* <div className="flex justify-between h-screen">
          <Button
            className="py-2 font-medium bg-yellow-400"
            content="Añadir un campo de otro paso"
            onClick={handleComeBack}
          >
            Volver
          </Button>
          </div>*/}
        <div className="flex justify-end mt-4">
          <Button
            className="py-2 font-medium bg-yellow-400"
            content="Añadir un campo de otro paso"
            onClick={handleSend}
          >
            Siguiente
          </Button>
        </div>
      </>
    </div>
  );
};

const EmailCopyConfig = (props) => {
  const { procedure, saveDataProcedure, listField, emailCompany, title, content, subject, footer, isInCharge = false } = props;
  const [listEmail, setListEmail] = useState([]);
  const [groups, setGroups] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [contactsFormat, setContactsFormat] = useState([]);
  const { openDialog, dialogClose } = useDialog()
  const { data, loading, setData } = Request({

    urlApi: "/InstitutionParams/code/DefaultCopyEmail",
  });
  const { data: contacts, loading: contactsLoading, call: refetchContacts } = Request({
    urlApi: `/company/${procedure.companyId}/contacts`,
  });
  useEffect(() => {
    if (loading && data?.value && !data.listEmailInstitution) {
      try {
        var parseable = true
        try {
          JSON.parse(data?.value)
        } catch (error) {
          parseable = false
          toast.error("Formato incorrecto en los grupos de correos");
        }
        if (parseable) {
          setData((a) => ({
            ...a,
            listEmailInstitution: JSON.parse(data.value) || [],
          }));
          setGroups((prevGroups) => {
            const existingGroups = new Set(prevGroups);

            if (data != null) {
              const newGroups = JSON.parse(data.value)?.map((d) => d.Grupo) || [];
              const uniqueNewGroups = newGroups.filter((group) => !existingGroups.has(group));
              const updatedGroups = [...prevGroups, ...uniqueNewGroups];
              return Array.from(new Set(updatedGroups));
            }

            return prevGroups;
          });
        }
        // setListEmail(JSON.parse(data.value) || []);
        setListEmail([]);
      } catch (error) {
        toast.error("Se produjo un error al guardar");
      }
    }
    if (contacts) {
      const modified = contacts.map((c) => ({
        Email: c.email,
        Name: c.name,
      }));
      setContactsFormat(modified);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, contacts, contactsLoading]);

  function handleList(item, isRemove) {
    if (!isRemove) {
      if (listEmail.find((element) => element.Email === item.Email)) {
      } else {
        setListEmail((a) => [...a, item]);
      }
    } else {
      const listResult = listEmail.filter(
        (element) => element.Email !== item.Email
      );
      setListEmail(listResult);
    }
  }

  function handleSend(sendNextStep = true) {
    setButtonDisabled(true)
    if (procedure.formCompleted)
      requestAuth("put", `/dataSet/procedure/${procedure.id}/SendEmail`, {
        fieldIds: listField.map((item) => item.id),
        emails: listEmail,
        title, content, subject, footer, isInCharge
      })
        .then(() => {
          saveDataProcedure(sendNextStep);
        })
        .catch(() => {
          toast.error("El correo no ha sido enviado");
          setButtonDisabled(false)
        });
  }

  if (!loading) {
    return <Circle />;
  }

  function createContact() {
    openDialog(
      <FormContact
        companyId={procedure.companyId}
        reload={refetchContacts}
        onClose={dialogClose}
      />,
    )
  }

  return (
    <div>
      <div className="">
        <H2 className="mb-2">Lista de correos</H2>
        {
          validateArray(groups) && groups?.map((i, idx) => (
            <ItemListEmail
              key={idx}
              groupName={i}
              data={data}
              listEmail={listEmail}
              handleList={handleList}
            />
          ))
        }
        <ItemListEmail
          groupName={`Correo de la compañía`}
          className={'bg-slate-300'}
          data={[{ Name: procedure?.companyName, Email: emailCompany }]}
          listEmail={listEmail}
          handleList={handleList}
          validateGroup={false}
        />
        <ItemListEmail
          groupName={`Contactos de la compañia`}
          className={'bg-slate-300'}
          data={contactsFormat}
          listEmail={listEmail}
          handleList={handleList}
          validateGroup={false}
          aditionalTopComp={<div>
            <Button onClick={createContact} className="px-2 py-1">
              <i className="fas fa-plus"></i>
            </Button>
          </div>}
        />
        <br />
        <H2 className="mb-2">
          Lista de correos a los que se enviara una copia
        </H2>
        {/* <div>
          <h3 className="mb-2">
            <span><span className="font-semibold">{procedure?.companyName}:</span> {emailCompany}</span>
          </h3>
        </div> */}
        <div className="flex flex-wrap gap-2">
          {validateArray(listEmail) ? (
            listEmail.map((email, index) => {
              return (
                <div
                  className={`bg-blue-200 flex items-center gap-2 rounded-lg px-3 py-1 cursor-pointer`}
                  key={index}
                  onClick={() => handleList(email, true)}
                >
                  <h3>
                    <span className="font-semibold">{email.Name}:</span>{" "}
                    {email.Email}
                  </h3>
                  <i className="fas fa-times text-red-600"></i>
                </div>
              );
            })
          ) : (
            <H3>(No hay correos seleccionados)</H3>
          )}
        </div>
      </div>
      <div className="flex justify-end mt-4 gap-2">
        <Button
          className="py-2 font-medium bg-yellow-500"
          content="Añadir un campo de otro paso"
          onClick={() => handleSend(false)}
          disabled={buttonDisabled}
        >
          Enviar correo(s) <i className="fas fa-paper-plane"></i>
        </Button>
        <Button
          className="py-2 font-medium bg-green-500"
          content="Añadir un campo de otro paso"
          onClick={() => handleSend(true)}
          disabled={buttonDisabled}
        >
          Enviar correo(s) y al siguiente paso <i className="fas fa-paper-plane"></i>
        </Button>
      </div>
    </div>
  );
};

const TextSubData = ({ procedureId, item }) => {
  const { data, loading } = Request({
    urlApi: `/dataSet/procedure/${procedureId}/field/${item.id}/subdata`,
  });

  if (!loading) {
    return null;
  }
  return validateArray(data) ? (
    <>
      {data.map((item) => (
        <>
          {item.label}
          {item.description ? ` - ${item.description}` : ""}
        </>
      ))}
    </>
  ) : null;
};

export default SendProcedure;

const ItemListEmail = ({ groupName, data, listEmail, handleList, className = 'bg-slate-300', validateGroup = true, aditionalTopComp = null }) => {
  const addGroup = () => {
    if (validateGroup)
      for (const i of data?.listEmailInstitution?.filter(e => e.Grupo === groupName)) {
        handleList(i, false)
      }
  }
  return (
    <div className={`${className} p-2 my-1 rounded-lg`}>
      <div className="flex justify-between">
        <div className="mb-1 font-semibold cursor-pointer" onClick={() => addGroup()}>{groupName}</div>
        {
          aditionalTopComp &&
          aditionalTopComp
        }
      </div>
      <div className="flex flex-wrap gap-2">
        {
          validateGroup &&
          <>
            {
              validateArray(data?.listEmailInstitution) ? (
                data?.listEmailInstitution?.filter(e => e.Grupo === groupName)?.map((email, index) => {
                  const validateAdd = listEmail.find(
                    (element) => element.Email === email.Email
                  )
                    ? true
                    : false;
                  return (
                    <div key={index} onClick={() => handleList(email, validateAdd)} className='cursor-pointer'>
                      <h3
                        className={`${!validateAdd ? "bg-white" : "bg-white opacity-40"
                          }  rounded-lg px-3 py-1`}
                      >
                        <span className="font-semibold">{email.Name}:</span>{" "}
                        {email.Email}
                      </h3>
                    </div>
                  );
                })
              ) : (
                <H3>No hay correos</H3>
              )
            }
          </>
        }
        {
          !validateGroup &&
          <>
            {
              validateArray(data) ? (
                data?.map((email, index) => {
                  const validateAdd = listEmail.find(
                    (element) => element.Email === email.Email
                  )
                    ? true
                    : false;
                  return (
                    <div key={index} onClick={() => handleList(email, validateAdd)} className='cursor-pointer'>
                      <h3
                        className={`${!validateAdd ? "bg-white" : "bg-white opacity-40"
                          }  rounded-lg px-3 py-1`}
                      >
                        <span className="font-semibold">{email.Name}:</span>{" "}
                        {email.Email}
                      </h3>
                    </div>
                  );
                })
              ) : (
                <H3>No hay correos</H3>
              )
            }
          </>
        }
      </div>
    </div>
  )
}

const FormContact = (props) => {
  const { contact, companyId, onClose, reload } = props
  return (
    <>
      <FormDefault
        tittle={contact ? 'Editar contacto' : 'Crear nuevo contacto'}
        fields={[
          {
            label: 'Nombre del contacto',
            name: 'name',
          },
          {
            label: 'Teléfono de contacto',
            name: 'phone',
          },
          {
            label: 'Correo electronico',
            name: 'email',
          },
          {
            label: 'Contacto principal',
            name: 'main',
            type: 'checkbox',
          },
        ]}
        initialValues={{
          name: contact?.name ?? '',
          phone: contact?.phone ?? '',
          main: contact?.main ?? false,
          email: contact?.email ?? '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Este campo es obligatorio'),
        })}
        onSubmit={async (values) => {
          await requestAuth(
            contact ? 'put' : 'post',
            contact ? '/company/contact' : '/company/contact',
            contact
              ? {
                ...values,
                Id: contact.id,
                CompanyId: companyId,
              }
              : {
                ...values,
                CompanyId: companyId,
              },
          )
            .then(() => {
              onClose()
              reload()
              toast.success('Se creo con éxito')
            })
            .catch((response) => {
              toast.error(
                response.response.data
                  ? response.response.data
                  : 'Se produjo un error al crear',
              )
            })
        }}
        buttonName={contact ? 'Editar' : 'Crear'}
      />
      <br />
    </>
  )
}