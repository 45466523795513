import * as Yup from "yup";
import { toast } from "react-toastify";
import { H3 } from "../../../components/Text";
import { Button } from "../../../components/Buttons";
import { Circle } from "../../../components/Animation";
import FormDefault from "../../../components/Forms/FormDefault";
import { validateArray } from "../../../utils/validate";
import { Request } from "../../../hooks/Request";
import { useDialog } from "../../../contexts/DialogContext";
import { requestAuth } from "../../../components/services/RequestService";
import { DialogConfirmation } from "../../../components/Modal";

const FieldsCompany = (props) => {
  const { companyId } = props;
  const { openDialog, dialogClose } = useDialog();

  const { data, loading, call } = Request({
    urlApi: `company/${companyId}/details`,
  });

  function handleFormField(field) {
    openDialog(
      <FormFieldCompany
        field={field}
        call={call}
        companyId={companyId}
        dialogClose={dialogClose}
      />
    );
  }

  function handleDeleteField(field) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Desea eliminar el dato?</H3>}
        onClose={dialogClose}
        method="delete"
        url={`/company/detail/${field.id}`}
        texBtn="Eliminar"
        handleSuccess={call}
      />
    );
  }

  if (!loading) {
    return <Circle />;
  }

  return (
    <div className="bg-slate-400 p-[10px] rounded-md my-[15px] font-normal text-white shadow-xl">
      <div className="flex items-center gap-2">
        <h3 className="font-semibold  text-black">Datos adicionales</h3>
        <Button onClick={() => handleFormField()} className="px-2 py-1">
          <i className="fas fa-plus"></i>
        </Button>
      </div>
      {validateArray(data) ? (
        data.map((field) => {
          const jsonValue =
            field.value === "[]"
              ? [{ value: "" }, { value: "" }, { value: "" }]
              : JSON.parse(field.value);
          return (
            <div
              key={field.id}
              className="flex justify-between items-center pl-1 pb-1 mt-1 border-b-2 border-slate-600"
            >
              <h4>
                <span className="font-semibold text-black">{field.label}:</span>{" "}
                <div className="">
                  {validateArray(jsonValue)
                    ? jsonValue.map((item) =>
                        item?.value ? (
                          <div className="">- {item.value}</div>
                        ) : null
                      )
                    : null}
                </div>
              </h4>
              <div className="flex gap-1">
                <Button
                  onClick={() =>
                    handleFormField({ ...field, jsonValue: jsonValue })
                  }
                  className="px-2 py-1 bg-slate-600"
                >
                  <i className="far fa-edit"></i>
                </Button>
                <Button
                  onClick={() => handleDeleteField(field)}
                  className="px-2 py-1 bg-red-600"
                >
                  <i className="fas fa-minus"></i>
                </Button>
              </div>
            </div>
          );
        })
      ) : (
        <p className="text-center">No tienes datos adicionales</p>
      )}
    </div>
  );
};

const FormFieldCompany = ({ companyId, call, dialogClose, field = null }) => {
  async function onSubmit(values) {
    const auxValue = [
      { value: values.value1 },
      { value: values.value2 },
      { value: values.value3 },
    ];

    if (values.label_isNew) {
      await handleCreateOption(values.label, "additionalCompanyInformation");
    }
    await requestAuth(
      field ? "put" : "post",
      "/company/detail",
      field
        ? {
            ...values,
            Id: field.id,
            value: JSON.stringify(auxValue),
          }
        : {
            ...values,
            CompanyId: companyId,
            value: JSON.stringify(auxValue),
          }
    )
      .then(() => {
        call();
        if (field) {
          dialogClose();
        }
        toast.success("Se guardo exitosamente");
      })
      .catch(() => {
        toast.error("No se pudo guardar");
      });
  }

  async function handleCreateOption(value, dropdown) {
    await requestAuth("post", "/dropdown/option", {
      Name: value,
      DropdownListLabel: dropdown,
    });
  }

  return (
    <div>
      <FormDefault
        tittle={field ? "Editar campo" : "Adicionar campo adicional"}
        fields={[
          {
            label: "Campo",
            name: "label",
            typeInput: "selectNewOption",
            urlApi: "/dropdown/additionalCompanyInformation/options",
            value: "name",
            labelOption: "name",
          },
          {
            label: "Valor del campo",
            name: "value1",
            type: "text",
            typeInput: "input",
          },
          {
            label: "Valor del campo",
            name: "value2",
            type: "text",
            typeInput: "input",
          },
          {
            label: "Valor del campo",
            name: "value3",
            type: "text",
            typeInput: "input",
          },
        ]}
        initialValues={{
          label: field?.label || "",
          value1: field?.jsonValue[0].value || "",
          value2: field?.jsonValue[1].value || "",
          value3: field?.jsonValue[2].value || "",
        }}
        validationSchema={Yup.object().shape({
          label: Yup.string().required("Este campo es obligatorio"),
          value1: Yup.string().required("Este campo es obligatorio"),
        })}
        onSubmit={onSubmit}
        buttonName="Guardar"
      />
      <br />
    </div>
  );
};

export default FieldsCompany;
