import { H3 } from "../../components/Text";
import { Button } from "../../components/Buttons";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDialog } from "../../contexts/DialogContext";
import { useParams } from "react-router-dom";
import { Request } from "../../hooks/Request";
import { validateArray } from "../../utils/validate";
import { useSocket } from "../../contexts/SocketContext";
import { requestAuth } from "../../components/services/RequestService";
import Circle from "../../components/Animation/Circle";
import { DialogConfirmation } from "../../components/Modal";
import logo from "../../assets/logo45-5.png";

const ViewNotification = () => {
  const { rolId } = useParams();
  const [page, setPage] = useState(1);
  const { openDialog, dialogClose } = useDialog();
  const [viewNotify, setViewNotify] = useState(false);
  const { connection, setConnectionId } = useSocket();

  const { data, loading, pagination, call } = Request({
    urlApi: `/Notification?PageNumber=${page}`,
  });

  useEffect(() => {
    const connect = connection.connect;
    if (connect) {
      connect
        .start()
        .then(() => {
          connect.on("notifyStep", (n) => {
            const notify = JSON.parse(n);
            Notification.requestPermission().then(() => {});
            setTimeout(() => {
              if (Notification.permission === "granted") {
                console.log("notificación enviada");
                const notificationWindow = new Notification(
                  "Tienes un nuevo tramite pendiente",
                  {
                    icon: logo,
                    body: `Nuevo tramite en el paso ${notify.NumberStep}.${notify.NameStep} (${notify.NumberCode})`,
                    // body: `Nuevo tramite en el paso ${notify.NumberStep}.${notify.NameStep} del cliente ${notify.NameStep} (${notify.NumberCode})`,
                  }
                );
                notificationWindow.onclick = function () {
                  const urlLog = `${
                    window.location.origin
                  }/rol/${rolId}/procedure-type/${1}/step/${notify.NumberStep}`;
                  window.open(urlLog, "_blank");
                };
              }
              call();
            }, 1000);
            setPage(1);
          });
          requestAuth(
            "post",
            `/auth/SocketConnection/${connect.connection.connectionId}`
          ).then(() => {
            setConnectionId(connect.connection.connectionId);
          });
        })
        .catch((e) => {
          // console.log("Connection failed: ", e);
        });
    }
  });

  async function handleRemoveAll() {
    openDialog(
      <DialogConfirmation
        children={
          <H3>¿Estas seguro que quieres eliminar todas las notificaciones?</H3>
        }
        onClose={dialogClose}
        method="put"
        url="/notification/removeAll"
        texBtn="Eliminar"
        handleSuccess={call}
      />
    );
  }

  if (!loading) {
    return <Circle />;
  }

  return (
    <div>
      <button
        onClick={() => setViewNotify(!viewNotify)}
        className="relative flex flex-row justify-between items-center p-1 pr-2"
      >
        <div className="flex items-center justify-center text-white text-[1.8rem]">
          <i className="fa-solid fa-bell"></i>
        </div>
        {validateArray(data) ? (
          <div className="bg-red-500 px-1 absolute right-0 top-1 rounded-full">
            <h3 className="text-xs text-white">{data.length}</h3>
          </div>
        ) : null}
      </button>
      {viewNotify && (
        <>
          <div
            onClick={() => setViewNotify(!viewNotify)}
            className="absolute w-screen h-screen top-0 left-0"
          ></div>
          <div className="absolute z-10 bg-[#978d8d] w-fit right-4 md:right-10 mt-2 rounded-md">
            <div className="bg-[white] shadow-2xl border-[#0000002c] border-[1px] shadow-[#0000006c] rounded-md">
              <div className="w-[270px] md:w-[320px] py-2">
                <div className="flex justify-between items-center px-2">
                  <H3 className="text-lg sm:text-[1.4rem] xl:text-[1.4rem] text-start">
                    Notificaciones
                  </H3>
                  <Button
                    className="px-2 py-1 bg-[#f58484]"
                    content="Eliminar todas las notificaciones"
                    onClick={handleRemoveAll}
                  >
                    <i className="fas fa-trash"></i>
                  </Button>
                </div>
                <ListNotify
                  setViewNotify={setViewNotify}
                  setPage={setPage}
                  data={data}
                  loading={loading}
                  call={call}
                  pagination={pagination}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const ListNotify = (props) => {
  const { rolId } = useParams();
  const { setViewNotify, data, call } = props;

  async function handleOnClick(notify) {
    await requestAuth("put", `/notification/${notify.id}`).then(() => {
      call();
    });
    setViewNotify(false);
  }

  return (
    <div className=" max-h-96 overflow-auto">
      {validateArray(data) ? (
        data.map((notify) => (
          <Link
            key={notify.id}
            to={`/rol/${rolId}/procedure-type/${notify.procedureTypeId}/step/${notify.step}`}
            onClick={() => handleOnClick(notify)}
            className="flex gap-2 bg-[#618ccc60] px-4 py-2 my-1"
          >
            <i className="fas fa-handshake px-[8px] py-[10px] bg-[#618BCC] rounded-full"></i>
            <H3 className="text-start leading-none font-normal">
              Nuevo tramite en el paso {notify.step} del cliente{" "}
              <span className="text-gray-700">{notify.nameCompanie}</span>{" "}
              <span className="text-blue-800">({notify.interCode})</span>
            </H3>
          </Link>
        ))
      ) : (
        <H3 className="font-normal">No hay notificaciones</H3>
      )}
    </div>
  );
};

export default ViewNotification;
