import { Label } from "../Text";
import ListDocument from "../common/ListDocument";

const CompleteDocuments = ({ field, addDocument = false }) => {
  return (
    <div>
      <Label className={`mt-2`}>
        {field.labelCustom ? field.labelCustom : field.label}
      </Label>
      <ListDocument
        procedure={field}
        step={parseInt(field.step)}
        addDocument={addDocument}
      />
    </div>
  );
};

export default CompleteDocuments;
