import { Request } from "../../hooks/Request";
import { Circle } from "../../components/Animation";
import View from "../../components/GeneratePdf/View";
import { validateArray } from "../../utils/validate";
import Text from "../../components/GeneratePdf/Text";
import { Page, Document, PDFViewer, Image, Link } from "@react-pdf/renderer";
import CustomTablePDF from "../../components/GeneratePdf/CustomTablePDF";

const GenerateRoadmap = (props) => {
  const { procedure } = props;
  const { data, loading } = Request({
    urlApi: `/tradecruz/${procedure.id || procedure.procedureId}/HojaDeRuta`,
  });

  if (!loading) {
    return <Circle />;
  }
  function handleBreakDown(list) {
    let newList = [];
    if (validateArray(list)) {
      list.forEach((field) => {
        if (validateArray(field.fileStatusList)) {
          field.fileStatusList.forEach((file) => {
            newList.push(file);
          });
        }
      });
    }
    return newList;
  }
  const MyDoc = (
    <Document>
      <Page size="A4" style={{ padding: "20px" }}>
        <View borderContent justifyContent="space-between" alignItems="center">
          <View width="25%">
            {/* <Image src={logotipo} style={{ height: "50px" }} /> */}
          </View>
          <Text textAlign="center" fontSize="12px" bold>
            HOJA DE RUTA
          </Text>
          <View
            width="25%"
            flexDirection="column"
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <Text fontSize="15px" bold textAlign="end">
              {data.intern}
            </Text>
            <Text fontSize="8px">Fecha de inicio: {data.startDate}</Text>
            <Text fontSize="8px">ID de cliente: {data.idCliente}</Text>
          </View>
        </View>
        <Text
          bold
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#dfdfdf" }}
        >
          Datos generales
        </Text>
        <View border={{ right: true, bottom: true, left: true }}>
          <View width="50%" flexDirection="column">
            <Text border={{ right: true }}>
              Importador / exportador: {data.client}
            </Text>
            <Text border={{ right: true }}>NIT: {data.nitImportador}</Text>
            <Text border={{ right: true }}>
              Rep. legal: {data.legalRepresentative}
            </Text>
            <Text border={{ right: true }} bold fontSize="9px">
              Intermediario: {data.intermediario}
            </Text>
          </View>
          <View width="50%" flexDirection="column">
            <Text>Elaborador por: {data.elaboradoPor}</Text>
            <Text>Prioridad: {data.prioridad}</Text>
            <Text>Encargado: {data.inCharge}</Text>
          </View>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <View width="50%" flexDirection="column">
            <Text>Descripción de mercancía: {data.product}</Text>
            <Text>Aduana: {data.aduana}</Text>
            <Text>Patrón/régimen: {data.regimenPatronImportación}</Text>
          </View>
          <View width="50%" flexDirection="column">
            <Text border={{ left: true }}>
              Proveedor/Emisor: {data.proveedorEmisor}
            </Text>
            <Text border={{ left: true }}>
              Factura comercial: {data.facturaComercial}
            </Text>
            <Text border={{ left: true }}>
              Empresa de transporte: {data.empresaTransporte}
            </Text>
            <Text border={{ left: true }}>
              Documento de embarque: {data.documentoEmbarque}
            </Text>
          </View>
        </View>
        <Text
          bold
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#dfdfdf" }}
        >
          Documentos del trámite
        </Text>
        <View flexDirection="column">
          <CustomTablePDF
            fields={[
              {
                label: "N°",
                name: "counter",
                width: "5",
              },
              {
                label: "Documento",
                name: "name",
                width: "25",
              },
              {
                label: "Número",
                name: "number",
                width: "28",
              },
              {
                label: "Proveedor/Emisor",
                name: "provider",
                width: "23",
              },
              {
                label: "Estado",
                name: "status",
                width: "10",
              },
              {
                label: "Enlace",
                name: "url",
                type: "link",
                width: "10",
              },
            ]}
            border={{ right: true, bottom: true, left: true }}
            data={data.documentList}
          />
          <Text
            textAlign="rigt"
            fontSize="10px"
            bold
            border={{ right: true, bottom: true, left: true }}
          >
            Total Documentos: {data.counter}
          </Text>
        </View>
        <Text
          bold
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#dfdfdf" }}
        >
          Datos del tramite
        </Text>
        <View border={{ right: true, bottom: true, left: true }}>
          <View width="40%" flexDirection="column" border={{ right: true }}>
            {data.numeroDam !== "-" ? (
              <View flexDirection="column">
                <Text fontSize="8px">DAM: {data.client}</Text>
                <Text fontSize="8px">Responsable: {data.client}</Text>
                <Text fontSize="8px" border={{ bottom: true }}>
                  Fecha: {data.client}
                </Text>
              </View>
            ) : (
              <View></View>
            )}
            <Text fontSize="8px">DIM: {data.numeroDim}</Text>
            <Text fontSize="8px">Transmisión: {data.liquidador}</Text>
            <Text fontSize="8px">Fecha: {data.fechaTransmision}</Text>
          </View>
          <View width="60%" flexDirection="column">
            <Text fontSize="8px">Canal: {data.canal}</Text>
            <Text fontSize="8px">Fecha: {data.canalFecha}</Text>
            <Text fontSize="8px">
              Registro observaciones: {data.observacionesDeLaDim}
            </Text>
          </View>
        </View>
        <Text
          bold
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#dfdfdf" }}
        >
          Gestión de mercadería
        </Text>
        <View border={{ right: true, bottom: true, left: true }}>
          <View width="40%" flexDirection="column">
            <Text>Retiro mercadería</Text>
            <Text>Fecha: {data.fechaDeRetiroDeMercaderia}</Text>
            <Text>Responsable: {data.gestor}</Text>
          </View>
          <View border={{ left: true }} width="60%" flexDirection="column">
            <Text>Obs. aforo:</Text>
            <View style={{ flexWrap: "wrap" }} width="60%">
              <Text>Fotografías del retiro de la mercadería:</Text>
              {data.fotografíaDeRetiroDeMercadería.map((item, index) => (
                <Link src={item.url} key={index}>
                  <Text color="#1d4ed8">
                    {item.description ? item.description : "Fotografía"}
                  </Text>
                </Link>
              ))}
            </View>
          </View>
        </View>
        <Text
          bold
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#dfdfdf" }}
        >
          Datos contables
        </Text>
        <View border={{ right: true, bottom: true, left: true }}>
          <View width="40%" flexDirection="column">
            <Text border={{ right: true }}>
              Planillado: {data.planillado}
              {data.siAdjuntarDocumento && data.siAdjuntarDocumento !== "-" ? (
                <>
                  {" - "}
                  <Link src={data.siAdjuntarDocumento}>
                    <Text color="#1d4ed8">Documento</Text>
                  </Link>
                </>
              ) : null}
            </Text>
            <Text border={{ right: true }}>
              Fecha de planillado: {data.fechaPlanillado}
            </Text>
          </View>
          <View width="60%" flexDirection="column">
            <Text>
              Facturación: {data.siFacturado}
              {data.facturacion && data.facturacion !== "-" ? (
                <>
                  {" - "}
                  <Link src={data.facturacion}>
                    <Text color="#1d4ed8">Documento</Text>
                  </Link>
                </>
              ) : null}
            </Text>
            <Text>Fecha de facturación: {data.fechaFacturacion}</Text>
          </View>
        </View>
        <Text
          bold
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#dfdfdf" }}
        >
          Incidencias
        </Text>
        {validateArray(data.problemasDeDespacho) ? (
          <CustomTablePDF
            fields={[
              {
                label: "Descripción",
                name: "label",
                width: "50",
              },
              {
                label: "Observación",
                name: "description",
                width: "50",
              },
            ]}
            border={{ right: true, bottom: true, left: true }}
            data={data.problemasDeDespacho.slice(0, 4)}
          />
        ) : null}
        <Text
          bold
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#dfdfdf" }}
        >
          Archivo
        </Text>
        <View
          flexDirection="column"
          border={{ right: true, bottom: true, left: true }}
        >
          <Text>Hojas totales: {data.cantidadDeHojasTotales}</Text>
          <Text>Fecha de archivo: {data.fechaDeArchivo}</Text>
          <Text>Observaciones de archivo: {data.observacionesDelArchivo}</Text>
        </View>
      </Page>
      <Page size="A4" style={{ padding: "20px" }}>
        <View borderContent justifyContent="space-between" alignItems="center">
          <View width="25%">
            {/* <Image src={logotipo} style={{ height: "50px" }} /> */}
          </View>
          <Text textAlign="center" fontSize="12px" bold>
            PROCESO DE CONTROL DOCUMENTAL
          </Text>
          <View
            width="25%"
            flexDirection="column"
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <Text fontSize="8px">Código: GOP-PR-RD-CR-GES-LIQ</Text>
            <Text fontSize="8px">Versión: 1.5</Text>
            <Text fontSize="8px">Fecha de versión: 18/05/2023</Text>
          </View>
        </View>
        <Text
          bold
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#dfdfdf" }}
        >
          Controles del trámite
        </Text>
        <View border={{ right: true, bottom: true, left: true }}>
          <View width="100%" flexDirection="column">
            <CustomTablePDF
              fields={[
                {
                  label: "Item",
                  name: "counter",
                  width: "10",
                },
                {
                  label: "Area",
                  name: "area",
                  width: "10",
                },
                {
                  label: "Topico",
                  name: "nombre",
                  width: "45",
                },
                {
                  label: "Estado",
                  name: "estado",
                  width: "20",
                },
                {
                  label: "Observaciones",
                  name: "observaciones",
                  width: "15",
                },
              ]}
              border={{ right: true, bottom: true, left: true }}
              data={data.controlData}
              //data={handleBreakDown(data.rvDoc)}
            />
          </View>
        </View>
        <Text
          bold
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#dfdfdf" }}
        >
          Revisiones
        </Text>
        <View border={{ right: true, bottom: true, left: true }}>
          <View width="30%" flexDirection="column">
            <Text>
              *(ASTERISCO EN LA COLUMNA "ITEM") ACCIONES QUE SE REPITEN EN AMBAS
              AREAS)
            </Text>
          </View>
          <View width="15%" flexDirection="column">
            <Text>GESTORA</Text>
          </View>
          <View width="15%" flexDirection="column">
            <Text>REVISADO POR:</Text>
            <Text>FECHA</Text>
          </View>
          <View width="40%" flexDirection="column" marginButton="5px">
            <Text border={{ bottom: true }}>{data.gestor}</Text>
            <Text border={{ bottom: true }}>{data.fechaGestoria}</Text>
          </View>
        </View>
        <View border={{ right: true, bottom: true, left: true }}>
          <View width="30%" flexDirection="column">
            <Text>
              *(ASTERISCO EN LA COLUMNA "TOPICO") ACCION DE APOYO POR EL AREA DE
              GESTORIA (NO GENERA RESPONSABILIDAD)
            </Text>
          </View>
          <View width="15%" flexDirection="column">
            <Text>LIQUIDACIÓN</Text>
          </View>
          <View width="15%" flexDirection="column">
            <Text>REVISADO POR:</Text>
            <Text>FECHA</Text>
          </View>
          <View width="40%" flexDirection="column">
            <Text border={{ bottom: true }}>{data.liquidador}</Text>
            <Text border={{ bottom: true }}>{data.fechaLiquidacion}</Text>
          </View>
        </View>
        <Text
          bold
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#dfdfdf" }}
        >
          Versiones del documento
        </Text>
        <View border={{ right: true, bottom: true, left: true }}>
          <View width="33%" flexDirection="column">
            <Text bold border={{ right: true, bottom: true }}>
              NRO DE HOJAS
            </Text>
            <Text border={{ right: true, bottom: true }}>{data.clientId}</Text>
          </View>
          <View width="33%" flexDirection="column">
            <Text bold border={{ right: true, bottom: true }}>
              VERSION ANTECESORA
            </Text>
            <Text border={{ right: true, bottom: true }}>
              GOP-PR-GES-LIQ 1.4
            </Text>
          </View>
          <View width="34%" flexDirection="column">
            <Text bold border={{ right: true, bottom: true }}>
              FECHA
            </Text>
            <Text border={{ right: true, bottom: true }}>17/10/2022</Text>
          </View>
        </View>
      </Page>
      <Page size="A4" style={{ padding: "20px" }}>
        <View borderContent justifyContent="space-between" alignItems="center">
          <View width="25%">
            {/* <Image src={logotipo} style={{ height: "50px" }} /> */}
          </View>
          <Text textAlign="center" fontSize="12px" bold>
            INDICADOR DE RENDIMIENTO (KPI)
          </Text>
          <View
            width="25%"
            flexDirection="column"
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <Text fontSize="8px">Código: {data.startDate}</Text>
            <Text fontSize="8px">Versión: {data.clientId}</Text>
            <Text fontSize="8px">Nro páginas: {data.clientId}</Text>
          </View>
        </View>
        <Text
          bold
          borderContent
          style={{ marginTop: "5px", backgroundColor: "#dfdfdf" }}
        >
          TRAZABILIDAD POR PROCESOS
        </Text>
        <Text bold border={{ right: true, bottom: true, left: true }}>
          Duración Total: {data.totalDuration}
        </Text>
        <CustomTablePDF
          fields={[
            // {
            //   label: 'Nro',
            //   name: 'label',
            //   width: '5',
            // },
            {
              label: "N°",
              name: "step",
              width: "4",
            },
            {
              label: "Encargado",
              name: "userInChange",
              width: "17",
            },
            {
              label: "Paso",
              name: "stepName",
              width: "18",
            },
            {
              label: "Description",
              name: "description",
              width: "19",
            },
            {
              label: "Inicio",
              name: "initialDate",
              type: "date",
              width: "17",
            },
            {
              label: "Final",
              name: "finalDate",
              type: "date",
              width: "17",
            },
            {
              label: "Duración",
              name: "durationString",
              width: "28",
            },
          ]}
          data={data.procedureHistories}
          border={{ right: true, bottom: true, left: true }}
        />
        <View border={{ right: true, bottom: true, left: true }}>
          {data.financeData ? (
            <View width="50%" flexDirection="column">
              <Text bold border={{ right: true, bottom: true }}>
                FINANZAS
              </Text>
              <Text border={{ right: true }}>
                Revisión carpeta: {data.client}
              </Text>
              <Text border={{ right: true, bottom: true }}>
                Fecha: {data.client}
              </Text>
              <Text bold border={{ right: true, bottom: true }}>
                PLANILLA / FACTURA: {data.client}
              </Text>
              <Text border={{ right: true }}>Fecha: {data.client}</Text>
              <Text border={{ right: true }}>Nro planilla: {data.client}</Text>
              <Text border={{ right: true }}>Nro Factura: {data.client}</Text>
              <Text border={{ right: true, bottom: true }}>
                Responsable: {data.client}
              </Text>
              <Text bold border={{ right: true, bottom: true }}>
                NOTAS / OBSERVACIONES
              </Text>
              <Text border={{ right: true }}>{data.client}</Text>
            </View>
          ) : (
            <View></View>
          )}
          {data.archiveData ? (
            <View width="50%" flexDirection="column">
              <Text bold border={{ right: true, bottom: true }}>
                ARCHIVO
              </Text>
              <Text border={{ right: true }}>
                Revisión archivo: {data.client}
              </Text>
              <Text border={{ right: true }}>Fecha: {data.client}</Text>
              <Text border={{ right: true }}>Responsable: {data.client}</Text>
              <Text border={{ right: true, bottom: true }}>
                Observacion: {data.client}
              </Text>
              <Text border={{ right: true }}>Archivo: {data.client}</Text>
              <Text border={{ right: true }}>Fecha: {data.client}</Text>
              <Text border={{ right: true, bottom: true }}>
                Observacion: {data.client}
              </Text>
              <Text border={{ right: true, bottom: true }}>
                Estado: {data.client}
              </Text>
            </View>
          ) : (
            <View></View>
          )}
        </View>
      </Page>
    </Document>
  );

  return (
    <div>
      <PDFViewer
        style={{
          width: 900,
          height: window.innerHeight - 100,
        }}
        children={MyDoc}
      />
    </div>
  );
};

export default GenerateRoadmap;
