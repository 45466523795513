import React, { useState } from 'react'
import { GraficChart } from '../../containers/GraficChart'
import { useAuth } from '../../contexts/AuthContext'
import { Request } from '../../hooks/Request'
import { H1 } from '../../components/Text'
import { H3 } from '../../components/Text'
import Texto from '../../components/Text/Texto'
import Label from '../../components/Text/Label'
import { useEffect } from 'react'

const Summary = () => {
  const { user } = useAuth()

  return (
    <div className="bg-slate-200 h-full p-[10px] sm:p-[20px]">
      {user?.company?.length === 0 ? null : (
        <div className="bg-white m-[20px] p-[10px] sm:p-[20px] flex flex-col items-center">
          {user?.company?.length === 1 ? (
            <H1>INFORMACIÓN DE LA COMPAÑÍA</H1>
          ) : (
            <H1>INFORMACIÓN DE LAS COMPAÑÍAS</H1>
          )}
          {user?.company?.map((company, index) => (
            <CompanySection key={index} company={company} />
          ))}
        </div>
      )}
      <div className="bg-white m-[20px] p-[10px] sm:p-[20px] flex flex-col items-center">
        <H1>INFORMACIÓN DE USUARIO</H1>
        <div className="w-full flex flex-wrap items-center justify-items-center content-center sm:justify-between">
          <div className="flex flex-col items-center justify-items-center w-full sm:w-1/3 p-[15px] mx-auto my-[10px] sm:my-[15px]">
            <H3 className="border-b-2">NOMBRE</H3>
            <Texto className="border-b-2">{user?.user?.name}</Texto>
          </div>
          <div className="flex flex-col items-center justify-items-center w-full sm:w-1/3 p-[15px] mx-auto my-[10px] sm:my-[15px]">
            <H3 className="border-b-2">APELLIDO PATERNO</H3>
            <Texto className="border-b-2">{user?.user?.fatherLastName}</Texto>
          </div>
          <div className="flex flex-col items-center justify-items-center w-full sm:w-1/3 p-[15px] mx-auto my-[10px] sm:my-[15px]">
            <H3 className="border-b-2"> APELLIDO MATERNO</H3>
            <Texto className="border-b-2">{user?.user?.motherLastName}</Texto>
          </div>
          <div className="flex flex-col items-center justify-items-center w-full sm:w-1/3 p-[15px] mx-auto my-[10px] sm:my-[15px]">
            <H3 className="border-b-2">CARNET DE IDENTIDAD</H3>
            <Texto className="border-b-2">{user?.user?.ci}</Texto>
          </div>
          <div className="flex flex-col items-center justify-items-center w-full sm:w-1/3 p-[15px] mx-auto my-[10px] sm:my-[15px]">
            <H3 className="border-b-2"> Nro DE CELULAR </H3>
            <Texto className="border-b-2">{user?.user?.cellphoneNumber}</Texto>
          </div>
          <div className="flex flex-col items-center justify-items-center w-full sm:w-1/3 p-[15px] mx-auto my-[10px] sm:my-[15px]">
            <H3 className="border-b-2"> Nro DE TELEFONO </H3>
            <Texto className="border-b-2">
              {user?.user?.phoneNumber
                ? user.user.phoneNumber
                : 'No registrado'}
            </Texto>
          </div>
          <div className="flex flex-col items-center justify-items-center w-full sm:w-1/3 p-[15px] mx-auto my-[10px] sm:my-[15px]">
            <H3 className="border-b-2"> EMAIL </H3>
            <Texto className="border-b-2">{user?.user?.email}</Texto>
          </div>
        </div>
      </div>
    </div>
  )
}

const CompanySection = ({ company }) => {
  const [valueSelect, setvalueSelect] = useState(22)
  const { data, call } = Request({
    urlApi: company.id
      ? `/clientStats/companies/${company.id}?internCode=${valueSelect}`
      : null,
  })

  useEffect(() => {
    call()
  }, [valueSelect])

  if (data === undefined) return null

  return (
    <div className="flex w-full flex-col sm:flex-row items-center border-t-2 py-[20px] sm:py-[40px]">
      <InfoEmpresa user={company} />
      <div className="w-[95%] sm:w-[40%] sm:border-l-2 px-[20px]">
        <H3 className="py-[20px]">GRÁFICA CON EL TOTAL DE PROCESOS</H3>
        <Label htmlFor="año">Filtrar por año</Label>
        <select
          value={valueSelect}
          onChange={(e) => setvalueSelect(e.target.value)}
        >
          <option value="19">2019</option>
          <option value="20">2020</option>
          <option value="21">2021</option>
          <option value="22">2022</option>
        </select>
        <GraficChart year={valueSelect} values={company} />
      </div>
    </div>
  )
}

export default Summary

const InfoEmpresa = ({ user }) => {
  const { data } = Request({
    urlApi: user.id ? `company/${user?.id}/ClientsCompany` : null,
  })

  function convertData(convertData) {
    let date = new Date(convertData)
    let fecha = ''
    if (
      date.getDate() !== 0 &&
      date.getMonth() !== 0 &&
      date.getFullYear() !== 0
    ) {
      return (fecha =
        date.getDate() + ' / ' + date.getMonth() + ' / ' + date.getFullYear())
    }
    return 'No registrado'
  }

  return (
    <div className="flex flex-col w-[95%] sm:w-[60%]">
      <H1>{user?.razonSocial}</H1>
      <div className="w-full flex flex-wrap items-center justify-items-center content-center sm:justify-between">
        <div className="flex flex-col items-center justify-items-center w-full lg:w-1/2 my-[10px] sm:my-[15px] p-[15px] mx-auto">
          <H3 className="border-b-2">RAZÓN SOCIAL</H3>
          <Texto className="border-b-2">{user?.razonSocial}</Texto>
        </div>
        <div className="flex flex-col items-center justify-items-center w-full lg:w-1/2 my-[10px] sm:my-[15px] p-[15px] mx-auto">
          <H3 className="border-b-2">NIT</H3>
          <Texto className="border-b-2">{user?.nit}</Texto>
        </div>
        <div className="flex flex-col items-center justify-items-center w-full lg:w-1/2 my-[10px] sm:my-[15px] p-[15px] mx-auto">
          <H3 className="border-b-2"> ESTADO</H3>
          <Texto className="border-b-2">{user?.status}</Texto>
        </div>
        <div className="flex flex-col items-center justify-items-center w-full lg:w-1/2 my-[10px] sm:my-[15px] p-[15px] mx-auto">
          <H3 className="border-b-2">FECHA DE CREACIÓN</H3>
          <Texto className="border-b-2">
            {convertData(user?.creationDate)}
          </Texto>
        </div>
        <div className="flex flex-col items-center justify-items-center w-full lg:w-1/2 my-[10px] sm:my-[15px] p-[15px] mx-auto">
          <H3 className="border-b-2">CLIENTES HABILITADOS</H3>
          {data?.map((client, index) => {
            return (
              <Texto key={index} className="border-b-2">
                -{client?.name} {client?.fatherLastName}{' '}
                {client?.motherLastName}
              </Texto>
            )
          })}
        </div>
      </div>
    </div>
  )
}
