import FormDefault from "../../../components/Forms/FormDefault";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { requestAuth } from "../../../components/services/RequestService";


const CreateCompany = (props) => {
  const { onClose, reload } = props;

  return (
    <div>
      <FormDefault
        tittle="Crear nueva compañía"
        fields={[
          {
            label: "Id de compañia",
            name: "Num",
            placeholder: "Introduzca id de compañia (opcional)",
            type: "string",
            typeInput: "input",
          },
          {
            label: "Número de Identificación Tributaria (NIT)",
            name: "Nit",
            placeholder: "Introduzca su numero NIT",
            type: "string",
            typeInput: "input",
          },
          {
            label: "Cedula de identidad",
            name: "Ci",
            placeholder: "Introduzca su ci",
            type: "string",
            typeInput: "input",
          },
          {
            label: "Razon social",
            name: "RazonSocial",
            placeholder: "Introdusca la Razon Social",
            type: "text",
            typeInput: "input",
          },
          {
            label: "Correo electrónico",
            name: "Email",
            placeholder: "Introdusca el correo electrónico",
            type: "text",
            typeInput: "input",
          },
          {
            label: 'Categoria',
            name: 'categoria',
            type: 'select',
            typeInput: 'select',
            options: [
              {
                label: "AA",
                value: "AA",
              },
              {
                label: "A",
                value: "A",
              },
              {
                label: "B",
                value: "B",
              },
              {
                label: "C",
                value: "C",
              },
            ]
          },
          {
            label: "Teléfono",
            name: "Phone",
            type: "text",
            typeInput: "input",
          },
          {
            label: "Dirección",
            name: "Direccion",
            type: "text",
            typeInput: "input",
          },
          {
            label: "Gps",
            name: "Gps",
            type: "text",
            typeInput: "input",
          },
        ]}
        initialValues={{
          Num: "",
          Nit: "",
          Ci: "",
          RazonSocial: "",
          Email: "",
          categoria: "",
          Phone: "",
          Direccion: "",
          Gps: "",
        }}
        validationSchema={Yup.object().shape({
          Nit: Yup.string().required("Este campo es obligatorio"),
          RazonSocial: Yup.string().required("Este campo es obligatorio"),
        })}
        onSubmit={async (values) => {
          await requestAuth("post", "company/Register", values)
            .then(() => {
              reload();
              onClose();
              toast.success("Envio exitoso");
            })
            .catch((response) => {
              toast.error(
                response.response.data
                  ? response.response.data
                  : "Se ha producido un error al enviar"
              );
            });
        }}
        buttonName="Enviar"
      />
    </div>
  );
}

export default CreateCompany;