import { useState } from "react";
import { H3, Label } from "../Text";
import { toast } from "react-toastify";
import Circle from "../Animation/Circle";
import { Button } from "../Buttons";
import { Request } from "../../hooks/Request";
import TableComplete from "../Tables/TableComplete";
import { requestAuth } from "../services/RequestService";
import { useDialog } from "../../contexts/DialogContext";
import { DialogConfirmation } from "../Modal";
import CreatableSelect from "react-select/creatable";
import { validateArray } from "../../utils/validate";

const InputSubContaAdd = ({ field }) => {
  const { openDialog, dialogClose } = useDialog();

  const { data, loading, call } = Request({
    urlApi: `/dataSet/procedure/${field.id}/field/${field.fieldId}/subdata`,
  });
  const {
    data: document,
    loading: loadDocument,
    call: callDocument,
  } = Request({
    urlApi: field.urlApi,
  });

  function handleForm(item) {
    openDialog(
      <>
        {loadDocument && (
          <div className="min-h-[380px]">
            <FormAddDocument
              data={document}
              item={item}
              reload={call}
              field={field}
              onClose={dialogClose}
              callDocument={callDocument}
            />
          </div>
        )}
      </>
    );
  }
  function handleEditReceipt(data) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres cambiar el recibo?</H3>}
        onClose={dialogClose}
        method="put"
        url={`/dataSet/procedure/subData/${data.id}`}
        data={{ ...data, recibo: data.recibo ? false : true }}
        texBtn="Cambiar"
        handleSuccess={call}
      />
    );
  }

  function handleDelete(data) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres eliminar este item?</H3>}
        onClose={dialogClose}
        method="delete"
        url={`/dataSet/procedure/subData/${data.id}`}
        texBtn="Eliminar"
        handleSuccess={call}
      />
    );
  }

  return (
    <div className="bg-gray-300/40 p-2 rounded-md">
      <Label htmlFor={field.name} className={`${field.className} my-0`}>
        {field.labelCustom ? field.labelCustom : field.label}
      </Label>
      <div className="bg-gray-100 rounded-lg p-3 pt-2">
        {loadDocument && (
          <FormAddDocument
            data={document}
            reload={call}
            field={field}
            onClose={() => {}}
            callDocument={callDocument}
          />
        )}
      </div>
      <br />
      {!loading ? (
        <Circle />
      ) : (
        <div className="w-full">
          <TableComplete
            header={[
              {
                name: "label",
                label: "Campo",
              },
              {
                name: "recibo",
                label: "R",
                type: "custom",
                builder: (item) => {
                  if (item) {
                    return <i class="fas fa-check-circle text-green-700"></i>;
                  } else {
                    return <i class="fas fa-times-circle"></i>;
                  }
                },
              },
              {
                name: "description",
                label: "Proforma",
              },
              {
                name: "description2",
                label: "Planilla",
              },
              {
                name: "observation",
                label: "Diferencia",
              },
              {
                name: "add",
                type: "action",
                label: "Opciones",
                sticky: true,
                actions: [
                  {
                    label: "Cambiar estado de recibo",
                    icon: "fas fa-pen-square",
                    action: handleEditReceipt,
                    color: "text-[#1d4ed8]",
                  },
                  {
                    label: "Añadir",
                    icon: "fas fa-trash",
                    action: handleDelete,
                    color: "text-red-500",
                  },
                  {
                    label: "Editar",
                    icon: "fas  fa-edit",
                    action: handleForm,
                    color: "text-[#1d4ed8]",
                  },
                ],
              },
            ]}
            data={data}
          />
        </div>
      )}
    </div>
  );
};

const FormAddDocument = (props) => {
  const { data, item = null, reload, field, onClose, callDocument } = props;
  const [values, setValues] = useState({
    Label: item?.label ? item.label : validateArray(data) ? data[0].name : "",
    NumberValue: item?.numberValue ? item.numberValue : 0,
    Description: item?.description ? item.description : "",
    Description2: item?.description2 ? item.description2 : "",
    isNew: false,
  });

  async function handleSubmit() {
    if (values.isNew) {
      await handleCreateOption(values);
    }
    await requestAuth(
      item ? "put" : "post",
      item
        ? `/dataSet/procedure/subData/${item.id}`
        : `/dataSet/procedure/${field.id}/field/${field.fieldId}/subdata`,
      item
        ? {
            ...values,
            observation:
              parseFloat(values.Description2 ?? 0) -
              parseFloat(values.Description ?? 0),
          }
        : {
            ...values,
            Description2: values.Description,
            observation: 0,
          }
    )
      .then(() => {
        reload();
        onClose();
        setValues({
          ...values,
          NumberValue: 0,
          Description: "",
          Description2: "",
        });
        toast.success("Registro exitoso");
      })
      .catch(() => {
        toast.error("Se produjo un error al guardar");
      });
  }

  async function handleCreateOption(value) {
    await requestAuth("post", "/Dropdown/Option", {
      Name: value.Label,
      DropdownListLabel: field.urlInitial,
    }).then(() => {
      callDocument();
    });
  }

  function handleChangeInp(e) {
    const { name, value } = e.target;
    setValues((a) => ({
      ...a,
      [name]: value,
    }));
  }
  function handleChange(e) {
    setValues((a) => ({
      ...a,
      Label: e.value,
      isNew: e.__isNew__ || false,
    }));
  }

  return (
    <form className="flex flex-col">
      <Label className={`mt-2`}>Lista de campos</Label>
      {validateArray(data) ? (
        <CreatableSelect
          isSearchable={true}
          onChange={handleChange}
          styles={{
            placeholder: (base) => ({
              ...base,
              color: "black",
            }),
          }}
          // defaultInputValue={values[field.name]}
          placeholder={"Seleccionar..."}
          isOptionSelected={false}
          // isLoading={field.urlApi ? !loading : false}
          options={data.map((option) => ({
            value: option.name,
            label: option.name,
          }))}
        />
      ) : null}
      <Label className={`my-0 mt-4`}>{item ? "Monto proforma" : "Monto"}</Label>
      <div className={`flex gap-2 ${item ? "flex-col" : ""}`}>
        <input
          value={values.Description}
          onChange={handleChangeInp}
          className="w-full p-2 rounded-md"
          type="text"
          name="Description"
        />
        {item ? (
          <>
            <Label className={`my-0 mt-1`}>Monto planilla</Label>
            <input
              value={values.Description2}
              onChange={handleChangeInp}
              className="w-full p-2 rounded-md"
              type="text"
              name="Description2"
            />
          </>
        ) : null}
        <Button
          onClick={handleSubmit}
          className={`px-3 ${item ? "mt-2 py-3" : "py-0"}`}
        >
          Guardar
        </Button>
      </div>
    </form>
  );
};

export default InputSubContaAdd;
