import { toast } from "react-toastify";
import FormDefault from "../../components/Forms/FormDefault";
import { requestAuth } from "../../components/services/RequestService";
import { useDialog } from "../../contexts/DialogContext";
import { Anchor, H3 } from "../../components/Text";

const GenerateContabilidad = () => {
  const { openDialog, dialogClose } = useDialog();   
  async function onSubmit(values) {
    await requestAuth(
      "post",
      `/files/billingStatement?initial=${values.initial}&final=${values.final}`
    )
      .then((response) => {
        console.log(response);
        toast.success("Registro exitoso");
        viewLinkExcel(response.data)
      })
      .catch(() => {
        toast.error("Se produjo un error al guardar");
      });
  }
  function viewLinkExcel(data) {
    openDialog(
      <div className="w-[300px]">
        <H3>Archivo generado</H3>
        <center>
          <Anchor
            className="text-[#264fbe] text-[1.4rem]"
            href={data.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Descargar archivo
          </Anchor>
        </center>
        <br />
      </div>
    );
  }
  

  return (
    <div>
      <FormDefault
        tittle={`Formulario para generar el informe de contabilidad`}
        fields={[
          {
            label: "Fecha de inicio",
            name: "initial",
            type: "date",
            typeInput: "input",
          },
          {
            label: "Fecha final",
            name: "final",
            type: "date",
            typeInput: "input",
          },
        ]}
        initialValues={{}}
        // validationSchema={Yup.object().shape(validate)}
        onSubmit={onSubmit}
        buttonName="Registrar"
      />
    </div>
  );
};

export default GenerateContabilidad;
