import { Bar } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { validateArray } from "../../utils/validate";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const prepareChartData = (datal, listaMes) => {
  const labels = datal.map((cliente) => cliente.option);
  const datasets = [];

  listaMes.forEach((month) => {
    const dataAux = datal.map((cliente) => {
      const cantidad = cliente.cantidadMes.find(
        (item) => item.nroMes === month.id
      );
      return cantidad?.cantidad ?? 0;
    });

    datasets.push({
      label: month.label,
      data: dataAux,
      backgroundColor: month.color,
    });
  });
  return {
    labels: labels,
    datasets: datasets,
  };
};

const ChartVertical = ({ data, listY, title, onClick, generalData }) => {
  const chartData = validateArray(data)
    ? prepareChartData(data, listY)
    : { labels: [], datasets: [] };

  const footer = (tooltipItems) => {
    let list = data.find((item) => item.option === tooltipItems[0].label);
    if (list) {
      const cantidadMes = list.cantidadMes.find(
        (item) => item.nroMes === tooltipItems[0].datasetIndex + 1
      );
      if (cantidadMes) {
        return `Porcentaje: ${cantidadMes.percentage}`;
      } else {
        return "";
      }
    }
    return "";
  };

  return (
    <Bar
      style={{ width: "80%", overflowX: "auto" }}
      options={{
        plugins: {
          title: {
            display: true,
            text: title,
          },
          tooltip: {
            callbacks: {
              footer: footer,
            },
          },
        },
        responsive: true,
        scales: {
          x: {
            label: "X-axis",
            stacked: true,
            min: 0,
            max: 50,
          },
          y: {
            stacked: true,
          },
        },
        onClick: function (_, element) {
          console.log(generalData)
          console.log(chartData.labels[element[0].index])
          if (validateArray(element)) {
            // setNumMonth(listY[element[0].datasetIndex].id)
            // generalData = { ...generalData, chart1: element[0].index }
            onClick(
              chartData.labels[element[0].index],
              // element[0].datasetIndex + 1
              listY[element[0].datasetIndex],
            );
          }
        },
      }}
      data={chartData}
    />
  );
};

export default ChartVertical;
