import { Request } from "../../hooks/Request";
import logotipo from "../../assets/logohorizontal.png";
import qr from "../../assets/qr.png";
import { Circle } from "../../components/Animation";
import View from "../../components/GeneratePdf/View";
import Text from "../../components/GeneratePdf/Text";
import { Page, Document, PDFViewer, Image } from "@react-pdf/renderer";
import * as dayjs from "dayjs";

const GenerateOtherReceipt = (props) => {
  const { procedure } = props;
  const { data, loading } = Request({
    urlApi: `/tradecruz/procedure/${
      procedure.id || procedure.procedureId
    }/otherReceipt`,
  });

  if (!loading) {
    return <Circle />;
  }

  return (
    <div>
      <PDFViewer
        style={{
          width: 900,
          height: window.innerHeight - 100,
        }}
        children={
          <Document>
            <Page size="A4" style={{ padding: "20px" }}>
              <View borderContent flexDirection="column">
                <View
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: "5px", marginLeft: "5px" }}
                >
                  <View
                    gap="0"
                    width="50%"
                    flexDirection="column"
                    alignItems="center"
                    style={{ marginTop: "5px", marginLeft: "5px" }}
                  >
                    {/* <Image src={logotipo} style={{ height: "60px" }} /> */}
                    <Text padding="1px 3px">
                      4to. Anillo entre Av. Banzer y Beni Calle Tristan
                      Languidey Nro. 19
                    </Text>
                    <Text padding="1px 3px">
                      info@tradecruz.com Telf.:(591-3)3406135
                    </Text>
                    <Text padding="1px 3px">Santa Cruz - Bolivia</Text>
                  </View>
                  <View width="40%" flexDirection="column" alignItems="center">
                    <View
                      width="100%"
                      justifyContent="center"
                      style={{ backgroundColor: "#e3be2b" }}
                    >
                      <Text fontSize="15px" bold textAlign="end">
                        RECIBO OFICIAL
                      </Text>
                    </View>
                    <Text fontSize="10px">ORIGINAL</Text>
                    <View
                      width="100%"
                      justifyContent="center"
                      style={{ backgroundColor: "#eaf2f4" }}
                    >
                      <Text fontSize="15px" bold textAlign="end">
                        Nº: {data?.nroDeRecibo ?? ""}
                      </Text>
                    </View>
                  </View>
                </View>
                <View
                  border={{ top: true }}
                  style={{ margin: "5px 5px" }}
                ></View>
                <View flexDirection="column" style={{ margin: "10px 25px" }}>
                  <View justifyContent="space-between" alignItems="center">
                    <View width="40%">
                      <Text fontSize="11px">
                        Fecha: {dayjs().format("DD/MM/YYYY")}
                      </Text>
                    </View>
                    <View width="40%">
                      <Text fontSize="14px" bold>
                        Bs.: {data?.sumaTotal ?? ""}
                      </Text>
                    </View>
                  </View>
                  <View>
                    <View width="18%">
                      <Text fontSize="11px">Por concepto de:</Text>
                    </View>
                    <View width="82%">
                      <Text fontSize="11px">
                        {data?.detalleConceptos ?? ""}
                      </Text>
                    </View>
                  </View>
                  <View>
                    <View width="18%"></View>
                    <View width="82%">
                      <Text fontSize="11px">
                        {data?.subDetalleConceptos ?? " "}
                      </Text>
                    </View>
                  </View>
                  <View>
                    <View width="18%">
                      <Text fontSize="11px">La suma de:</Text>
                    </View>
                    <View width="82%">
                      <Text fontSize="11px">
                        {data?.sumaTotalLiteral ?? ""}
                      </Text>
                    </View>
                  </View>
                  <View>
                    <View width="18%">
                      <Text fontSize="11px">Tipo de pago:</Text>
                    </View>
                    <View width="82%">
                      <Text fontSize="11px">.............</Text>
                    </View>
                  </View>
                  <View>
                    <View width="18%">
                      <Text fontSize="11px">Nº Documento:</Text>
                    </View>
                    <View width="82%">
                      <Text fontSize="11px">{data?.nroDocumentos ?? ""}</Text>
                    </View>
                  </View>
                  <View>
                    <View width="18%">
                      <Text fontSize="11px">T.C.:</Text>
                    </View>
                    <View width="82%">
                      <Text fontSize="11px">9.96</Text>
                    </View>
                  </View>
                  <View>
                    <View width="60%"></View>
                    <View
                      width="40%"
                      alignItems="center"
                      flexDirection="column"
                      justifyContent="space-between"
                    >
                      <Image
                        src={qr}
                        style={{ height: "80px", margin: "5px 5px" }}
                      />
                      <View
                        width="100%"
                        border={{ top: true }}
                        style={{ margin: "5px 5px" }}
                      ></View>
                      <Text fontSize="11px" padding="1px 3px">
                        Entregue conforme
                      </Text>
                      <Text fontSize="11px" padding="1px 3px">
                        Tradecruz S.R.L.
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </Page>
          </Document>
        }
      />
    </div>
  );
};

export default GenerateOtherReceipt;
