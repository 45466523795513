import * as Yup from "yup";
import { useState } from "react";
import { toast } from "react-toastify";
import { Request } from "../../hooks/Request";
import { Button } from "../../components/Buttons";
import { Circle } from "../../components/Animation";
import { validateArray } from "../../utils/validate";
import { H2, H3, Texto } from "../../components/Text";
import { fileFormInput } from "../../utils/fieldForm";
import SubModal from "../../components/Modal/SubModal";
import FormDefault from "../../components/Forms/FormDefault";
import CallServiceTable from "../../components/Tables/CallServiceTable";
import { requestAuth } from "../../components/services/RequestService";
import DragList from "../../components/Drags/DragList";
import { useDialog } from "../../contexts/DialogContext";
import { DialogConfirmation } from "../../components/Modal";

const ListFieldsMail = (props) => {
  const { step } = props;
  const [modalView, setModalView] = useState({
    status: false,
    type: "",
    field: null,
  });
  const { openDialog, dialogClose } = useDialog();

  function handleAllFields(data) {
    openDialog(
      <DialogConfirmation
        children={
          <H3>
            ¿Estas seguro que desea adicionar los campos correspondientes al
            paso?
          </H3>
        }
        onClose={dialogClose}
        method="post"
        url={`/processStep/mailParam/${data.id}/fields`}
        texBtn="Adicionar"
        handleSuccess={call}
      />
    );
  }

  const { data, loading, call } = Request({
    urlApi: `/processstep/${step.id}/mailParam`,
  });

  const typeText = {
    text: "Texto",
    number: "Número",
    date: "Fecha",
    selectGet: "Lista desplegable",
    file: "Archivo",
    fileStatus: "Archivo con estado",
    requestSerialNumber: "Solicitar número correlativo",
    controlField: "Campo de control",
    IncidentField: "Campo de incidencia",
    fileRead: "Archivo para la lectura",
    fileExcel: "Generar excel",
    checkbox: "Casilla de verificación",
    textArea: "Caja de texto multi-línea",
    subData: "Campo con valor multiple",
  };

  function handleEditField(type, field) {
    setModalView({
      status: true,
      type: type,
      field: field,
    });
  }

  if (!loading) {
    return <Circle />;
  }
  return (
    <div>
      <div className="flex justify-between items-center mb-2">
        <H2 className="mb-2">{data.name}</H2>
        <div className="flex gap-2">
          {/* <Button
            className="py-1.5 px-2 text-white"
            content="Crear nuevo campo"
            onClick={() => handleEditField('create', null)}
          >
            <i className="fas fa-plus"></i>
          </Button> */}
          {data.mailParamId && (
            <>
              <Button
                className="py-1 px-2 text-white"
                content="Añadir campos actuales del paso"
                onClick={() => handleAllFields(data)}
              >
                <i className="fas fa-rectangle-list"></i>
                {/* <i class="fa-regular fa-rectangle-list"></i> */}
              </Button>
              <Button
                className="py-1 px-2 text-white"
                content="Añadir un campo ya existente"
                onClick={() => handleEditField("select", null)}
              >
                <i className="fas fa-stream"></i>
              </Button>
            </>
          )}
          {/* <Button
            className="py-1 px-2 text-white"
            content="Ordenar los campos"
            onClick={() => handleEditField('order', null)}
          >
            <i className="fas fa-sort-numeric-up"></i>
          </Button> */}
        </div>
      </div>
      <div>
        <FormDefault
          tittle="Editar parámetros del correo electrónico"
          fields={[
            {
              label: "Titulo",
              name: "Title",
            },
            {
              label: "Contenido",
              name: "Content",
            },
            {
              label: "Asunto",
              name: "Subject",
              validate: true,
            },
            {
              label: "Pie de firma",
              name: "Footer",
            },
            {
              label: "Habilitar envío de correo",
              name: "Send",
              type: "checkbox",
            },
            {
              label: "Incluir campos llenados",
              name: "IncludeFilledFields",
              type: "checkbox",
            },
            {
              label: "Incluir campos faltantes",
              name: "IncludeUnfilledFields",
              type: "checkbox",
            },
          ]}
          initialValues={{
            id: data?.id,
            // ProcedureTypeId: 1,
            Step: data?.step,
            Name: data?.name,
            Title: data?.title || "",
            Content: data?.content || "",
            Subject: data?.subject || "",
            Footer: data?.footer || "",
            Send: data?.send || false,
            IncludeFilledFields: data?.includeFilledFields || false,
            IncludeUnfilledFields: data?.includeUnfilledFields || false,
            // CompanyId: dataCampo?.companyId + '',
          }}
          validationSchema={Yup.object().shape({
            // id: Yup.string().required('Este campo es obligatorio'),
            // Title: Yup.string().required('Este campo es obligatorio'),
            // Content: Yup.string().required('Este campo es obligatorio'),
            Subject: Yup.string().required("Este campo es obligatorio"),
          })}
          onSubmit={(values, resetForm) => {
            requestAuth("put", "/processStep/mailParam", values)
              .then(() => {
                // setFormCountryside(!formCountryside)
                // setReload(!reload)
                toast.success("Envio exitoso");
                call();
                resetForm();
              })
              .catch(() => {
                toast.error("Error en el envio");
              });
          }}
          buttonName="Editar"
        />
      </div>
      <br />
      {validateArray(data.fieldList) ? (
        data.fieldList.map((field) => (
          <div
            className="bg-slate-300 mb-[10px] p-[10px] sm:p-[20px] text-center flex items-center"
            key={field.id}
          >
            <div className="w-1/2">
              <Texto className="font-semibold">{field?.label}</Texto>
              <Texto>
                {field?.validate ? "Campo requerido" : "No requerido"}
              </Texto>
              <Texto>{typeText[field?.type]}</Texto>
            </div>
            <div className="flex items-center justify-center gap-2 w-1/2">
              {/* <Button
                onClick={() => handleEditField('edit', field)}
                content="Editar campo"
                className="py-2 text-white"
              >
                <i className="fas fa-edit"></i>
              </Button> */}
              <Button
                content="Eliminar campo"
                onClick={() => {
                  requestAuth(
                    "delete",
                    `field/${field?.id}/processstepid/${data?.id}/mailParam`
                  )
                    .then(() => {
                      call();
                      toast.success("Campo eliminado");
                    })
                    .catch(() => {
                      toast.error("Se produjo un error al enviar");
                    });
                }}
                className="py-2 text-white"
              >
                <i className="fas fa-trash-alt"></i>
              </Button>
            </div>
          </div>
        ))
      ) : (
        <H3>No se tiene campos para mostrar en el correo</H3>
      )}
      <SubModal
        onClose={() => setModalView({ status: false, type: "", field: null })}
        activateForm={modalView.status}
      >
        {modalView.type === "edit" ? (
          <FormEditField
            field={modalView.field}
            stepId={step.id}
            call={call}
            onClose={() =>
              setModalView({ status: false, type: "", field: null })
            }
          />
        ) : modalView.type === "create" ? (
          <FormCreateField
            call={call}
            stepId={step.id}
            onClose={() =>
              setModalView({ status: false, type: "", field: null })
            }
          />
        ) : modalView.type === "select" ? (
          <FormSelectField
            call={call}
            stepId={step.id}
            onClose={() =>
              setModalView({ status: false, type: "", field: null })
            }
          />
        ) : modalView.type === "order" ? (
          <FormOrderField
            fields={data.fields}
            orderFieldsActiveorderFieldsActive
            call={call}
            onClose={() =>
              setModalView({ status: false, type: "", field: null })
            }
          />
        ) : null}
      </SubModal>
    </div>
  );
};

const FormOrderField = (props) => {
  const { fields, call, onClose } = props;

  return (
    <DragList
      tittle="Ordenar campos"
      orderFieldsActive={true}
      data={fields}
      onSaveChange={(fields) => {
        const newFields = fields?.map((field, index) => ({
          Id: field.fieldProcessStepId,
          order: index + 1,
        }));
        requestAuth("put", "field/order", newFields)
          .then(() => {
            call();
            onClose();
            toast.success("Cambio de orden exitoso");
          })
          .catch(() => {
            toast.error("Se produjo un error al realizar los cambios");
          });
      }}
    />
  );
};

const FormSelectField = (props) => {
  const { stepId, call, onClose } = props;
  const [loading, setLoading] = useState(false);

  function handleSelect(values) {
    setLoading(true);
    requestAuth(
      "post",
      `/field/fieldId/${values?.id}/processStepId/${stepId}/mailParam`
    )
      .then(() => {
        call();
        setLoading(true);
        onClose();
        toast.success("Campo adicionado");
      })
      .catch((error) => {
        setLoading(true);
        toast.error(
          error.response.data ? error.response.data : "No se pudo adicionar"
        );
      });
  }

  if (loading) {
    return <Circle />;
  }
  return (
    <div>
      <H2>LISTA PARA ADICIONAR CAMPOS EXISTENTES</H2>
      <CallServiceTable
        urlApi="/field"
        header={[
          {
            name: "label",
            label: "Nombre del campo",
            filter: true,
          },
          //   {
          //     name: 'type',
          //     label: 'Tipo de campo',
          //     filter: false,
          //     type: 'translate',
          //     translate: {
          //       text: 'Texto',
          //       file: 'Archivo',
          //       fileRead: 'Archivo',
          //       number: 'Numero',
          //       selectGet: 'Lista desplegable',
          //       checkbox: 'Casilla de verificación',
          //       textArea: 'Caja de texto multi-línea',
          //       date: 'Fecha',
          //     },
          //   },
          {
            name: "edit",
            type: "action",
            label: "Añadir",
            actions: [
              {
                label: "Añadir campo",
                icon: "fas fa-circle-plus",
                action: handleSelect,
                color: "text-[#1d4ed8]",
              },
            ],
          },
        ]}
        statusPaination={true}
        showTable={false}
      />
    </div>
  );
};

const FormCreateField = (props) => {
  const { stepId, call, onClose } = props;
  return (
    <FormDefault
      tittle="Crear un nuevo campo"
      fields={fileFormInput}
      initialValues={{
        Label: "",
        ProcessStepIdList: "",
        Type: "text",
        Url: "",
        Validate: false,
        TableDisplay: false,
        ClientDisplay: false,
        Unique: false,
        AutoGenerated: true,
      }}
      validationSchema={Yup.object().shape({
        Label: Yup.string().required("Este campo es obligatorio"),
        Type: Yup.string().required("Este campo es obligatorio"),
        Url: Yup.string()
          .when("Type", {
            is: "fileRead",
            then: Yup.string().required("Campo requerido."),
          })
          .when("Type", {
            is: "selectGet",
            then: Yup.string().required("Campo requerido."),
          })
          .when("Type", {
            is: "fileExcel",
            then: Yup.string().required("Campo requerido."),
          })
          .when("Type", {
            is: "subItemsConta",
            then: Yup.string().required("Campo requerido."),
          })
          .when("Type", {
            is: "subData",
            then: Yup.string().required("Campo requerido."),
          })
          .when("Type", {
            is: "documentsData",
            then: Yup.string().required("Campo requerido."),
          })
          .when("Type", {
            is: "documentsDataAdd",
            then: Yup.string().required("Campo requerido."),
          }),
      })}
      onSubmit={async (values, resetForm) => {
        await requestAuth("post", "field", {
          ...values,
          ProcessStepIdList: [stepId],
        })
          .then(() => {
            call();
            onClose();
            toast.success("Nuevo campo creado");
          })
          .catch(() => {
            toast.error("El campo ya existe");
          });
      }}
      buttonName="Enviar"
    />
  );
};

const FormEditField = ({ field, stepId, call, onClose }) => {
  return (
    <FormDefault
      tittle="Editar datos del campo"
      fields={fileFormInput}
      initialValues={{
        id: field?.id || "",
        Initial: field?.initial || "",
        Label: field?.label || "",
        Name: field?.name || "",
        Type: field?.type || "text",
        Url: field?.url || "",
        Color: field?.color || "",
        Validate: field?.validate || false,
        TableDisplay: field?.tableDisplay,
        Unique: field?.unique || false,
        AutoGenerated: field?.autoGenerated || false,
        // ClientDisplay: field?.clientDisplay || false,
        // AllowModification: field?.allowModification || false,
        Format: field?.format || "",
        lockEdition: field?.lockEdition || false,
      }}
      validationSchema={Yup.object().shape({
        Label: Yup.string().required("Este campo es obligatorio"),
        Type: Yup.string().required("Este campo es obligatorio"),
        Url: Yup.string()
          .when("Type", {
            is: "fileRead",
            then: Yup.string().required("Campo requerido."),
          })
          .when("Type", {
            is: "selectGet",
            then: Yup.string().required("Campo requerido."),
          })
          .when("Type", {
            is: "fileExcel",
            then: Yup.string().required("Campo requerido."),
          })
          .when("Type", {
            is: "subItemsConta",
            then: Yup.string().required("Campo requerido."),
          })
          .when("Type", {
            is: "subData",
            then: Yup.string().required("Campo requerido."),
          }),
      })}
      onSubmit={async (values, resetForm) => {
        await requestAuth(
          "put",
          `field/${values.id}/processStepId/${stepId}`,
          values
        )
          .then(() => {
            toast.success("Campo editado correctamente!");
            call();
            onClose();
          })
          .catch(() => {
            toast.error("Se ha producido un error al enviar");
          });
      }}
      buttonName="Enviar"
    />
  );
};

export default ListFieldsMail;
