import * as Yup from "yup";
import { useDialog } from "../../contexts/DialogContext";
import FormDefault from "../../components/Forms/FormDefault";
import * as dayjs from "dayjs";
import GenerateTransportReceipt from "./GenerateTransportReceipt";
import logoRT1 from "../../assets/logoTransporte.png"
import logoRT2 from "../../assets/truck2.png"
import logoRT3 from "../../assets/truck3.png"
import logoRT4 from "../../assets/truck4.png"
import logoR1 from "../../assets/logohorizontal.png";
import logoR2 from "../../assets/receipt2.png"
import GenerateReceipt from "./GenerateReceipt";

dayjs.locale("es");

const RenderSelectLogo = (props) => {
  const { openDialog } = useDialog();
  const { typeReceipt } = props

  function handleReceiptTransport(values) {
    openDialog(
      <GenerateTransportReceipt {...props} logo={values.logo} />
    );
  }

  function handleReceipt(values) {
    openDialog(
      <GenerateReceipt {...props} logo={values.logo} />
    );
  }

  return (
    typeReceipt === 'transporte' ?
      <FormDefault
        tittle="Seleccionar Logo"
        fields={[
          {
            label: "Logo",
            name: "logo",
            typeInput: "selectImage",
            options: [
              {
                route: logoRT1,
                name: 'Transporte Pepe'
              },
              {
                route: logoRT2,
                name: 'Turbo Cargo'
              },
              {
                route: logoRT3,
                name: 'Ruta Express'
              },
              {
                route: logoRT4,
                name: 'Transporte Estrella'
              },
            ],
          },
        ]}
        initialValues={{
          logo: { route: logoRT1, name: 'Transporte Pepe' },
        }}
        validationSchema={Yup.object().shape({
        })}
        onSubmit={handleReceiptTransport}
        buttonName="Generar recibo"
      />
      :
      <FormDefault
        tittle="Seleccionar Logo"
        fields={[
          {
            label: "Logo",
            name: "logo",
            typeInput: "selectImage",
            options: [
              {
                route: logoR1,
                name: 'Tradecruz S.R.L.'
              },
              {
                route: logoR2,
                name: 'Monroy S.R.L.'
              },
            ],
          },
        ]}
        initialValues={{
          logo: { route: logoR1, name: 'Tradecruz S.R.L.' },
        }}
        validationSchema={Yup.object().shape({
        })}
        onSubmit={handleReceipt}
        buttonName="Generar recibo"
      />

  );
};

export default RenderSelectLogo;
