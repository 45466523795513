import * as Yup from "yup";
import { toast } from "react-toastify";
import { H1 } from "../../components/Text";
import { useParams } from "react-router-dom";
import { Request } from "../../hooks/Request";
import Circle from "../../components/Animation/Circle";
import { useModal } from "../../contexts/ModalContext";
import FormDefault from "../../components/Forms/FormDefault";
import { requestAuth } from "../../components/services/RequestService";
import CallServiceTable from "../../components/Tables/CallServiceTable";
import {
  headerList,
  filtersDefault,
  addFiltersDefault,
} from "../../utils/constant";

const CompleteDocuments = () => {
  const { rolId } = useParams();
  const { openModal, onClose } = useModal();

  function handleProcedure(procedure, reload) {
    openModal(<FormFileUpload procedure={procedure} reload={reload} />);
  }
  return (
    <CallServiceTable
      urlApi="/procedure/openprocedures"
      addFilters={addFiltersDefault}
      filters={[
        {
          name: "procedureType",
          url: "/procedureType",
          default: "Todos los tramites",
          valueOption: "id",
          labelOption: "name",
        },
        {
          name: "Year",
          default: "",
          label: "Fecha de inicio",
          options: [
            {
              label: "2024",
              name: "2024",
              initial: true,
            },
            {
              label: "2023",
              name: "2023",
            },
            {
              label: "2022",
              name: "2022",
            },
            {
              label: "2021",
              name: "2021",
            },
          ],
        },
        ...filtersDefault,
      ]}
      header={headerList}
      addUrl={`status=pending&rolId=${rolId}`}
      onClick={handleProcedure}
      statusPaination={true}
    />
  );
};

const FormFileUpload = (props) => {
  const { procedure } = props;
  const { data, call, loading } = Request({
    urlApi: `/field/uploadedfileFields/${procedure.id}`,
  });

  async function onSubmit(value) {
    let formData = new FormData();
    formData.append("Name", value.FieldId);
    formData.append("File", value.File);
    formData.append("originalName", value.File.name);
    formData.append("Status", "verified");
    formData.append("ProcedureId", procedure.id);
    const fileContent = await requestAuth("post", "/files", formData)
      .then((res) => {
        return {
          Value: res.data.url,
          ProcedureId: procedure.id,
          FieldId: parseInt(value.FieldId),
          FileId: res.data.id,
        };
      })
      .catch((error) => {
        toast.error(
          error.response.data
            ? error.response.data
            : "Se produjo un error al subir el archivo"
        );
      });
    submitDataset(fileContent);
  }

  async function submitDataset(value) {
    await requestAuth("post", "/dataSet/NewDataSet", [value])
      .then(() => {
        call();
        toast.success("El archivo se subió exitosamente");
      })
      .catch(() => toast.error("No se pudo subir el archivo"));
  }

  return (
    <div>
      <H1>Subir documento</H1>
      <FormDefault
        fields={[
          {
            label: "Adjuntar un archivo",
            name: "File",
            id: procedure.id,
            typeInput: "completeDocuments",
          },
        ]}
        initialValues={{
          FieldId: "",
          File: null,
        }}
        validationSchema={Yup.object().shape({
          FieldId: Yup.string().required("Este campo es obligatorio"),
          File: Yup.mixed().required("Campo requerido.").nullable(),
        })}
        onSubmit={onSubmit}
        buttonName="Guardar"
      />
    </div>
  );
};
export default CompleteDocuments;
