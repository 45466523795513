import Recurso_3logo from "../../assets/logo45-5.png";
import FormDefault from "../../components/Forms/FormDefault";
import * as Yup from "yup";
import { request } from "../../components/services/RequestService";
import { useAuth } from "../../contexts/AuthContext";
import { validateArray } from "../../utils/validate";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { H1, H3 } from "../../components/Text";
import { RequestNoAuth } from "../../hooks/RequestNoAuth";
import React from "react";
import styled from "styled-components";

const FormLogin = (props) => {
  const {
    data,
    title,
    fields,
    validationSchema,
    onSubmit,
    buttonName,
    redirect,
  } = props;

  return (
    <div
      style={{
        backgroundImage: `url("${data.backgroundImage}")`,
      }}
      className="bg-cover bg-top flex justify-center items-center w-screen h-screen"
    >
      <FormStyle data={data}>
        <div className="bg-[#ffffffa1] w-full p-8 mx-4 rounded-2xl flex flex-col justify-center items-center sm:w-[400px]">
          <img
            className="w-[70px] sm:w-[120px]"
            src={data.logo}
            alt="logo de trazo"
          />
          <H1 className="text-[25px]">{title}</H1>
          <div className="w-full">
            <FormDefault
              fields={fields}
              initialValues={{
                UsernameOrEmail: "",
                password: "",
              }}
              validationSchema={Yup.object().shape(validationSchema)}
              onSubmit={onSubmit}
              classNameBtn={`text-white`}
              buttonName={buttonName}
            />
          </div>
          <H3 className="text-gray-700 mt-2">
            {redirect ? (
              redirect
            ) : (
              <Link to="/auth/recover">
                ¿Olvidaste tu <span>contraseña</span>?
              </Link>
            )}
          </H3>
        </div>
      </FormStyle>
      <img
        className="w-[70px] hidden absolute left-0 bottom-0 sm:w-[120px] m-6 sm:block"
        src={Recurso_3logo}
        alt="logo de trazo"
      />
    </div>
  );
};

const FormStyle = styled.div`
  & h1 {
    color: ${(props) => `#${props.data.color1}`};
  }
  & label {
    color: ${(props) => `#${props.data.color2}`};
  }
  & button {
    background: ${(props) => `#${props.data.color_buttons}`};
  }
  & span {
    color: ${(props) => `#${props.data.color1}`};
  }
`;
export default FormLogin;
