import * as dayjs from "dayjs";
import { useState, useEffect } from "react";
import { Request } from "../../hooks/Request";
import { validateArray } from "../../utils/validate";
import { Button } from "../Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"; // <-- import styles to be used

import { DayPicker } from "react-day-picker";
import "react-day-picker/src/style.css";
import es from "date-fns/locale/es";
import Select from "react-select";

const Search = (props) => {
  const {
    setSelected,
    setSearch,
    search,
    header,
    filters,
    params,
    setParams,
    addFilters = [],
    reload,
    setPageNumber,
  } = props;

  function onKeyPressed(e) {
    if (e.key === "Enter") {
      reload();
    } else if (e.key === "Escape") {
      setSearch("");
    }
  }

  return (
    <>
      <div className="flex flex-wrap text-sm items-center gap-2 portrait:flex-col portrait:w-full portrait:gap-2">
        {validateArray(
          [...header, ...addFilters].filter((item) => item.filter)
        ) ? (
          <>
            <div
              onKeyDown={onKeyPressed}
              className="flex gap-1 min-w-[200px] shadow-md py-1 pr-1 pl-3 rounded-md items-center bg-[#f8fafc] portrait:w-[100%]"
            >
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                className="text-sm text-[#94a3b8]"
              />
              <input
                type="text"
                placeholder="Buscar por"
                className="outline-[0px] w-[20%] sm:h-8 flex-auto bg-[#f8fafc] text-base focus:outline-none"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              <select
                name="SearchSelect"
                className="bg-[#f97316] h-8 rounded-lg text-[#e2e8f0] hover:text-black w-[40%] selected"
                onChange={(e) => {
                  setSelected(e.target.value);
                }}
                defaultValue
              >
                {addFilters && addFilters.length > 0
                  ? [...addFilters, ...header].map(
                      (value, key) =>
                        value.filter && (
                          <option key={key} value={value.name}>
                            {value.label}
                          </option>
                        )
                    )
                  : [...header].map(
                      (value, key) =>
                        value.filter && (
                          <option key={key} value={value.name}>
                            {value.label}
                          </option>
                        )
                    )}
              </select>
              <Button
                type="button"
                onClick={() => setSearch("")}
                className="bg-[#f97316] rounded-full py-1 text-base text-white px-2"
              >
                <i className="fas fa-times"></i>
              </Button>
            </div>
            <button
              type="button"
              className="flex-none border-solid border-1 text-base bg-gradient-to-r from-emerald-500 to-emerald-600 shadow-lg rounded-lg py-2 px-6 mr-4 hover:text-white  portrait:w-[100%] portrait:mb-2 portrait:mr-0"
              onClick={() => {
                setPageNumber(1);
                reload();
              }}
            >
              Buscar
            </button>
          </>
        ) : null}
        {filters && filters.length > 0
          ? filters.map((filter, index) =>
              filter.type === "rangeDate" ? (
                <RangeDate
                  key={index}
                  filter={filter}
                  params={params}
                  setParams={setParams}
                />
              ) : (
                <Filerts
                  key={index}
                  filter={filter}
                  params={params}
                  setParams={setParams}
                />
              )
            )
          : null}
      </div>
    </>
  );
};
const RangeDate = ({ params, setParams }) => {
  const [view, setView] = useState(false);
  const [selectedDay, setSelectedDay] = useState();

  useEffect(() => {
    setParams((a) => ({
      ...a,
      initial:
        selectedDay !== undefined && selectedDay
          ? selectedDay.from !== undefined && selectedDay.from
            ? dayjs(selectedDay.from).format("YYYY-MM-DDTHH:mm:ss")
            : ""
          : "",
      final:
        selectedDay !== undefined && selectedDay
          ? selectedDay.to !== undefined && selectedDay.to
            ? dayjs(selectedDay.to).format("YYYY-MM-DDTHH:mm:ss")
            : ""
          : "",
    }));
  }, [selectedDay]);

  return (
    <div className="static">
      <div
        style={{
          background: params.initial ? "#f97316" : "#94a3b842",
          color: params.initial ? "#e2e8f0" : "gray",
          border: params.initial ? "1px solid #f97316" : "1px solid #94a3b842",
        }}
        className={`min-w-[330px] h-10 rounded-lg hover:text-black selected portrait:w-[100%] portrait:m-0`}
        onClick={() => setView(!view)}
      >
        <div className="pt-3">
          {selectedDay === undefined && !selectedDay ? (
            "Elija una fecha"
          ) : (
            <>
              {selectedDay.from
                ? dayjs(selectedDay.from).format("DD/MM/YYYY")
                : ""}
              {" - "}
              {selectedDay.to ? dayjs(selectedDay.to).format("DD/MM/YYYY") : ""}
            </>
          )}
        </div>
      </div>
      {view && (
        <>
          <div
            onClick={() => setView(!view)}
            className="absolute z-10 w-screen h-screen top-0 left-0"
          ></div>
          <div className="absolute z-10 bg-[#fff] shadow-lg w-fit mt-2 rounded-md">
            <DayPicker
              locale={es}
              mode="range"
              selected={selectedDay}
              onSelect={setSelectedDay}
            />
          </div>
        </>
      )}
    </div>
  );
};

const Filerts = ({ filter, params, setParams }) => {
  const { data, loading, error } = Request({
    urlApi: filter.url ? `${filter.url}` : null,
  });

  function handleOnChange(e) {
    setParams((a) => ({ ...a, [filter.name]: e.value }));
  }

  function handleOption() {
    if (filter.url) {
      if (validateArray(data)) {
        return data.map((option) => ({
          value: option[filter.valueOption ?? "name"],
          label: option[filter.labelOption ?? "label"],
        }));
      }
      return [];
    } else {
      return filter.options.map((option) => ({
        value: option[filter.valueOption ?? "name"],
        label: option[filter.labelOption ?? "label"],
      }));
    }
  }

  return error && filter.url
    ? null
    : loading && (
        <div className="min-w-[150px]">
          <Select
            defaultValue={
              params[filter.name]
                ? {
                    value: params[filter.name],
                    label: filter.defaultValue ?? params[filter.name],
                  }
                : undefined
            }
            isSearchable={true}
            onChange={handleOnChange}
            styles={{
              placeholder: (base) => ({
                ...base,
                color:
                  !params[filter.name] &&
                  (params[filter.name] === filter.defaultValue ||
                    params[filter.name] === "")
                    ? "gray"
                    : "white",
              }),
              singleValue: (base) => ({
                ...base,
                color:
                  !params[filter.name] &&
                  (params[filter.name] === filter.defaultValue ||
                    params[filter.name] === "")
                    ? "gray"
                    : "white",
                // color: "white",
                // color: "gray",
              }),
              control: (base) => ({
                ...base,
                background:
                  !params[filter.name] &&
                  (params[filter.name] === filter.defaultValue ||
                    params[filter.name] === "")
                    ? "#94a3b842"
                    : "#f97316",
                color:
                  !params[filter.name] &&
                  (params[filter.name] === filter.defaultValue ||
                    params[filter.name] === "")
                    ? "gray"
                    : "white",
                border:
                  !params[filter.name] &&
                  (params[filter.name] === filter.defaultValue ||
                    params[filter.name] === "")
                    ? "1px solid #94a3b842"
                    : "1px solid #f97316",
                borderRadius: "10px",
              }),
            }}
            placeholder={filter.label ?? "Seleccionar..."}
            isOptionSelected={false}
            isLoading={filter.url ? !loading : false}
            options={[
              {
                label: filter.default
                  ? filter.default
                  : filter.label
                  ? filter.label
                  : "Todos",
                value: filter.defaultValue ?? "",
              },
              ...handleOption(),
            ]}
          />
          {/* <select
            name="SearchSelect"
            className="bg-[#f97316] h-10 rounded-lg text-[#e2e8f0] hover:text-black selected portrait:w-[100%]"
            onChange={handleOnChange}
            defaultValue
          >
            <option value="">
              {filter.default ? filter.default : 'Seleccionar...'}
            </option>
            {filter.url
              ? data.map((value, key) => (
                  <option
                    key={key}
                    value={
                      value[filter.valueOption ? filter.valueOption : 'name']
                    }
                  >
                    {value[filter.labelOption ? filter.labelOption : 'label']}
                  </option>
                ))
              : filter.options.map((value, key) => (
                  <option
                    key={key}
                    value={
                      value[filter.valueOption ? filter.valueOption : 'name']
                    }
                  >
                    {value[filter.labelOption ? filter.labelOption : 'label']}
                  </option>
                ))}
          </select> */}
        </div>
      );
};
export default Search;
