import { useEffect } from "react";
import * as Yup from "yup";
import { Request } from "../../hooks/Request";
import logotipo from "../../assets/LogotipoTC.png";
import { Circle } from "../../components/Animation";
import View from "../../components/GeneratePdf/View";
import { toast } from "react-toastify";
import { requestAuth } from "../../components/services/RequestService";
import Text from "../../components/GeneratePdf/Text";
import { useAuth } from "../../contexts/AuthContext";
import { useDialog } from "../../contexts/DialogContext";
import FormDefault from "../../components/Forms/FormDefault";
import { Page, Document, PDFViewer, Image, pdf } from "@react-pdf/renderer";
import * as dayjs from "dayjs";
import { es } from "dayjs/locale/es";
dayjs.locale("es");

const RenderLiquidaciónProforma = (props) => {
  const { procedure } = props;
  const { openDialog, dialogClose } = useDialog();

  const { data, loading } = Request({
    urlApi: "/dropdown/user/accountNumbers",
  });

  function handleProform(values) {
    openDialog(
      <LiquidaciónProform {...props} accountNumber={values.account} />
    );
  }

  function handleAccount() {
    const list = data.map((item) =>
      item.accountNumber
        ? {
            label: `${item.accountNumber} - ${item.name} ${item.fatherLastName}`,
            value: item.accountNumber,
          }
        : null
    );
    return list.filter((n) => n);
  }

  if (!loading) {
    return <Circle />;
  }
  return (
    <FormDefault
      tittle="Elaborador por/ Nro de Cuenta Bancaria a Depositar"
      fields={[
        {
          label: "Atención",
          name: "account",
          typeInput: "select",
          options: handleAccount(),
          //   [
          //   {
          //     label: "Original",
          //     value: "Original",
          //   },
          //   {
          //     label: "Copia",
          //     value: "Copia",
          //   },
          //   {
          //     label: "Fotocopia simple",
          //     value: "Fotocopia simple",
          //   },
          //   {
          //     label: "Fotocopia legalizada",
          //     value: "Fotocopia legalizada",
          //   },
          // ],
          // typeInput: "selectNewOption",
          // urlApi: "/dropdown/user/accountNumbers",
          // value: "name",
          // labelOption: "name",
        },
      ]}
      initialValues={{
        account: "",
      }}
      validationSchema={Yup.object().shape({
        // atn: Yup.string().required("Campo requerido"),
      })}
      onSubmit={handleProform}
      buttonName="Registrar"
    />
  );
};

const LiquidaciónProform = (props) => {
  const { procedure, accountNumber } = props;
  const { user } = useAuth();
  const { data, loading } = Request({
    urlApi: `/tradecruz/${procedure.id || procedure.procedureId}/proform`,
  });

  useEffect(() => {
    if (loading) {
      handleOnClick();
    }
  }, [loading]);

  if (!loading) {
    return <Circle />;
  }

  const MyDoc = (
    <Document>
      <Page size="A4" style={{ padding: "20px" }}>
        <View flexDirection="column" borderContent>
          <View padding="8px" style={{ backgroundColor: "#d38c19" }}></View>
          <View flexDirection="column">
            <View justifyContent="space-between" alignItems="center">
              <View width="25%">
                {/* <Image src={logotipo} style={{ height: "50px" }} /> */}
              </View>
              <View
                width="60%"
                flexDirection="column"
                alignItems="flex-end"
                justifyContent="flex-end"
              >
                <Text color="#004080" textAlign="end" fontSize="16px" bold>
                  PROFORMA DE DESPACHO
                </Text>
                <Text color="#d38c19" fontSize="18px" bold textAlign="end">
                  Nro. {procedure.numberString}
                </Text>
                <Text fontSize="10px" textAlign="end">
                  Santa Cruz {dayjs().format("DD")} de {dayjs().format("MMMM")}{" "}
                  del {dayjs().format("YYYY")}
                </Text>
              </View>
            </View>
            <View
              border={{ top: true }}
              // style={{ marginTop: "5px" }}
            >
              <View width="65%" flexDirection="column">
                <Text>
                  <Text bold>SEÑOR(ES): </Text>
                  <Text>{data.senores}</Text>
                </Text>
              </View>
              <View width="35%">
                <Text>
                  <Text bold>NIT: </Text>
                  <Text>{data.nit}</Text>
                </Text>
              </View>
            </View>
            <View
            // style={{ marginTop: "5px" }}
            >
              <View width="65%" flexDirection="column">
                <Text>
                  <Text bold>Doc. Embarque: </Text>
                  <Text>{data.docEmbarque}</Text>
                </Text>
              </View>
              <View width="35%">
                <Text>
                  <Text bold>Int. Agencia: </Text>
                  <Text>{data.intAgencia}</Text>
                </Text>
              </View>
            </View>

            <View
            // style={{ marginTop: "5px" }}
            >
              <View width="50%" flexDirection="column">
                <Text>
                  <Text bold>Facturas(s): </Text>
                  <Text>{data.facturas}</Text>
                </Text>
              </View>
              <View width="25%">
                <Text>
                  <Text bold>Bultos: </Text>
                  <Text>{data.bultos}</Text>
                </Text>
              </View>
              <View width="25%">
                <Text>
                  <Text bold>Peso: </Text>
                  <Text>{data.peso}</Text>
                </Text>
              </View>
            </View>
            <View
              flexDirection="column"
              // style={{ marginTop: "5px" }}
            >
              <View>
                <View width="50%">
                  <Text>
                    <Text bold>Proveedor/Emisor: </Text>
                    <Text>{data.proveedor}</Text>
                  </Text>
                </View>
              </View>
              <Text
              // style={{ marginTop: "5px" }}
              >
                <Text bold>Mercadería: </Text>
                <Text>{data.mercancia}</Text>
              </Text>
            </View>
            <View border={{ top: true, bottom: true }}>
              <View width="13%" flexDirection="column">
                <Text bold>Valor FOB </Text>
                <Text bold>Fletes I,II </Text>
                <Text bold>Seguro </Text>
                <Text bold>Otros Gastos </Text>
                <Text bold>Valor CIF </Text>
                <Text bold>T.c. </Text>

                <Text bold>Valor CIF </Text>
              </View>
              <View width="5%" flexDirection="column">
                <Text>$us.</Text>
                <Text>$us.</Text>
                <Text>$us.</Text>
                <Text>$us.</Text>
                <Text>$us.</Text>
                <Text>{data.tc}</Text>
                <Text>Bs.</Text>
              </View>
              <View
                width="12%"
                flexDirection="column"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Text>{data.valorFob}</Text>
                <Text>{data.flete1y2}</Text>
                <Text>{data.seguro}</Text>
                <Text>{data.otrosGastos}</Text>
                <Text>{data.valorCif}</Text>
                <Text> </Text>
                <Text>{data.valorCifBs}</Text>
              </View>
              <View
                style={{ marginLeft: "5%" }}
                width="50%"
                flexDirection="column"
              >
                <Text>
                  <Text bold>Aduana: </Text>
                  <Text>{data.aduana}</Text>
                </Text>
                <Text>
                  <Text bold>Modalidad: </Text>
                  <Text>{data.modalidad}</Text>
                </Text>
                <Text>
                  <Text bold>Items: </Text>
                  <Text>{data.items}</Text>
                </Text>
                <Text>
                  <Text bold>Tipo: </Text>
                  <Text>{data.tipo}</Text>
                </Text>
              </View>
            </View>
            <View style={{ backgroundColor: "#dddddd" }}>
              <Text bold>A - Tributos y otros Conceptos Aduaneros</Text>
            </View>
            {data.aList.map(
              (element) =>
                element.description != null &&
                element.description !== "0" && (
                  <View justifyContent="space-between">
                    <View width="50%">
                      <Text>
                        {element.label} {element.recibo ? "*" : ""}
                      </Text>
                    </View>
                    <View width="50%" justifyContent="flex-end">
                      <Text textAlign="end">{element.description}</Text>
                    </View>
                  </View>
                )
            )}
            <View justifyContent="flex-end">
              <View width="10%">
                <Text bold>Sub Total:</Text>
              </View>
              <View width="20%" justifyContent="flex-end">
                <Text bold textAlign="end">
                  {data.aSubTotal}
                </Text>
              </View>
            </View>
            <View style={{ backgroundColor: "#dddddd" }}>
              <Text bold>B - Otros Gastos de Importacion/Exportacion</Text>
            </View>
            {data.bList.map(
              (element) =>
                element.description != null &&
                element.description !== "0" && (
                  <View justifyContent="space-between">
                    <View width="50%">
                      <Text>
                        {element.label} {element.recibo ? "*" : ""}
                      </Text>
                    </View>
                    <View width="50%" justifyContent="flex-end">
                      <Text textAlign="end">{element.description}</Text>
                    </View>
                  </View>
                )
            )}
            <View justifyContent="flex-end">
              <View width="10%">
                <Text bold>Sub Total:</Text>
              </View>
              <View width="20%" justifyContent="flex-end">
                <Text bold textAlign="end">
                  {data.bSubTotal}
                </Text>
              </View>
            </View>
            <View style={{ backgroundColor: "#dddddd" }}>
              <Text bold>C - Gastos de Operaciones</Text>
            </View>
            {data.cList.map(
              (element) =>
                element.description != null &&
                element.description !== "0" && (
                  <View justifyContent="space-between">
                    <View width="50%">
                      <Text>
                        {element.label} {element.recibo ? "*" : ""}
                      </Text>
                    </View>
                    <View width="50%" justifyContent="flex-end">
                      <Text textAlign="end">{element.description}</Text>
                    </View>
                  </View>
                )
            )}
            <View justifyContent="flex-end">
              <View width="10%">
                <Text bold>Sub Total:</Text>
              </View>
              <View width="20%" justifyContent="flex-end">
                <Text bold textAlign="end">
                  {data.cSubTotal}
                </Text>
              </View>
            </View>
            <View style={{ backgroundColor: "#dddddd" }}>
              <Text bold>D - Honorarios Profesionales</Text>
            </View>
            {data.dList.map(
              (element) =>
                element.description != null &&
                element.description !== "0" && (
                  <View justifyContent="space-between">
                    <View width="50%">
                      <Text>
                        {element.label} {element.recibo ? "*" : ""}
                      </Text>
                    </View>
                    <View width="50%" justifyContent="flex-end">
                      <Text textAlign="end">{element.description}</Text>
                    </View>
                  </View>
                )
            )}
            <View justifyContent="flex-end">
              <View width="10%">
                <Text bold>Sub Total:</Text>
              </View>
              <View width="20%" justifyContent="flex-end">
                <Text bold textAlign="end">
                  {data.dSubTotal}
                </Text>
              </View>
            </View>
            <View
              flexDirection="column"
              padding="5px"
              border={{ top: true, bottom: true }}
              style={{ marginTop: "5px" }}
            >
              <View justifyContent="flex-end">
                <View width="35%">
                  <Text bold>
                    Tributos Aduaneros, otros Gastos y Factura (Bs.)
                  </Text>
                </View>
                <View borderContent width="20%" justifyContent="flex-end">
                  <Text bold textAlign="end">
                    {data.tributosAduanerosOtrosGastosYFactura}
                  </Text>
                </View>
              </View>
              {data.descuentosBs &&
              data.descuentosBs !== "-" &&
              data.descuentosBs !== "0" ? (
                <View justifyContent="flex-end">
                  <View width="12.5%">
                    <Text bold>Descuento (Bs.)</Text>
                  </View>
                  <View
                    border={{ right: true, left: true, bottom: true }}
                    width="20%"
                    justifyContent="flex-end"
                  >
                    <Text bold textAlign="end">
                      {data.descuentosBs}
                    </Text>
                  </View>
                </View>
              ) : null}
              <View justifyContent="flex-end">
                <View width="15.5%">
                  <Text bold>Total Proforma (Bs.)</Text>
                </View>
                <View
                  border={{ right: true, left: true, bottom: true }}
                  width="20%"
                  justifyContent="flex-end"
                >
                  <Text bold textAlign="end">
                    {data.totalProformaBs}
                  </Text>
                </View>
              </View>
              <View justifyContent="flex-end">
                <View width="19%">
                  <Text bold>Total Proforma $us. 6.85</Text>
                </View>
                <View
                  border={{ right: true, left: true, bottom: true }}
                  width="20%"
                  justifyContent="flex-end"
                >
                  <Text bold textAlign="end">
                    {data.totalProformaSus}
                  </Text>
                </View>
              </View>
            </View>
            {data.clarifications ? (
              <Text color="#004080" fontSize="7px" bold>
                ACLARACIONES: {data.clarifications}
              </Text>
            ) : null}
            <View>
              <View width="50%" flexDirection="column">
                <Text color="#004080" bold>
                  BANCO UNION S.A. (Cta. Corriente)
                </Text>
                <Text>Cuenta En Bs.: {accountNumber}</Text>
                {/* <Text>TRADECRUZ S.R.L</Text> */}
              </View>
              <View width="50%" flexDirection="column">
                <Text>
                  Elaborado por: {user.user.name} {user.user.fatherLastName}{" "}
                  {user.user.motherLastName}
                </Text>
                {/* <Text>NIT: 375482020</Text> */}
                {/* <Text>SWIFT: BAUNBO22</Text> */}
              </View>
            </View>
            {/* <Text fontSize="7px">
              Nota.: La presente Proforma esta sujeta a Cambio dentro del plazo
              requerido por Tradecruz SRL por variacion en el T./C.
            </Text> */}
          </View>
          <View
            flexDirection="column"
            padding="4px 0"
            justifyContent="center"
            alignItems="center"
            style={{ backgroundColor: "#002747" }}
          >
            {/* <Text color="white">
              4to. Anillo entre Av. Banzer y Beni Calle Tristan
              Languidey Nro. 19
            </Text>
            <Text color="white">
              Teléfono: (591)3-3406135 Teléfono: (591)3-385332 - (591)3-3852168
            </Text> */}
          </View>
        </View>
      </Page>
    </Document>
  );

  async function handleOnClick() {
    await pdf(MyDoc)
      .toBlob()
      .then((r) => {
        var file = new File([r], `Proforma-${dayjs().format("DD-MM-YYYY")}`);
        uploadFile(file);
      });
  }

  async function uploadFile(file) {
    let formData = new FormData();
    formData.append("Name", `Proforma-${dayjs().format("DD-MM-YYYY")}`);
    formData.append("File", file);
    formData.append(
      "originalName",
      `Proforma-${dayjs().format("DD-MM-YYYY")}.pdf`
    );
    formData.append("Status", "verified");
    formData.append("ProcedureId", procedure.id || procedure.procedureId);
    await requestAuth("post", "/files", formData)
      .then((res) => {
        handleRegister({
          url: res.data.url,
          name: `Proforma-${dayjs().format("DD-MM-YYYY")}`,
        });
      })
      .catch(() => {
        toast.error("Se produjo un error al subir el archivo");
      });
  }

  async function handleRegister(values) {
    await requestAuth(
      "post",
      `/fileState/procedure/${procedure.id || procedure.procedureId}/field/590`,
      values
    )
      .then(() => {
        // toast.success("Registro exitoso");
      })
      .catch(() => {
        toast.error("Se produjo un error al guardar");
      });
  }

  return (
    <div>
      <PDFViewer
        style={{
          width: 900,
          height: window.innerHeight - 100,
        }}
        children={MyDoc}
      />
    </div>
  );
};

export default RenderLiquidaciónProforma;
