import { H3 } from "../../components/Text";
import { useParams } from "react-router-dom";
import {
  headerList,
  filtersDefault,
  addFiltersDefault,
} from "../../utils/constant";
import { useModal } from "../../contexts/ModalContext";
import { useDialog } from "../../contexts/DialogContext";
import CallServiceTable from "../../components/Tables/CallServiceTable";
import { DialogConfirmation } from "../../components/Modal";
import RenderLiquidaciónProforma from "../../containers/ManegeProcedure/RenderLiquidaciónProforma";
import { FillForm, SendProcedure } from "../../containers/steps";
import ListDocument from "../../components/common/ListDocument";
import GenerateRoadmap from "../../containers/ManegeProcedure/GenerateRoadmap";
import { Tooltip } from "react-tippy";

const ProceduresInCharge = () => {
  const { rolId } = useParams();
  const { openModal, onClose } = useModal();
  const { openDialog, dialogClose } = useDialog();

  function handleProcedure(procedure, reloadTable) {
    openModal(
      <FillForm
        procedure={{
          ...procedure,
          processStepId: 14,
        }}
        reload={reloadTable}
        onClose={onClose}
        openModal={openModal}
        rolId={rolId}
        step={12}
        typeId="1"
      />
    );
  }

  function generateSettlementProforma(procedure) {
    openModal(<RenderLiquidaciónProforma procedure={procedure} />);
  }

  function handleReadProforma(procedure) {
    openDialog(
      <DialogConfirmation
        children={
          <H3>¿Estas seguro que quieres extraer los datos del excel?</H3>
        }
        onClose={dialogClose}
        method="post"
        url={`Tradecruz/procedure/${procedure.id}/readerXlsxProforma`}
        texBtn="Extraer"
        handleSuccess={onClose}
      />
    );
  }

  function handleDocument(procedure) {
    openModal(<ListDocument procedure={procedure} />);
  }

  function generateRoadmap(procedure) {
    openDialog(<GenerateRoadmap procedure={procedure} />);
  }

  function handleSendProcedure(procedure, reloadTable) {
    openModal(
      <SendProcedure
        openModal={openModal}
        procedure={{ ...procedure, processStepId: 14 }}
        reload={reloadTable}
        onClose={onClose}
        isInCharge={true}
      />
    );
  }

  return (
    <CallServiceTable
      urlApi="/procedure/byInCharge"
      filters={[
        {
          name: "procedureType",
          url: "/procedureType",
          default: "Todos los tramites",
          valueOption: "id",
          labelOption: "name",
        },
        ...filtersDefault,
      ]}
      addFilters={addFiltersDefault}
      header={[
        ...headerList,
        {
          name: "Action",
          type: "action",
          label: "Acciones",
          disabledOnClick: true,
          sticky: true,
          builder: (_, __, data, call, buttons) => {
            return (
              <div className="flex items-center justify-center gap-1 px-1">
                <div
                  className={`text-red-500 text-xl cursor-pointer`}
                  onClick={() => handleSendProcedure(data, call)}
                >
                  <Tooltip
                    title="Enviar"
                    position="right"
                    trigger="mouseenter"
                  >
                    <i className={"fas fa-share-square p-1"}></i>
                  </Tooltip>
                </div>
              </div>
            );
          },
          actions: [
            {
              label: "Enviar correo",
              icon: "fas fa-share-square",
              name: "trz-010-leerProforma",
              action: handleSendProcedure,
              color: "text-[#e74c3c]",
            },
            {
              label: "Leer proforma",
              icon: "fas fa-file-import",
              name: "trz-010-leerProforma",
              action: handleReadProforma,
              color: "text-[#1d4ed8]",
            },
            {
              label: "Generar liquidación - proforma",
              icon: "fas fa-coins",
              action: generateSettlementProforma,
              color: "text-[#1d4ed8]",
            },
            {
              label: "Ver documentos",
              icon: "fa-solid fa-folder",
              action: handleDocument,
              color: "text-[#1d4ed8]",
            },
            {
              label: "Generar hoja de ruta",
              icon: "fas fa-route",
              action: generateRoadmap,
              color: "text-[#1d4ed8] pr-5",
            },
          ],
        },
      ]}
      onClick={handleProcedure}
    />
  );
};

export default ProceduresInCharge;
