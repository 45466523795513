// import * as Yup from "yup";
// import { toast } from "react-toastify";
import { useModal } from "../../contexts/ModalContext";
// import FormDefault from "../../components/Forms/FormDefault";
// import { requestAuth } from "../../components/services/RequestService";
import CallServiceTable from "../../components/Tables/CallServiceTable";
import {
  filtersDefault,
  addFiltersDefault,
  headerListFiscalizationContent,
  // headerList,
} from "../../utils/constant";
import { FillForm, SendProcedure } from "../../containers/steps";
// import { Tooltip } from "react-tippy";
import { useParams } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { validateArray } from "../../utils/validate";
import TableLisDocument from "../../components/common/TableLisDocument";
import { useDialog } from "../../contexts/DialogContext";
import { DialogConfirmation } from "../../components/Modal";
import { H3 } from "../../components/Text";

const Fiscalization = ({ type, procedureTypeId }) => {
  const { openModal, onClose } = useModal();
  const { rolId } = useParams();
  const { openDialog, dialogClose } = useDialog();
  // function handleFiscalization(procedure, reload) {
  //   openModal(
  //     <FormFiscalization
  //       procedureTypeId={procedureTypeId}
  //       procedure={procedure}
  //       reload={reload}
  //       onClose={onClose}
  //       openModal={openModal}
  //     />
  //   );
  // }

  // function handleSendProcedure(procedure, reloadTable) {
  //   openModal(
  //     <SendProcedure
  //       openModal={openModal}
  //       procedure={procedure}
  //       reload={reloadTable}
  //       onClose={onClose}
  //       isInCharge={true}
  //     />
  //   );
  // }

  function handleProcedure(procedure, reloadTable) {
    openModal(
      <FillForm
        procedure={{
          ...procedure,
          processStepId: 40,
        }}
        reload={reloadTable}
        onClose={onClose}
        openModal={openModal}
        rolId={rolId}
        step={12}
        typeId="1"
      />
    );
  }
  function handleSendProcedure(procedure, reloadTable) {
    openModal(
      <SendProcedure
        openModal={openModal}
        procedure={{ ...procedure, processStepId: 40 }}
        reload={reloadTable}
        onClose={onClose}
      />
    );
  }

  function handleFiscalizationComplete(procedure, reload) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro de cambiar el estado de fiscalización a Completado?</H3>}
        onClose={dialogClose}
        method="put"
        url={`/dataSet/procedure/${procedure.id}/fieldname/estadoDeFiscalización/value/Completado`}
        texBtn="Completar"
        handleSuccess={reload}
      />
    );
  }
  return (
    <CallServiceTable
      filters={filtersDefault}
      urlApi={`/procedure/proceduresPerMenu/${type}`}
      onClick={handleProcedure}
      addFilters={addFiltersDefault}
      header={[
        {
          name: "internCode",
          label: "Nro. interno",
          type: "sticky",
          listHeader: [
            { name: "internCode", label: "Nro. interno" },
            { name: "companyName", label: "Cliente" },
          ],
        },
        {
          name: "estadoFiscalizacion",
          label: "Estado",
          type: "custom",
          builder: (value) => {
            return (
              <div
                className={`text-white ${value === "Sin fiscalizar" || value === "Pendiente"
                  ? "bg-[#fd3838A1]"
                  : value === "Completado"
                    ? "bg-[#4fce4421] text-gray-500"
                    : ""
                  } text-center rounded-full w-fit py-1 px-4 `}
              >
                {value}
              </div>
            );
          },
        },
        { name: "ordenDeControlDiferidoCdi", label: "Orden de control" },
        { name: "tecnicoAsignadoControlDiferidoInmediato", label: "Técnico asignado" },
        ...headerListFiscalizationContent,
        {
          name: "Action",
          type: "custom",
          label: "Acciones",
          disabledOnClick: true,
          sticky: true,
          builder: (_, __, data, call, buttons) => {
            const listButton = buttons ? JSON.parse(buttons) : [];
            return (
              <div className="flex items-center justify-center gap-1 px-1">
                <div
                  className={`text-red-500 text-xl cursor-pointer`}
                  onClick={() => handleSendProcedure(data, call)}
                >
                  <Tooltip
                    title="Enviar"
                    position="right"
                    trigger="mouseenter"
                  >
                    <i className={"fas fa-share-square p-1"}></i>
                  </Tooltip>
                </div>
                {
                  (data?.estadoFiscalizacion === null || data?.estadoFiscalizacion === 'Pendiente') &&

                  <div
                    className={`text-blue-500 text-xl cursor-pointer`}
                    onClick={() => handleFiscalizationComplete(data, call)}
                  >
                    <Tooltip
                      title="Completar Fiscalización"
                      position="right"
                      trigger="mouseenter"
                    >
                      <i className={"fas fa-check-to-slot p-1"}></i>
                    </Tooltip>
                  </div>
                }
                {listButton && validateArray(listButton) ? (
                  <TableLisDocument
                    call={call}
                    listButton={listButton}
                    procedure={data}
                  />
                ) : null}
              </div>
            );
          },
        },
      ]}
    // addUrl={`procedureType=${procedureTypeId}`}
    // onClick={handleProcedure}
    />
  );
};

// const FormFiscalization = (props) => {
//   const { procedure, procedureTypeId, reload,openModal, onClose } = props;
//   const { rolId } = useParams();

//   async function uploadFile(values) {
//     let formData = new FormData();
//     formData.append("Name", "DIM Regularizada");
//     formData.append("File", values.file);
//     formData.append("originalName", values.file.name);
//     formData.append("Status", "verified");
//     formData.append("ProcedureId", procedure.id);
//     await requestAuth("post", "/files", formData)
//       .then((res) => {
//         onSubmit({
//           ...values,
//           file: res.data.url,
//           FileId: res.data.id,
//         });
//       })
//       .catch(() => {
//         toast.error("Se produjo un error al subir el archivo");
//       });
//   }

//   async function onSubmit(values) {
//     await requestAuth("post", "/dataset", [
//       {
//         FieldId: procedureTypeId === 1 ? 181 : 595,
//         FileId: values.FileId,
//         ProcedureId: procedure.id,
//         Value: values.file,
//       },
//       {
//         FieldId: 183,
//         ProcedureId: procedure.id,
//         Value: values.regularizationDate,
//       },
//       {
//         FieldId: 722,
//         ProcedureId: procedure.id,
//         Value: values.fineForTemporaryRegularization,
//       },
//       {
//         FieldId: 723,
//         ProcedureId: procedure.id,
//         Value: values.amountOfFineForTemporaryRegularization,
//       },
//       {
//         FieldId: 724,
//         ProcedureId: procedure.id,
//         Value: values.paidBy,
//       },
//       {
//         FieldId: procedureTypeId === 1 ? 594 : 596,
//         ProcedureId: procedure.id,
//         Value: values.observation,
//       },
//     ])
//       .then(() => {
//         reload();
//         onClose();
//         toast.success("Tramite regularizado");
//       })
//       .catch(() => {
//         toast.error("Se ha producido un error");
//       });
//   }


//   return (
//     // <FormDefault
//     //   tittle={`Regularizar el trámite (${
//     //     procedure.internCode || procedure.name
//     //   })`}
//     //   fields={
//     //     [
//     //     {
//     //       label:
//     //         procedureTypeId === 1
//     //           ? "DIM regularizada"
//     //           : "Constancia de salida de exportación",
//     //       name: "file",
//     //       type: "file",
//     //       typeInput: "file",
//     //     },
//     //     {
//     //       label: "Fecha de regularización",
//     //       name: "regularizationDate",
//     //       type: "date",
//     //       typeInput: "input",
//     //     },
//     //     {
//     //       label: "Multa por regularización atemporal",
//     //       name: "fineForTemporaryRegularization",
//     //       typeInput: "input",
//     //     },
//     //     {
//     //       label: "Monto de multa por regularización atemporal Bs.",
//     //       name: "amountOfFineForTemporaryRegularization",
//     //       typeInput: "input",
//     //     },
//     //     {
//     //       label: "Pagado por",
//     //       name: "paidBy",
//     //       typeInput: "input",
//     //     },
//     //     {
//     //       label:
//     //         procedureTypeId === 1
//     //           ? "Observaciones de la DIM regularizada"
//     //           : "Observaciones de la constancia",
//     //       name: "observation",
//     //       placeholder: "Introduzca las observaciones",
//     //       type: "textArea",
//     //       typeInput: "input",
//     //     },
//     //   ]
//     // }
//     //   initialValues={{
//     //     file:
//     //       procedureTypeId === 1
//     //         ? procedure?.dimRegularizada && procedure.dimRegularizada !== "-"
//     //           ? procedure.dimRegularizada
//     //           : null
//     //         : procedure?.constanciaDeSalidaDeExportacion &&
//     //           procedure.constanciaDeSalidaDeExportacion !== "-"
//     //         ? procedure.constanciaDeSalidaDeExportacion
//     //         : null,
//     //     regularizationDate: procedure?.regularizationDate ?? "",
//     //     fineForTemporaryRegularization:
//     //       procedure?.fineForTemporaryRegularization ?? "",
//     //     amountOfFineForTemporaryRegularization:
//     //       procedure?.amountOfFineForTemporaryRegularization ?? "",
//     //     paidBy: procedure?.paidBy ?? "",
//     //     observation:
//     //       procedureTypeId === 1
//     //         ? procedure?.observacionesDeLaDimRegularizada ?? ""
//     //         : procedure?.observacionesDeLaConstanciaDeSalidaDeExportacion ?? "",
//     //   }}
//     //   validationSchema={Yup.object().shape({
//     //     file: Yup.mixed().required("Campo requerido.").nullable(),
//     //   })}
//     //   onSubmit={(values) =>
//     //     typeof values.dimRegularizada === "string"
//     //       ? onSubmit(values)
//     //       : uploadFile(values)
//     //   }
//     //   buttonName="Regularizar"
//     // />
//     <CallServiceTable
//       urlApi="/procedure/byInCharge"
//       filters={[
//         {
//           name: "procedureType",
//           url: "/procedureType",
//           default: "Todos los tramites",
//           valueOption: "id",
//           labelOption: "name",
//         },
//         ...filtersDefault,
//       ]}
//       addFilters={addFiltersDefault}
//       header={[
//         ...headerList,
//         {
//           name: "Action",
//           type: "action",
//           label: "Acciones",
//           disabledOnClick: true,
//           sticky: true,
//           builder: (_, __, data, call, buttons) => {
//             return (
//               <div className="flex items-center justify-center gap-1 px-1">
//                 <div
//                   className={`text-red-500 text-xl cursor-pointer`}
//                   onClick={() => handleSendProcedure(data, call)}
//                 >
//                   <Tooltip
//                     title="Enviar"
//                     position="right"
//                     trigger="mouseenter"
//                   >
//                     <i className={"fas fa-share-square" + " p-1"}></i>
//                   </Tooltip>
//                 </div>
//               </div>
//             );
//           },
//           // actions: [
//           //   {
//           //     label: "Enviar correo",
//           //     icon: "fas fa-share-square",
//           //     name: "trz-010-leerProforma",
//           //     action: handleSendProcedure,
//           //     color: "text-[#e74c3c]",
//           //   },
//           //   {
//           //     label: "Leer proforma",
//           //     icon: "fas fa-file-import",
//           //     name: "trz-010-leerProforma",
//           //     action: handleReadProforma,
//           //     color: "text-[#1d4ed8]",
//           //   },
//           //   {
//           //     label: "Generar liquidación - proforma",
//           //     icon: "fas fa-coins",
//           //     action: generateSettlementProforma,
//           //     color: "text-[#1d4ed8]",
//           //   },
//           //   {
//           //     label: "Ver documentos",
//           //     icon: "fa-solid fa-folder",
//           //     action: handleDocument,
//           //     color: "text-[#1d4ed8]",
//           //   },
//           //   {
//           //     label: "Generar hoja de ruta",
//           //     icon: "fas fa-route",
//           //     action: generateRoadmap,
//           //     color: "text-[#1d4ed8] pr-5",
//           //   },
//           // ],
//         },
//       ]}
//       onClick={handleProcedure}
//     />
//   );
// };

export default Fiscalization
