import * as Yup from "yup";
import * as dayjs from "dayjs";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { H2, H3 } from "../../components/Text";
import { Button } from "../../components/Buttons";
import { Circle } from "../../components/Animation";
import { DialogConfirmation } from "../../components/Modal";
import { useModal } from "../../contexts/ModalContext";
import { Request } from "../../hooks/Request";
import { useDialog } from "../../contexts/DialogContext";
import { validateArray } from "../../utils/validate";
import FormDefault from "../../components/Forms/FormDefault";
import { requestAuth } from "../../components/services/RequestService";
import CallServiceTable from "../../components/Tables/CallServiceTable";
import GenerateRoadmap from "../../containers/ManegeProcedure/GenerateRoadmap";
import {
  addFiltersDefault,
  filtersDefault,
  headerList,
} from "../../utils/constant";
import TableComplete from "../../components/Tables/TableComplete";

const ListProcedures = () => {
  const { openModal, onClose } = useModal();
  const { openDialog } = useDialog();

  function handleDetail(procedure) {
    openModal(
      <HistoryDetail
        procedure={procedure}
        openModal={openModal}
        onClose={onClose}
      />
    );
  }

  function generateRoadmap(procedure) {
    openDialog(<GenerateRoadmap procedure={procedure} />);
  }

  return (
    <>
      <CallServiceTable
        onClick={handleDetail}
        urlApi="/procedure/openprocedures"
        addFilters={addFiltersDefault}
        filters={[
          {
            name: "procedureType",
            url: "/procedureType",
            default: "Todos los tramites",
            valueOption: "id",
            labelOption: "name",
          },
          {
            name: "Year",
            default: "",
            label: "Fecha de inicio",
            options: [
              {
                label: "2024",
                name: "2024",
                initial: true,
              },
              {
                label: "2023",
                name: "2023",
              },
              {
                label: "2022",
                name: "2022",
              },
              {
                label: "2021",
                name: "2021",
              },
            ],
          },
          ...filtersDefault,
        ]}
        header={headerList}
      />
    </>
  );
};

const HistoryDetail = ({ procedure, openModal, onClose }) => {
  const [page, setpage] = useState(1);
  const { openDialog, dialogClose } = useDialog();

  const { data, loading, call, pagination } = Request({
    urlApi: `/procedure/procedureHistory/${procedure.id}?PageNumber=${page}`,
  });

  useEffect(() => {
    if (loading) {
      call();
    }
  }, [page]);

  function handlePagination(number) {
    setpage((a) => a + number);
  }

  function handlePaginationStatic(number) {
    setpage(number);
  }

  function handleEdit(procedureHistory) {
    openModal(
      <FormEdit
        procedure={procedure}
        procedureHistory={procedureHistory}
        onClose={onClose}
        reload={call}
      />
    );
  }
  function handleAddHistory() {
    openModal(
      <FormEdit
        procedure={procedure}
        procedureHistory={null}
        onClose={onClose}
        reload={call}
      />
    );
  }

  function handleDelete(procedureHistory) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres eliminar el historial?</H3>}
        onClose={dialogClose}
        method="delete"
        url={`/procedureHistory/${procedureHistory.procedureHistoryId}`}
        texBtn="Eliminar"
        handleSuccess={call}
      />
    );
  }
  function handleProcessStepHistory() {
    openModal(
      <ProcessSteps
        procedure={procedure}
        procedureHistory={null}
        onClose={onClose}
        reload={call}
        openDialog={openDialog}
        dialogClose={dialogClose}
      />
    );
  }

  const Pagination = ({ pag }) => {
    return (
      <div className="flex items-center gap-1">
        <Button
          onClick={() => handlePaginationStatic(1)}
          disabled={pag.currentPage <= 1}
          className="px-2 py-2"
        >
          <i className="fas fa-angle-left"></i>
          <i className="fas fa-angle-left"></i>
        </Button>
        <Button
          onClick={() => handlePagination(-1)}
          disabled={pag.currentPage <= 1}
          className="px-2 py-2"
        >
          <i className="fas fa-angle-left"></i>
        </Button>
        {pag.currentPage > 1 ? (
          <h4 className="bg-white p-2 rounded-md">{pag.currentPage - 1}</h4>
        ) : null}
        <h4 className="bg-white p-2 rounded-md border-2 border-[#FBCE00]">
          {pag.currentPage}
        </h4>
        {pag.currentPage < pag.totalPages ? (
          <h4 className="bg-white p-2 rounded-md">{pag.currentPage + 1}</h4>
        ) : null}
        <Button
          onClick={() => handlePagination(1)}
          disabled={pag.currentPage >= pag.totalPages}
          className="px-2 py-2"
        >
          <i className="fas fa-angle-right"></i>
        </Button>
        <Button
          onClick={() => handlePaginationStatic(pag.totalPages)}
          disabled={pag.currentPage >= pag.totalPages}
          className="px-2 py-2"
        >
          <i className="fas fa-angle-right"></i>
          <i className="fas fa-angle-right"></i>
        </Button>
      </div>
    );
  };

  if (!loading) {
    return <Circle />;
  }

  return (
    <>
      <div className="flex justify-between items-center mb-2">
        <H2 margin="0">Historial del trámite</H2>
        <div className="flex gap-3">
          <Button onClick={handleProcessStepHistory} className="px-2 py-2">
            {/* <i className="fas fa-code-branch"></i> */}
            <span className="text-sm font-semibold">Valores</span>
          </Button>
          <Button onClick={handleAddHistory} className="px-2 py-2">
            <i className="fas fa-plus"></i>
          </Button>
          <Pagination pag={pagination ? JSON.parse(pagination) : null} />
        </div>
      </div>
      {validateArray(data)
        ? data.map((item) => (
          <div className="flex gap-2 mb-1" key={item.procedureHistoryId}>
            <div
              className={`flex items-center justify-between gap-2 w-full px-2 py-1  ${item.stepAction === "startStep"
                ? "border-2 border-blue-500"
                : item.stepAction === "finishStep"
                  ? "border-2 border-blue-500"
                  : ""
                } bg-slate-100 rounded-md`}
            >
              <div className="flex items-center gap-2">
                <h3 className="font-bold text-green-600">
                  {item.stepNumber}
                </h3>
                <div className="">
                  <h4 className="">{item.user}</h4>
                  <h4 className="font-semibold">
                    {item.description
                      ? item.description
                      : item.stepAction === "startStep"
                        ? "Paso iniciado"
                        : item.stepAction === "finishStep"
                          ? "Paso finalizado"
                          : "Llenando el formulario"}
                  </h4>
                </div>
              </div>
              <div className="">
                <h4 className="text-end">{item.step}</h4>
                <h4 className="text-end">
                  {dayjs(item.date).format("DD/MM/YYYY HH:mm")}
                </h4>
              </div>
            </div>
            {/* <Button className="px-2" onClick={() => handleDataSets(item)}>
              <i className="fas fa-trash"></i>
            </Button> */}
            <Button className="px-2" onClick={() => handleEdit(item)}>
              <i className="far fa-edit"></i>
            </Button>
            <Button className="px-2" onClick={() => handleDelete(item)}>
              <i className="fas fa-trash"></i>
            </Button>
          </div>
        ))
        : null}
    </>
  );
};

const headDataSetHistory = [
  {
    name: "userNameComplete",
    label: "Responsable",
  },
  {
    name: "description",
    label: "Descripción",
  },
  {
    name: "valueBefore",
    label: "Valor anterior",
  },
  {
    name: "valueCurrent",
    label: "Valor actual",
  },
  {
    name: "creationDate",
    label: "Fecha de modificación",
    type: "datetime",
  },
];

const DataSetDialog = ({
  url,
  dataSetLabel
}) => {
  const { data, loading } = Request({
    urlApi: url,
  });
  return (
    <>
      {
        !loading &&
        <Circle />
      }
      {
        loading &&
        <TableComplete
          titleTable={`${dataSetLabel}`}
          header={headDataSetHistory}
          data={data}
        />
      }
    </>
  )
}

const ProcessSteps = (props) => {
  const { procedure, openDialog, dialogClose } = props;
  const { data, loading } = Request({
    urlApi: `/procedure/${procedure.id}/dataSetsPerSteps`,
  });
  function handleDataSets(dataset) {
    openDialog(
      <DataSetDialog
        // children={<H3>¿Estas seguro que quieres eliminar el historial?</H3>}
        onClose={dialogClose}
        url={`/dataSet/${dataset.id}/history`}
        dataSetLabel={dataset?.fieldLabel}
      />
      // <div>{JSON.stringify(dataset)}</div>
    );
  }
  return (
    <div className="grid grid-cols-1 gap-2">
      {
        !loading &&
        <Circle />
      }
      {
        loading && data?.map((d, index) => (
          <div
            key={index}
            className="border-2 border-blue-500 p-2 bg-slate-100 rounded-md"
          >
            <h3 className="font-bold">{d.step + ". " + d.name}</h3>
            <div className="flex flex-wrap gap-2">
              {
                d.datasets?.map((ds, idx) => (
                  <div key={idx}>
                    <button
                      type="button"
                      className="font-semibold bg-gray-300 rounded px-2 py-1"
                      onClick={() => handleDataSets(ds)}
                    >
                      {(idx + 1) + ". " + ds.fieldLabel}
                    </button>
                  </div>
                ))
              }
            </div>
          </div>
        ))
      }
    </div>
  );
};
const FormEdit = (props) => {
  const { procedure, procedureHistory, onClose, reload } = props;
  return (
    <>
      <FormDefault
        tittle={procedureHistory ? "Editar datos" : "Adicionar"}
        fields={[
          {
            label: "Editar fecha",
            name: "registerDate",
            type: "datetime-local",
          },
          {
            label: "Seleccione el paso:",
            name: "step",
            aditionalName: "StepName",
            typeInput: "select",
            urlApi: `/processstep/GetProcessStepsByProcedureTypeId/${procedure.procedureTypeId}`,
            value: "step",
            labelOption: "name",
            labelOptionParam: "step",
          },
          {
            label: "Id del usuario:",
            name: "userId",
            typeInput: "tableSelect",
            urlApi: `/processstep/GetProcessStepsByProcedureTypeId/${procedure.procedureTypeId}`,
            header: [],
            component: () => {
              return <div className="">sasd</div>;
            },
          },

          {
            label: "Acción del paso",
            name: "stepAction",
            type: "select",
            typeInput: "select",
            options: [
              {
                label: "Paso iniciado",
                value: "startStep",
              },
              {
                label: "Paso finalizado",
                value: "finishStep",
              },
            ],
          },
        ]}
        initialValues={{
          registerDate: procedureHistory?.date
            ? procedureHistory.date.split(".")[0]
            : "",
          step: procedureHistory?.stepNumber ? procedureHistory.stepNumber : "",
          userId: procedureHistory?.userId ? procedureHistory.userId : "",
          stepAction: procedureHistory?.stepAction
            ? procedureHistory.stepAction
            : "",
        }}
        validationSchema={Yup.object().shape({})}
        onSubmit={async (values) => {
          await requestAuth(
            procedureHistory ? "put" : "post",
            "/procedureHistory",
            procedureHistory
              ? {
                ProcedureHistoryId: procedureHistory.procedureHistoryId,
                ...values,
              }
              : {
                ProcedureId: procedure.id,
                ...values,
              }
          )
            .then(() => {
              onClose();
              reload();
              toast.success("Se guardo con éxito");
            })
            .catch((response) => {
              toast.error(
                response.response.data
                  ? response.response.data
                  : "Se produjo un error al guardar"
              );
            });
        }}
        buttonName={procedureHistory ? "Editar" : "Guardar"}
      />
      <br />
    </>
  );
};

export default ListProcedures;
