import { useParams } from "react-router-dom";
import { Button } from "../../components/Buttons";
import { NewProcedure } from "../../containers/steps";
import {
  headerList,
  filtersDefault,
  addFiltersDefault,
} from "../../utils/constant";
import { useModal } from "../../contexts/ModalContext";
import CallServiceTable from "../../components/Tables/CallServiceTable";

const Settlement = () => {
  const { openModal, onClose } = useModal();
  const { rolId } = useParams();

  function handleAdd(reloadTable) {
    openModal(<NewProcedure reload={reloadTable} onClose={onClose} />);
  }

  return (
    <div>
      <CallServiceTable
        component={({ reload }) => (
          <Button onClick={() => handleAdd(reload)}>
            Agregar nuevo trámite
          </Button>
        )}
        filters={[
          {
            name: "procedureType",
            url: "/procedureType",
            default: "Todos los tramites",
            valueOption: "id",
            labelOption: "name",
          },
          {
            name: "Year",
            default: "",
            label: "Fecha de inicio",
            options: [
              {
                label: "2024",
                name: "2024",
                initial: true,
              },
              {
                label: "2023",
                name: "2023",
              },
              {
                label: "2022",
                name: "2022",
              },
              {
                label: "2021",
                name: "2021",
              },
            ],
          },
          ...filtersDefault,
        ]}
        addFilters={addFiltersDefault}
        urlApi="/procedure/openprocedures"
        header={[
          ...headerList,
          {
            name: "dav",
            label: "Generar DAV",
            type: "custom",
            builder: (_, __, data) => {
              return (
                <Button
                  typeBtn="link"
                  href={`/rol/${rolId}/procedure-type/${data.procedureTypeId}/step/1/procedure/${data.id}/generate/dav`}
                  className="px-2.5 py-1.5 text-[#1d4fd8b7] bg-[#1d4fd827]"
                  position="left"
                  content="Genere, cargue y exporte un documento DAV"
                >
                  <i className="fas fa-clipboard-list"></i>
                </Button>
              );
            },
          },
          {
            name: "DAM",
            label: "Generar DAM",
            type: "custom",
            builder: (__, _, data) => {
              return (
                <Button
                  typeBtn="link"
                  href={`/rol/${rolId}/procedure-type/${data.procedureTypeId}/step/1/procedure/${data.id}/generate/dam`}
                  className="px-2.5 py-1.5 text-[#1d4fd8b7] bg-[#1d4fd827]"
                  position="left"
                  content="Genere, cargue y exporte un documento DAM"
                >
                  <i className="fas fa-file-invoice"></i>
                </Button>
              );
            },
          },
          {
            name: "DIM",
            label: "Generar DIM",
            type: "custom",
            builder: (__, _, data) => {
              return (
                <Button
                  typeBtn="link"
                  href={`/rol/${rolId}/procedure-type/${data.procedureTypeId}/step/1/procedure/${data.id}/generate/dim`}
                  className="px-2.5 py-1.5 text-[#1d4fd8b7] bg-[#1d4fd827]"
                  position="left"
                  content="Genere, cargue y exporte un documento DIM"
                >
                  <i className="fas fa-file-excel"></i>
                </Button>
              );
            },
          },
        ]}
      />
    </div>
  );
};

export default Settlement;
