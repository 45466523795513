import * as Yup from "yup";
import { Circle } from "../../components/Animation";
import { requestAuth } from "../../components/services/RequestService";
import FormDefault from "../../components/Forms/FormDefault";
import { toast } from "react-toastify";
import { Request } from "../../hooks/Request";

const GenerateExpenseSheet = (props) => {
  const { procedure, onClose, reload } = props;

  const { data, loading, call } = Request({
    urlApi: `/procedure/${procedure.id}/tareas`,
  });

  function dataIntialValues(list) {
    let aux = {};
    list?.forEach((element) => {
      aux = {
        ...aux,
        [element.name]:
          (element.initial === "false" ? false : element.initial) || "",
      };
    });
    return aux;
  }

  async function onSubmit(values, resetForm, fields) {
    const result = await Promise.all(
      fields.map(async (element) => {
        if (element.type === "file" || element.type === "fileRead") {
          if (
            (values[element.name] === "" || values[element.name] === null) &&
            (element.initial === "" || element.initial === null)
          ) {
            return null;
          } else if (
            (values[element.name] !== "" && !element.initial) ||
            (values[element.name] !== "" &&
              values[element.name] !== element.initial)
          ) {
            let formData = new FormData();
            formData.append("Name", element.name);
            formData.append("File", values[element.name]);
            formData.append("originalName", values[element.name].name);
            formData.append("Status", "verified");
            formData.append("ProcedureId", procedure.id);
            const fileContents = await requestAuth(
              "post",
              "/files",
              formData
            ).then((res) => {
              return {
                Value: res.data.url,
                ProcedureId: procedure.id,
                FieldId: element.id,
                FileId: res.data.id,
              };
            });
            return fileContents;
          } else {
            return {
              Value: element.initial,
              ProcedureId: procedure.id,
              FieldId: element.id,
            };
          }
        } else if (element.type === "selectNewOption") {
          if (values[element.name + "_isNew"]) {
            await handleCreateOption(
              values[element.name] || values[element.name] === 0
                ? values[element.name]
                : "",
              element.url
            );
          }
          return {
            Value:
              values[element.name] || values[element.name] === 0
                ? values[element.name]
                : "",
            ProcedureId: procedure.id,
            FieldId: element.id,
          };
        } else {
          return {
            Value:
              values[element.name] || values[element.name] === 0
                ? values[element.name]
                : "",
            ProcedureId: procedure.id,
            FieldId: element.id,
          };
        }
      })
    );
    await submitDataset(
      result.filter((n) => n),
      resetForm,
      values.btnSecondary
    );
  }

  async function handleCreateOption(value, dropdown) {
    await requestAuth("post", "/dropdown/option", {
      Name: value,
      DropdownListLabel: dropdown,
    });
  }
  async function submitDataset(values, resetForm) {
    await requestAuth("post", "/dataset", values)
      .then(() => {
        call();
        reload();
        onClose();
        resetForm();
        toast.success("Envio exitoso");
      })
      .catch(() => {
        toast.error("Error en el envio");
      });
  }

  if (!loading) {
    return <Circle />;
  }
  return (
    <div>
      <FormDefault
        fields={data
          .sort((a, b) => a.order - b.order)
          .map((value, index) => ({
            ...value,
            id: procedure.id,
            fieldId: value.id,
            labelCustom: (
              <>
                <span className="font-bold text-green-600">{index + 1}.</span>{" "}
                {value.label}
              </>
            ),
            label: value.label,
            labelCustom: (
              <>
                <span className="text-green-600">{index + 1}.</span>{" "}
                {value.label}
              </>
            ),
            name: value.name,
            validate: value.validate,
            lockEdition: value.lockEdition,
            placeholder: "Complete el campo",
            type: value.type === "fileRead" ? "file" : value.type,
            typeInput: value.type === "fileRead" ? "file" : value.type,
            initial: value.initial ? value.initial : null,
            urlApi: value.url
              ? value.url.includes("/")
                ? value.url
                : `/Dropdown/${value.url}/options`
              : "",
            urlInitial: value.url,
            value: "name",
            labelOption: "name",
            // action: () => handleExcelModal(value),
            reload: call,
            // step: step,
          }))}
        initialValues={dataIntialValues(data)}
        validationSchema={Yup.object().shape()}
        onSubmit={async (values, resetForm) =>
          await onSubmit(values, resetForm, data)
        }
        buttonFixed={true}
        buttonName="Registrar"
      />
    </div>
  );
};

export default GenerateExpenseSheet;
