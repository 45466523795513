import { Request } from "../../hooks/Request";
import logotipo from "../../assets/logoTransporte.png";
import qr from "../../assets/qr.png";
import { Circle } from "../../components/Animation";
import View from "../../components/GeneratePdf/View";
import Text from "../../components/GeneratePdf/Text";
import { Page, Document, PDFViewer, Image, pdf } from "@react-pdf/renderer";
import * as dayjs from "dayjs";
import { requestAuth } from "../../components/services/RequestService";
import { toast } from "react-toastify";
import { Button } from "../../components/Buttons";

const GenerateTransportReceipt = (props) => {
  const { procedure, logo } = props;
  const { data, loading } = Request({
    urlApi: `/tradecruz/procedure/${procedure.id || procedure.procedureId
      }/transportReceipt`,
  });

  if (!loading) {
    return <Circle />;
  }

  async function handleOnClick() {
    await pdf(MyDoc)
      .toBlob()
      .then((r) => {
        var file = new File([r], `Planilla-${dayjs().format("DD-MM-YYYY")}`);
        uploadFile(file);
      });
  }

  async function uploadFile(file) {
    let formData = new FormData();
    formData.append("Name", `Planilla-${dayjs().format("DD-MM-YYYY")}`);
    formData.append("File", file);
    formData.append(
      "originalName",
      `Planilla-${dayjs().format("DD-MM-YYYY")}.pdf`
    );
    formData.append("Status", "verified");
    formData.append("ProcedureId", procedure.id || procedure.procedureId);
    await requestAuth("post", "/files", formData)
      .then((res) => {
        handleRegister({
          url: res.data.url,
          name: `Planilla-${dayjs().format("DD-MM-YYYY")}`,
        });
      })
      .catch(() => {
        toast.error("Se produjo un error al subir el archivo");
      });
  }

  async function handleRegister(values) {
    await requestAuth(
      "post",
      `/fileState/procedure/${procedure.id || procedure.procedureId}/field/355`,
      values
    )
      .then(() => {
        toast.success("Registro exitoso");
      })
      .catch(() => {
        toast.error("Se produjo un error al guardar");
      });
  }

  const MyDoc = (<Document>
    <Page size="A4" style={{ padding: "20px" }}>
      <View borderContent flexDirection="column">
        <View
          alignItems="center"
          justifyContent="space-between"
          style={{ marginTop: "5px", marginLeft: "5px" }}
        >
          <View
            gap="0"
            width="35%"
            flexDirection="column"
            alignItems="center"
            style={{ marginTop: "0px", marginLeft: "0px" }}
          >
            <Image src={logo.route} style={{ height: "70px" }} />
            <Text fontSize="11px" padding="1px 3px">
              {logo.name}
            </Text>
            <Text fontSize="10px" padding="1px 3px">
              Santa Cruz - Bolivia
            </Text>
          </View>
          <View
            width="40%"
            alignItems="center"
            flexDirection="column"
            style={{ margin: "0px 30px" }}
          >
            <View width="100%" justifyContent="center">
              <Text fontSize="15px" bold textAlign="end">
                RECIBO DE TRANSPORTE
              </Text>
            </View>
            <Text fontSize="12px" padding="8px 3px 0px 3px">
              ORIGINAL
            </Text>
            <View width="100%" justifyContent="center">
              <Text fontSize="15px" bold textAlign="end">
                Número: {data?.nroDeRecibo ?? ""}
              </Text>
            </View>
          </View>
        </View>
        <View
          border={{ top: true }}
          style={{ margin: "5px 5px" }}
        ></View>
        <View flexDirection="column" style={{ margin: "10px 25px" }}>
          <View justifyContent="space-between" alignItems="center">
            <View width="60%">
              <Text fontSize="11px">
                Fecha de entrega de mercadería:{" "}
                {data?.fechaHoraEntregaMercaderia &&
                  data?.fechaHoraEntregaMercaderia !== "-"
                  ? dayjs(data.fechaHoraEntregaMercaderia).format(
                    "DD/MM/YYYY"
                  )
                  : "-"}
              </Text>
            </View>
            <View width="40%">
              <Text fontSize="14px" bold>
                Bs.: {data?.sumaTotal ?? ""}
              </Text>
            </View>
          </View>
          <View>
            <View width="18%">
              <Text fontSize="11px">Por concepto de:</Text>
            </View>
            <View width="82%">
              <Text fontSize="11px">
                {data?.detalleConceptos ?? ""}
              </Text>
            </View>
          </View>
          <View>
            <View width="18%"></View>
            <View width="82%">
              <Text fontSize="11px">
                {data?.subDetalleConceptos ?? " "}
              </Text>
            </View>
          </View>
          <View>
            <View width="14%">
              <Text fontSize="11px">La suma de:</Text>
            </View>
            <View width="86%">
              <Text fontSize="11px">
                {data?.sumaTotalLiteral ?? ""}
              </Text>
            </View>
          </View>
          {/* <View>
            <View width="40%">
              <Text fontSize="11px">
                Fecha y hora de entrega de mercadería:
              </Text>
            </View>
            <View width="60%">
              <Text fontSize="11px">
                {data?.fechaHoraEntregaMercaderia ?? ""}
              </Text>
            </View>
          </View> */}
          {/* <View>
            <View width="35%">
              <Text fontSize="11px">
                Medio de pago transporte local:
              </Text>
            </View>
            <View width="65%">
              <Text fontSize="11px">
                {data?.medioPagoTransporteLocal ?? ""}
              </Text>
            </View>
          </View> */}
          {/* <View>
            <View width="30%">
              <Text fontSize="11px">Método de pago de estribaje</Text>
            </View>
            <View width="70%">
              <Text fontSize="11px">
                {data?.medioPagoEstibaje ?? ""}
              </Text>
            </View>
          </View> */}
          <View
            style={{ marginTop: "80px" }}
            justifyContent="space-around"
          >
            <View
              width="30%"
              alignItems="center"
              flexDirection="column"
            >
              <View
                width="100%"
                border={{ top: true }}
                style={{ margin: "5px 5px" }}
              ></View>
              <Text fontSize="11px" padding="1px 3px">
                ENTREGUE CONFORME
              </Text>
            </View>
            <View
              width="30%"
              alignItems="center"
              flexDirection="column"
            >
              <View
                width="100%"
                border={{ top: true }}
                style={{ margin: "5px 5px" }}
              ></View>
              <Text fontSize="11px" padding="1px 3px">
                RECIBÍ CONFORME
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  </Document>)

  return (
    <div>
      <div className="flex justify-end mb-2">
        <Button className="py-2" onClick={handleOnClick}>
          Guardar recibo <i className="fas fa-cloud-upload-alt"></i>
        </Button>
      </div>
      <PDFViewer
        style={{
          width: 900,
          height: window.innerHeight - 100,
        }}
        children={MyDoc}
      />
    </div>
  );
};

export default GenerateTransportReceipt;
