import { useParams } from "react-router-dom";
import { useDialog } from "../../contexts/DialogContext";
import { useModal } from "../../contexts/ModalContext";
import CallServiceTable from "../../components/Tables/CallServiceTable";
// import RenderLiquidaciónProforma from "../../containers/ManegeProcedure/RenderLiquidaciónProforma";
import { FillForm } from "../../containers/steps";
// import { validateArray } from "../../utils/validate";
// import TableLisDocument from "../../components/common/TableLisDocument";
import {
  headerList,
  filtersDefault,
  addFiltersDefault,
} from "../../utils/constant";
import ListDocument from "../../components/common/ListDocument";

const ProceduresConfirmationFunds = () => {
  const { rolId } = useParams();
  const { openModal, onClose } = useModal();
  const { openDialog, dialogClose } = useDialog();
  // const { openDialog } = useDialog();
  function handleProcedure(procedure, reloadTable) {
    openModal(
      <FillForm
        procedure={{
          ...procedure,
          // processStepId: rol === "accountant" ? 16 : 5,
          processStepId: 45,
        }}
        reload={reloadTable}
        onClose={onClose}
        openModal={openModal}
        rolId={rolId}
        // step={rol === "accountant" ? "4" : "3"}
        step={13}
        typeId="1"
      />
    );
  }

  // function generateSettlementProforma(procedure) {
  //   openDialog(<RenderLiquidaciónProforma procedure={procedure} />);
  // }

  function handleDocument(procedure) {
    openModal(
      <ListDocument
        addDocument={true}
        procedure={procedure}
        openModal={openModal}
        onClose={onClose}
        openDialog={openDialog}
        dialogClose={dialogClose}
      />
    );
  }


  return (
    <CallServiceTable
      addFilters={addFiltersDefault}
      urlApi="/procedure/openprocedures"
      filters={[
        {
          name: "procedureType",
          url: "/procedureType",
          default: "Todos los tramites",
          valueOption: "id",
          labelOption: "name",
        },
        ...filtersDefault,
      ]}
      header={[
        ...headerList,
        {
          name: "manage",
          type: "action",
          label: "Gestionar",
          sticky: true,
          actions: [
            {
              label: "Ver documentos",
              icon: "fa-solid fa-folder",
              action: handleDocument,
              color: "text-[#1d4ed8]",
            },
          ]
        }
        // {
        //   name: "Reports",
        //   type: "action",
        //   label: "Reportes",
        //   actions: [
        //     {
        //       label: "Generar informe de la recepción de documentos",
        //       icon: "fas fa-tasks",
        //       action: listDocumentPdf,
        //       color: "text-[#1d4ed8] pl-3",
        //     },
        //   ],
        // },
        // {
        //   name: "manage",
        //   type: "action",
        //   label: "Gestionar",
        //   sticky: true,
        //   actions: [
        //     {
        //       label: "Generar proforma",
        //       icon: "fas fa-coins",
        //       action: generateSettlementProforma,
        //       color: "text-[#1d4ed8]",
        //     },
        //     {
        //       label: "Generar liquidación",
        //       icon: "fas fa-money-bill",
        //       action: generateSettlement,
        //       color: "text-[#1d4ed8]",
        //     },
        //     {
        //       label: "Ver documentos",
        //       icon: "fa-solid fa-folder",
        //       action: handleDocument,
        //       color: "text-[#1d4ed8]",
        //     },
        //     {
        //       label: "Generar entrega de documentos",
        //       icon: "fas fa-file",
        //       action: generateFileDelivery,
        //       color: "text-[#1d4ed8]",
        //     },
        //     {
        //       label: "Generar hoja de ruta",
        //       icon: "fas fa-route",
        //       action: generateRoadmap,
        //       color: "text-[#1d4ed8] pr-5",
        //     },
        //     {
        //       label: "Editar valores",
        //       icon: "fas fa-th-list",
        //       action: editProcedure,
        //       color: "text-[#1d4ed8]",
        //     },
        //     {
        //       label: "Generar recibo",
        //       icon: "fas fa-receipt",
        //       action: generateReceipt,
        //       color: "text-[#1d4ed8] pr-5",
        //     },
        //     {
        //       label: "Generar recibo de transporte",
        //       icon: "fas fa-shuttle-van",
        //       action: generateTransportReceipt,
        //       color: "text-[#1d4ed8] pr-5",
        //     },
        //     {
        //       label: "Editar trámite",
        //       icon: "fas fa-edit",
        //       action: editCountryside,
        //       color: "text-[#1d4ed8]",
        //     },
        //     {
        //       label: "Eliminar tramite",
        //       icon: "fas fa-trash",
        //       action: handleDelete,
        //       color: "text-[#ff2d55]",
        //     },
        //   ],
        // },
      ]}
      onClick={handleProcedure}
      statusPaination={true}
    />
  );
};

export default ProceduresConfirmationFunds;
