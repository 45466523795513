import { toast } from "react-toastify";
import { Request } from "../../hooks/Request";
import { Texto, H3, H2 } from "../../components/Text";
import { Button } from "../../components/Buttons";
import { Circle } from "../../components/Animation";
import { useDialog } from "../../contexts/DialogContext";
import { DialogConfirmation } from "../../components/Modal";
import { requestAuth } from "../../components/services/RequestService";
import CallServiceTable from "../../components/Tables/CallServiceTable";

const ListInCharge = ({ company, reloadList }) => {
  const { openDialog, dialogClose } = useDialog();
  const {
    data,
    loading,
    call: reload,
  } = Request({
    urlApi: `/company/${company.id}/inChargeCompany`,
  });

  function call() {
    reload();
    if (reloadList) {
      reloadList();
    }
  }

  function createContact() {
    openDialog(
      <AddInCharge companyId={company.id} reload={call} onClose={dialogClose} />
    );
  }

  function deleteDropdown(user) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres quitarlo como encargado?</H3>}
        onClose={dialogClose}
        method="delete"
        url={`/company/${company.id}/userId/${user.id}/type/inCharge`}
        texBtn="Quitar"
        handleSuccess={call}
      />
    );
  }

  if (!loading) {
    return <Circle />;
  }
  return (
    <div className="bg-slate-400 p-[10px] rounded-md my-[15px] text-white flex flex-col shadow-xl">
      <div className="flex justify-between w-full mb-1">
        <Texto className="font-semibold text-black mr-3">
          Encargados del Cliente
        </Texto>
        <Button onClick={createContact} className="px-2 py-1">
          <i className="fas fa-plus"></i>
        </Button>
      </div>
      {data.map((item, index) => (
        <div key={index} className="flex gap-1 mb-1">
          <div className="bg-[#ffffff65] text-black px-2 py-0.5 rounded-md  w-full">
            <h3>
              Nombre: {item.name} {item.fatherLastName} {item.motherLastName}
            </h3>
            <h3>Correo: {item.email}</h3>
          </div>
          <Button
            onClick={() => deleteDropdown(item)}
            className="px-2 py-1 bg-[#eb4040]"
          >
            <i className="fas fa-trash"></i>
          </Button>
        </div>
      ))}
    </div>
  );
};

const AddInCharge = (props) => {
  const { companyId, reload, onClose } = props;

  async function handleAdd(user) {
    await requestAuth(
      "post",
      `/company/${companyId}/userId/${user.id}?rol=inCharge`
    )
      .then(() => {
        onClose();
        reload();
        toast.success("Se creo con éxito");
      })
      .catch((response) => {
        toast.error(
          response.response.data
            ? response.response.data
            : "Se produjo un error al crear"
        );
      });
  }

  return (
    <div>
      <H2>Selecciona un usuario</H2>
      <br />
      <CallServiceTable
        urlApi="/users/ListUsers"
        addFilters={[
          {
            name: "firstName",
            label: "Nombres",
            filter: true,
          },
        ]}
        header={[
          {
            name: "name",
            label: "Nombres",
            filter: false,
          },
          {
            name: "fatherLastName",
            label: "Apellido Paterno",
            filter: true,
          },
          {
            name: "motherLastName",
            label: "Apellido Materno",
            filter: true,
          },
          {
            name: "email",
            label: "Correo",
            filter: false,
          },
          // {
          //   name: "status",
          //   label: "Estado",
          //   filter: false,
          // },
          {
            name: "add",
            type: "action",
            label: "Adicionar como encargado",
            actions: [
              {
                label: "Adicionar como encargado",
                icon: "fas fa-plus-circle",
                action: handleAdd,
                color: "text-[#1d4ed8]",
              },
            ],
          },
        ]}
        addUrl={`userId=${localStorage.getItem("id")}`}
        filters={[
          {
            url: "/rol",
            name: "RolName",
            default: "Todos los roles",
          },
        ]}
      />
    </div>
  );
};

export default ListInCharge;
