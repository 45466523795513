import React from "react";
import { toast } from "react-toastify";
import { requestAuth } from "../../components/services/RequestService";
import FormDefault from "../Forms/FormDefault";

const FormFilesMultiple = ({ onClose, reload }) => {
  return (
    <>
      <FormDefault
        tittle={"Subir Archivos"}
        fields={[
          {
            label: "Archivos",
            name: "files",
            type: "file",
            typeInput: "fileMultiple",
          },
        ]}
        initialValues={{}}
        onSubmit={async (values) => {          
          let formData = new FormData();          
          for (let index = 0; index < values.files.length; index++) {
            formData.append("Files", values.files[index]);
          }          
          await requestAuth("post", "/Files/list", formData)
            .then(() => {
              onClose();
              reload();
              toast.success("Se guardo con éxito");
            })
            .catch((response) => {
              toast.error(
                response.response.data
                  ? response.response.data
                  : "Se produjo un error al guardar"
              );
            });
        }}
        buttonName={"Guardar"}
      />
      <br />
    </>
  );
};

export default FormFilesMultiple;
