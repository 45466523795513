// import './RenderPdf.css'
import * as dayjs from "dayjs";
import { Request } from "../../hooks/Request";
import logotipo from "../../assets/logo-logadex.png";
import imgqr from "../../assets/qr.png";
import logooea from "../../assets/oea.png";
import { Circle } from "../../components/Animation";
import View from "../../components/GeneratePdf/View";
import Text from "../../components/GeneratePdf/Text";
import { Page, Document, PDFViewer, Image } from "@react-pdf/renderer";


const PdfFactura = (props) => {

  const { procedureId } = props;

  const { data: dataFactura, loading } = Request({
    urlApi: `/expenses?procedureId=${procedureId}&type=Factura`,
  });

  const { data, loading: loadingData } = Request({
    urlApi: `/Logadex/procedure/${procedureId}/someData`,
  });

  if (!loading || !loadingData) {
    return <Circle />;
  }
  
  return (
    <RenderPdfFactura dataFactura={dataFactura} data={data}/>
  )
}

const RenderPdfFactura = (props) => {
  
  const { dataFactura, data } = props;


  let subTotal=0;
  let descuento=0;
  let total=0;
  let impGiftCard=0;
  let totalPagar=0;
  let baseCreFis=0;
  let totalLiteral="";


  if(dataFactura){
    for (const objeto of dataFactura) {
      subTotal += objeto.monto;
    }

  }

  subTotal = parseFloat(subTotal.toFixed(2))


  const { data: totalLiteralNumber, loading } =  Request({
    urlApi: `/utility/NumberToLiteral/${subTotal}`,
  });
  
  totalLiteral = totalLiteralNumber;
  
  
  total=subTotal
  totalPagar=subTotal
  baseCreFis=subTotal

  let subTotalFormat = subTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  let descuentoFormat = descuento.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  let totalFormat = total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  let impGiftCardFormat = impGiftCard.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  let totalPagarFormat = totalPagar.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  let baseCreFisFormat = baseCreFis.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });


  const numeros = require("numeros_a_palabras");

  numeros.numero2word().Config._setSingular("BOLIVIANO");
  numeros.numero2word().Config._setPlural("BOLIVIANOS");
  numeros.numero2word().Config._setCentsSingular("CENTAVO");
  numeros.numero2word().Config._setCentsPlural("CENTAVOS");


  if (!loading) {
    return <Circle />;
  }

  const MyDoc = (
    <Document>
      <Page size="A4" style={{ padding: "20px" }}>
        {/* <View borderContent> */}
        <View width="100%">
          <View
            width="50%"
            // border={{ right: true }}
            flexDirection="column"
            style={{gap: "5px"}}
            // alignItems="center"
          >
            <Image src={logotipo} style={{ width: '70px' }} />
            <Text 
              bold 
              fontSize="11px" 
              textAlign="center"
            >
              LOGADEX AGENCIA DESPACHANTE DE ADUANA S.R.L.{"\n"}
              CASA MATRIZ
            </Text>
            <Text 
              bold 
              color="#5B5B5B"
              fontSize="10px" 
              textAlign="center"
            >
              No. Punto de Venta 0{"\n"}
              AVENIDA JAIMES FREYRE NRO. 2354 EDIFICIO{"\n"}
              TORRE KARITO PISO 1 DPTO. OF 2 ZONA{"\n"}
              SOPOCACHI BAJO{"\n"}
              Teléfono: 22417290{"\n"}
              LA PAZ
            </Text>
          </View>

          <View width="50%" padding="15px" >
            <View flexDirection="column" style={{gap: "10px"}}>
              <View flexDirection="column" borderContent padding="5px">
                <View >
                  <Text 
                    bold 
                    // color="#0000A0"
                    fontSize="9px" 
                    // border={{ right: true }}
                    // textAlign="center"
                    minWidth="40%"
                    >
                    NIT:
                  </Text>
                  <Text 
                    fontSize="9px" 
                    // textAlign="center"
                    // color="#0000A0"
                    // border={{ right: true }}
                    >
                    374689028
                  </Text>
                </View>
                <View >
                  <Text 
                    bold 
                    // color="#0000A0"
                    fontSize="9px" 
                    // border={{ right: true }}
                    // textAlign="center"
                    minWidth="40%"
                    >
                    FACTURA N°:
                  </Text>
                  <Text 
                    // textAlign="center"
                    fontSize="9px" 
                    // color="#0000A0"
                    // border={{ right: true }}
                    >
                    {data?.num}
                  </Text>
                </View>
                <View >
                  <Text 
                    bold 
                    // color="#0000A0"
                    fontSize="9px" 
                    // border={{ right: true }}
                    // textAlign="center"
                    minWidth="40%"
                  >
                    AUTORIZACIÓN:
                  </Text>
                  <Text 
                    // textAlign="center"
                    // color="#0000A0"
                    fontSize="9px" 
                    // border={{ right: true }}
                    >
                    19A323B61FC181325F9C748{"\n"}
                    A2585AB829E6D0EADC11322{"\n"}
                    A45B19E8E74
                  </Text>
                </View>
              </View>
              
              <View flexDirection="column" width="100%">
                <Text 
                  bold 
                  // color="#0000A0"
                  fontSize="16px" 
                  // border={{ right: true }}
                  textAlign="center"
                  minWidth="100%"
                >
                  FACTURA
                </Text>
                <Text 
                  bold 
                  // color="#0000A0"
                  fontSize="12px" 
                  // border={{ right: true }}
                  textAlign="center"
                  minWidth="100%"
                >
                  (CON DERECHO A CREDITO FISCAL)
                </Text>
              </View>
            </View>
            
          </View>  
        </View>

        <View style={{marginTop: "10px"}}>
          <View 
            width="70%"
          >
            <Text 
              bold 
              // color="#0B4BCE"
              fontSize="10px" 
              // textAlign="center"
              minWidth="20%"
              >
              Fecha:
            </Text>
            <Text
              fontSize="10px" 
              color="#737373"
              // fontSize="12px"
            >
              {/* 23/23/2323 34:45:45 */}
              {data?.registerDate ? dayjs(data?.registerDate).format("DD/MM/YYYY HH:mm:ss") : "-"}
            </Text>
          </View>
          <View 
            width="30%"
          >
            <Text 
              bold 
              // color="#0B4BCE"
              fontSize="10px" 
              // textAlign="center"
              minWidth="15%"
            >
              NIT/CI/CEX:
            </Text>
            <Text
              fontSize="10px" 
              color="#737373"
              // fontSize="12px"
            >
              {data?.company.nit}
            </Text>
          </View>
        </View>

        <View>
          <View 
            width="70%"
          >
            <Text 
              bold 
              // color="#0B4BCE"
              fontSize="10px" 
              // textAlign="center"
              minWidth="20%"
              >
              Nombre/Razón Social:
            </Text>
            <Text
              fontSize="10px" 
              color="#737373"
              // fontSize="12px"
            >
              {data?.company.razonSocial}
            </Text>
          </View>
          <View 
            width="30%"
          >
            <Text 
              bold 
              // color="#0B4BCE"
              fontSize="10px" 
              // textAlign="center"
              minWidth="15%"
            >
              Código cliente:
            </Text>
            <Text
              fontSize="10px" 
              color="#737373"
              // fontSize="12px"
            >
              {data?.company.code}
            </Text>
          </View>
        </View>

        {/* EXTRA */}

        {/* <View style={{marginTop: "10px"}}>
          <View 
            width="100%"
          >
            <Text 
              bold 
              // color="#0B4BCE"
              fontSize="10px" 
              // textAlign="center"
              // minWidth="10%"
              >
              Ref.:
            </Text>
            <Text
              fontSize="10px" 
              color="#737373"
              // fontSize="12px"
            >
              FACT F001-0061818 Mer.: BOLSA DE POLIETILENO
            </Text>
          </View>
        </View> */}

        {/* <View>
          <View 
            width="20%"
          >
            <Text 
              bold 
              // color="#0B4BCE"
              fontSize="10px" 
              // textAlign="center"
              // minWidth="10%"
              >
              Carpeta:
            </Text>
            <Text
              fontSize="10px" 
              color="#737373"
              // fontSize="12px"
            >
              3453453
            </Text>
          </View>
          <View 
            width="40%"
          >
            <Text 
              bold 
              // color="#0B4BCE"
              fontSize="10px" 
              // textAlign="center"
              // minWidth="10%"
            >
              DIM:
            </Text>
            <Text
              fontSize="10px" 
              color="#737373"
              // fontSize="12px"
            >
              DI-DSF3534543
            </Text>
          </View>
          <View 
            width="20%"
          >
            <Text 
              bold 
              // color="#0B4BCE"
              fontSize="10px" 
              // textAlign="center"
              // minWidth="10%"
            >
              FOB[Us]:
            </Text>
            <Text
              fontSize="10px" 
              color="#737373"
              // fontSize="12px"
            >
              721sdf,45
            </Text>
          </View>
          <View 
            width="20%"
          >
            <Text 
              bold 
              // color="#0B4BCE"
              fontSize="10px" 
              // textAlign="center"
              // minWidth="10%"
            >
              CIF[Bs]:
            </Text>
            <Text
              fontSize="10px" 
              color="#737373"
              // fontSize="12px"
            >
              721sdf,45
            </Text>
          </View>
        </View> */}

        {/* tabla */}

        {/* FILAS */}

        <View style={{marginTop: "5px"}} flexDirection="column">

          {/* HEADER */}
          <View
            style={{backgroundColor: "#D4D4D4" }}
            // style={{ marginTop: "10px" }}
            borderContent
          >
            <View 
              width="15%"
              justifyContent="center"
              alignItems="center"
              border={{right: true}}
            >
              <Text 
                bold
                textAlign="center"
                fontSize="11px"
              >
                Código{"\n"}Producto{"\n"}Servicio
              </Text>
            </View>

            <View 
              width="55%"
              justifyContent="center"
              alignItems="center"
              border={{right: true}}
            >
              <Text 
                bold
                textAlign="center"
                fontSize="11px"
              >
                Descripción
              </Text>
            </View>

            <View 
              width="15%"
              justifyContent="center"
              alignItems="center"
              border={{right: true}}
            >
              <Text 
                bold
                textAlign="center"
                fontSize="11px"
              >
                Precio{"\n"}Unitario
              </Text>
            </View>

            <View 
              width="15%"
              justifyContent="center"
              alignItems="center"
            >
              <Text 
                bold
                textAlign="center"
                fontSize="11px"
              >
                Subtotal
              </Text>
            </View>

          </View>

          {/* CONTENIDO */}

          {
            dataFactura?.map((item) => (
              <View key={item.id}
                border={{right: true, left: true, bottom: true}}
              >
                <View 
                  width="15%"
                  justifyContent="center"
                  alignItems="center"
                  border={{right: true}}
                >
                  <Text 
                    bold
                    textAlign="center"
                    fontSize="9px"
                    color="#343739"
                  >
                    {item.sigla}
                  </Text>
                </View>

                <View 
                  width="55%"
                  justifyContent="flex-start"
                  alignItems="center"
                  border={{right: true}}
                >
                  <Text 
                    bold
                    textAlign="center"
                    fontSize="9px"
                    color="#343739"
                  >
                    {item.concepto}
                  </Text>
                </View>

                <View 
                  width="15%"
                  justifyContent="flex-end"
                  alignItems="center"
                  border={{right: true}}
                >
                  <Text 
                    bold
                    textAlign="center"
                    fontSize="9px"
                    color="#343739"
                  >
                    {item.monto.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </Text>
                </View>

                <View 
                  width="15%"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Text 
                    bold
                    textAlign="center"
                    fontSize="9px"
                    color="#343739"
                  >
                    {item.monto.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </Text>
                </View>

              </View>
            ))
          }
          
          


          {/* FOOTER */}

          <View
            // border={{right: true, left: true, bottom: true}}
          >
            <View 
              width="55%"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Text 
                bold
                textAlign="center"
                fontSize="9px"
              >
                SON: {" "}
                {
                  // numeros.numero2word(subTotal).toString()
                  totalLiteral.toUpperCase()
                }
              </Text>
            </View>

            <View 
              width="25%"
              justifyContent="flex-start"
              alignItems="center"
              border={{right: true, left: true, bottom: true}}
            >
              <Text 
                bold
                textAlign="center"
                fontSize="9px"
              >
                SUBTOTAL Bs
              </Text>
            </View>

            <View 
              width="20%"
              justifyContent="flex-end"
              alignItems="center"
              border={{right: true, bottom: true}}
            >
              <Text 
                bold
                textAlign="center"
                fontSize="9px"
              >
                {subTotalFormat}
              </Text>
            </View>

          </View>

          <View
            // border={{right: true, left: true, bottom: true}}
          >
            <View 
              width="55%"
            ></View>

            <View 
              width="25%"
              justifyContent="flex-start"
              alignItems="center"
              border={{right: true, left: true, bottom: true}}
            >
              <Text 
                bold
                textAlign="center"
                fontSize="9px"
              >
                DESCUENTO Bs
              </Text>
            </View>

            <View 
              width="20%"
              justifyContent="flex-end"
              alignItems="center"
              border={{right: true, bottom: true}}
            >
              <Text 
                textAlign="center"
                fontSize="9px"
              >
                {descuentoFormat}
              </Text>
            </View>

          </View>

          <View
            // border={{right: true, left: true, bottom: true}}
          >
            <View 
              width="55%"
            ></View>

            <View 
              width="25%"
              justifyContent="flex-start"
              alignItems="center"
              border={{right: true, left: true, bottom: true}}
            >
              <Text 
                bold
                textAlign="center"
                fontSize="9px"
              >
                TOTAL Bs
              </Text>
            </View>

            <View 
              width="20%"
              justifyContent="flex-end"
              alignItems="center"
              border={{right: true, bottom: true}}
            >
              <Text 
                bold
                textAlign="center"
                fontSize="9px"
              >
                {totalFormat}
              </Text>
            </View>

          </View>

          <View
            // border={{right: true, left: true, bottom: true}}
          >
            <View 
              width="55%"
            ></View>

            <View 
              width="25%"
              justifyContent="flex-start"
              alignItems="center"
              border={{right: true, left: true, bottom: true}}
            >
              <Text 
                bold
                textAlign="center"
                fontSize="9px"
              >
                IMPORTE GIFT CARD Bs
              </Text>
            </View>

            <View 
              width="20%"
              justifyContent="flex-end"
              alignItems="center"
              border={{right: true, bottom: true}}
            >
              <Text 
                bold
                textAlign="center"
                fontSize="9px"
              >
                {impGiftCardFormat}
              </Text>
            </View>

          </View>

          <View
            // border={{right: true, left: true, bottom: true}}
          >
            <View 
              width="55%"
            ></View>

            <View 
              width="25%"
              justifyContent="flex-start"
              alignItems="center"
              border={{right: true, left: true, bottom: true}}
            >
              <Text 
                bold
                textAlign="center"
                fontSize="9px"
              >
                TOTAL A PAGAR Bs 
              </Text>
            </View>

            <View 
              width="20%"
              justifyContent="flex-end"
              alignItems="center"
              border={{right: true, bottom: true}}
            >
              <Text 
                bold
                textAlign="center"
                fontSize="9px"
              >
                {totalPagarFormat}
              </Text>
            </View>

          </View>

          <View
            // border={{right: true, left: true, bottom: true}}
          >
            <View 
              width="55%"
            ></View>

            <View 
              width="25%"
              justifyContent="flex-start"
              alignItems="center"
              border={{right: true, left: true, bottom: true}}
            >
              <Text 
                bold
                textAlign="center"
                fontSize="9px"
              >
                BASE CRÉDITO FISCAL Bs
              </Text>
            </View>

            <View 
              width="20%"
              justifyContent="flex-end"
              alignItems="center"
              border={{right: true, bottom: true}}
            >
              <Text 
                bold
                textAlign="center"
                fontSize="9px"
              >
                {baseCreFisFormat}
              </Text>
            </View>

          </View>


        </View>

        {/* FIN TABLA */}

        <View style={{marginTop: "25px", marginLeft: "15px", marginRight: "15px"}} flexDirection="column">
          <View
            // style={{backgroundColor: "#D4D4D4" }}
            // style={{ marginTop: "10px" }}
            // borderContent
          >
            <View 
              width="80%"
              justifyContent="center"
              alignItems="center"
              // border={{right: true}}
              flexDirection="column"
            >
              <Text 
                bold
                textAlign="center"
                fontSize="11px"
                color="#000000"
              >
                ESTA FACTURA CONTRIBUYE AL DESARROLLO DEL PAÍS, EL USO{"\n"}
                ILÍCITO SERÁ SANCIONADO PENALMENTE DE ACUERDO A LEY.
              </Text>
              <Text 
                bold
                textAlign="center"
                fontSize="9px"
                color="#000000"
              >
                Ley N° 453: Tienes derecho a un trato equitativo sin discriminación en la oferta de servicios.{"\n"}
                "Este documento es la Representación Gráfica de un Documento Fiscal Digital emitido en una modalidad de facturación en línea"
              </Text>
            </View>

            <View 
              width="20%"
              justifyContent="center"
              alignItems="center"
              // border={{right: true}}
            >
              <Image src={imgqr} style={{ width: '100px' }} />
            </View>

          </View>
        </View>

        <View style={{marginTop: "15px", marginLeft: "15px", marginRight: "15px"}} flexDirection="column">
          <View
            // style={{backgroundColor: "#D4D4D4" }}
            // style={{ marginTop: "10px" }}
            // borderContent
          >

            <View 
              width="10%"
              justifyContent="center"
              alignItems="center"
              // border={{right: true}}
            >
              <Image src={logooea} style={{ width: '100px' }} />
            </View>

            <View 
              width="90%"
              justifyContent="center"
              alignItems="center"
              // border={{right: true}}
              flexDirection="column"
            >
              <Text 
                bold
                textAlign="center"
                fontSize="11px"
                color="#00823D"
              >
                CASA MATRIZ: AVENIDA JAIMES FREYRE NRO.2354 EDIFICIO
                TORRE KARITO PISO 1{"\n"}DPTO. OF 2 ZONA
                SOPOCACHI BAJO - Teléfono: 22417290, LA PAZ  
              </Text>
            </View>

          </View>
        </View>

        
        

      </Page>
    </Document>
  );

  return (
    <div>
      {/* <BlobProvider document={MyDoc}>
        {({ blob }) => {
          return <div onClick={() => uploadFile(blob)}>Subir documento</div>
          }}
      </BlobProvider> */}
      <PDFViewer
        style={{
          width: 900,
          height: window.innerHeight - 100,
        }}
        children={MyDoc}
      />
    </div>
  );
};



export default PdfFactura;
