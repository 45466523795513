import * as Yup from "yup";
import { toast } from "react-toastify";
import FormDefault from "../../components/Forms/FormDefault";
import { Button } from "../../components/Buttons";
import { Circle } from "../../components/Animation";
import { H2 } from "../../components/Text";
import { validateArray } from "../../utils/validate";
import { requestAuth } from "../../components/services/RequestService";
import ListInCharge from "../ModalsCompany/ListInCharge";
import { useState } from "react";
import { Request } from "../../hooks/Request";

const NewProcedure = (props) => {
  const { reload, onClose, openModal } = props;

  function handleInCharge(values) {
    openModal(<ManageInCharge values={values} {...props} />);
  }

  return (
    <div>
      <FormDefault
        tittle="Aperturar nueva carpeta"
        fields={[
          {
            label: "Compañia",
            name: "CompanyId",
            typeInput: "select",
            urlApi: "/company/getallcompanies",
            value: "id",
            labelOption: "razonSocial",
          },
          {
            label: "Tipo de procedimiento",
            name: "ProcedureTypeId",
            typeInput: "select",
            urlApi: "/procedureType",
            value: "id",
            labelOption: "name",
          },
          {
            label: "Sucursal",
            name: "city",
            typeInput: "select",
            options: [
              {
                label: "La Paz",
                value: "LP",
              },
              {
                label: "Santa Cruz",
                value: "SC",
              },
            ],
          },
        ]}
        initialValues={{
          CompanyId: "",
          ProcedureTypeId: 1,
          city: "LP",
        }}
        validationSchema={Yup.object().shape({
          CompanyId: Yup.string().required("Este campo es obligatorio"),
          ProcedureTypeId: Yup.string().required("Este campo es obligatorio"),
        })}
        onSubmit={handleInCharge}
        buttonName="Guardar"
      />
    </div>
  );
};

const ManageInCharge = (props) => {
  const { reload, onClose, values } = props;
  const { data, loading, call } = Request({
    urlApi: `/company/${values.CompanyId}/inChargeCompany`,
  });
  const [load, setload] = useState(false);
  async function handleRegister() {
    setload(true);
    await requestAuth("post", `/procedure`, {
      ...values,
      city: values.city === "LP" ? "" : values.city,
    })
      .then(() => {
        setload(false);
        reload();
        onClose();
        toast.success("Trámite agregado correctamente!!!");
      })
      .catch(() => {
        setload(false);
        toast.error("Se ha producido un error al crear el Proceso");
      });
  }
  return (
    <div>
      <H2>Encargados del Cliente</H2>
      <ListInCharge company={{ id: values.CompanyId }} reloadList={call} />
      {load && loading ? (
        <Circle />
      ) : (
        <Button
          onClick={handleRegister}
          disabled={!validateArray(data)}
          className={`py-4 w-full`}
        >
          Registrar
        </Button>
      )}
    </div>
  );
};

export default NewProcedure;
