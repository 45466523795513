import { useState, useEffect } from "react";
import { requestAuth } from "../components/services/RequestService";

export const Request = (props) => {
  const [data, setData] = useState();
  const { urlApi, method = "GET", dataRequest = {} } = props;

  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState("");
  const [buttons, setButtons] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    Api();
  }, []);

  function call() {
    Api();
    setLoading(false);
  }

  function Api() {
    if (urlApi !== null) {
      requestAuth(method, urlApi, dataRequest)
        .then(function (response) {
          setData(response.data);
          setPagination(response.headers.pagination);
          setButtons(response.headers.buttons);
          setError(false);
        })
        .catch(function (error) {
          setError(true);
          setData();
        })
        .finally(function (res) {
          setLoading(true);
        });
    } else {
      setData(null);
      setError(true);
      setLoading(true);
    }
  }

  return { loading, data, pagination, call, error, setData, buttons };
};
