import { useState } from "react";
import { H3, Label } from "../Text";
import { toast } from "react-toastify";
import Circle from "../Animation/Circle";
import { Button } from "../Buttons";
import { Request } from "../../hooks/Request";
import TableComplete from "../Tables/TableComplete";
import { requestAuth } from "../services/RequestService";
import { useDialog } from "../../contexts/DialogContext";
import { DialogConfirmation } from "../Modal";
import CreatableSelect from "react-select/creatable";
import { validateArray } from "../../utils/validate";

const InputSubData = ({ field }) => {
  const { openDialog, dialogClose } = useDialog();

  const { data, loading, call } = Request({
    urlApi: `/dataSet/procedure/${field.id}/field/${field.fieldId}/subdata`,
  });
  const {
    data: document,
    loading: loadDocument,
    call: callDocument,
  } = Request({
    urlApi: field.urlApi,
  });

  function handleForm(item) {
    openDialog(
      <>
        {loadDocument && (
          <FormAddDocument
            data={document}
            item={item}
            reload={call}
            field={field}
            onClose={dialogClose}
            callDocument={callDocument}
          />
        )}
        <br />
      </>
    );
  }

  function handleDelete(data) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres eliminar este item?</H3>}
        onClose={dialogClose}
        method="delete"
        url={`/dataSet/procedure/subData/${data.id}`}
        texBtn="Eliminar"
        handleSuccess={call}
      />
    );
  }

  return (
    <div className="bg-gray-300/40 p-2 rounded-md">
      <Label htmlFor={field.name} className={`${field.className} my-0`}>
        {field.labelCustom ? field.labelCustom : field.label}
      </Label>
      <div className="bg-gray-100 rounded-lg p-3 pt-2">
        {loadDocument && (
          <FormAddDocument
            data={document}
            reload={call}
            field={field}
            onClose={() => { }}
            callDocument={callDocument}
          />
        )}
      </div>
      <br />
      {!loading ? (
        <Circle />
      ) : (
        <div className="w-full">
          <TableComplete
            header={[
              {
                name: "label",
                label: "Campo",
              },
              {
                name: "description",
                label: "Observación",
              },
              {
                name: "creationDate",
                label: "Fecha / hora",
                type: "date",
              },
              {
                name: "add",
                type: "action",
                label: "Opciones",
                sticky: true,
                actions: [
                  {
                    label: "Editar",
                    icon: "fas  fa-edit",
                    action: handleForm,
                    color: "text-[#1d4ed8]",
                  },
                  {
                    label: "Añadir",
                    icon: "fas fa-trash",
                    action: handleDelete,
                    color: "text-red-500",
                  },
                ],
              },
            ]}
            data={data}
          />
        </div>
      )}
    </div>
  );
};

const FormAddDocument = (props) => {
  const { data, item = null, reload, field, onClose, callDocument } = props;
  const [values, setValues] = useState({
    Label: item?.label ? item.label : validateArray(data) ? data[0].name : "",
    NumberValue: item?.numberValue ? item.numberValue : 0,
    Description: item?.description ? item.description : "",
    isNew: false,
  });

  async function handleSubmit() {
    if (values.isNew) {
      await handleCreateOption(values);
    }
    await requestAuth(
      item ? "put" : "post",
      item
        ? `/dataSet/procedure/subData/${item.id}`
        : `/dataSet/procedure/${field.id}/field/${field.fieldId}/subdata`,
      values
    )
      .then(() => {
        reload();
        onClose();
        setValues({
          ...values,
          NumberValue: 0,
          Description: "",
        });
        toast.success("Registro exitoso");
      })
      .catch(() => {
        toast.error("Se produjo un error al guardar");
      });
  }

  async function handleCreateOption(value) {
    await requestAuth("post", "/Dropdown/Option", {
      Name: value.Label,
      DropdownListLabel: field.urlInitial,
    }).then(() => {
      callDocument();
    });
  }

  function handleChangeInp(e) {
    const { name, value } = e.target;
    setValues((a) => ({
      ...a,
      [name]: value,
    }));
  }
  function handleChange(e) {
    setValues((a) => ({
      ...a,
      Label: e.value,
      isNew: e.__isNew__ || false,
    }));
  }

  return (
    <form className="flex flex-col">
      <Label className={`mt-2`}>Lista de campos</Label>
      <CreatableSelect
        isSearchable={true}
        onChange={handleChange}
        styles={{
          placeholder: (base) => ({
            ...base,
            color: "black",
          }),
        }}
        // defaultInputValue={values[field.name]}
        placeholder={"Seleccionar..."}
        isOptionSelected={false}
        // isLoading={field.urlApi ? !loading : false}
        options={data.map((option) => ({
          value: option.name,
          label: option.name,
        }))}
      />
      <Label className={`my-0 mt-4`}>Observación</Label>
      <div className="flex gap-2">
        <input
          value={values.Description}
          onChange={handleChangeInp}
          className="w-full p-2 rounded-md"
          type="text"
          name="Description"
        />
        <Button onClick={handleSubmit} className="px-3 py-0">
          Guardar
        </Button>
      </div>
    </form>
  );
};

export default InputSubData;
