// import { useEffect } from "react";
import { Page, Document, PDFViewer, Image } from "@react-pdf/renderer";
import * as dayjs from "dayjs";
import { Request } from "../../hooks/Request";
import { Circle } from "../../components/Animation";
import View from "../../components/GeneratePdf/View";
import Text from "../../components/GeneratePdf/Text";
import CustomTablePDF from "../../components/GeneratePdf/CustomTablePDF";
import logotipo from "../../assets/LogotipoTC.png";
import Logadex from "../../assets/Logadex.jpg"
import qrLogadex from "../../assets/qrLogadex.png"

const PdfProform = (props) => {
  const { procedure } = props;
  const { data, loading } = Request({
    urlApi: `/procedure/${procedure.id || procedure.procedureId}/rvd`,
  });

  // useEffect(() => {
  //   if (loading) {
  //     handleOnClick();
  //   }
  // }, [loading]);

  if (!loading) {
    return <Circle />;
  }

  const MyDoc = (
    <Document>
      <Page size="A4" style={{ padding: "20px" }}>
        <View justifyContent="space-between">
          <Image src={Logadex} style={{ width: "150px", paddingLeft: "20px" }} />
          <Text textAlign="center" fontSize="10px" bold>
            LA PAZ 14 DE FEBRERO DE 2024
          </Text>
        </View>

        <View
          flexDirection="column"
          justifyContent="center "
          style={{ marginBottom: "10px" }}>
          <Text

            textAlign="center"
            fontSize="16px"
            bold>
            "LOGADEX"
          </Text>
          <Text

            textAlign="center"
            fontSize="16px"
            bold>
            AGENCIA  DESPACHANTE  DE  ADUANAS  S.R.L.
          </Text>
        </View>

        <View>
          <View width="30%"></View>
          <View
            width="40%"
            alignItems="center"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Text fontSize="16px" padding="1px 3px" bold>
              LIQUIDACION - PROFORMA
            </Text>
            <View width="95%" border={{ top: true }}></View>
            <View width="95%" border={{ top: true }}></View>
          </View>
        </View>


        <Text
          style={{ marginTop: "10px" }}
          fontSize="11px"
          bold
        >
          CONSIGNATARIO:
        </Text>
        <Text
          style={{ margin: "0px" }}
          fontSize="11px"
          bold
        >
          ATENCION:
        </Text>
        <Text
          style={{ marginBottom: "10px" }}
          fontSize="11px"
          bold
        >
          IMPORTACION N°:
        </Text>
        <View border={{ top: true }} ></View>
        <View border={{ top: true }} ></View>
        <View border={{ top: true }}>
          <View width="30%" flexDirection="column">
            <Text
              style={{ margin: "0px" }}
              fontSize="11px"
              padding="2px 3px"
              bold>
              VALOR FOB
            </Text>
            <Text
              style={{ margin: "0px" }}
              fontSize="11px"
              padding="2px 3px"
              bold>
              FLETE MARITIMO
            </Text>
            <Text
              style={{ margin: "0px" }}
              fontSize="11px"
              padding="2px 3px"
              bold>
              FLETE TERRESTRE
            </Text>
            <Text
              style={{ margin: "0px" }}
              fontSize="11px"
              padding="2px 3px"
              bold>
              ASP-B/ITI/OTRO
            </Text>
            <Text
              style={{ margin: "0px" }}
              fontSize="11px"
              padding="2px 3px"
              bold>
              SEGURO
            </Text>
            <Text
              style={{ margin: "0px" }}
              fontSize="11px"
              padding="2px 3px"
              bold>
              OTROS GASTOS
            </Text>
            <Text
              style={{ margin: "0px" }}
              fontSize="11px"
              padding="2px 3px"
              bold>
              VALOR CIF ADUANA
            </Text>
            <Text
              style={{ margin: "0px" }}
              fontSize="11px"
              padding="2px 3px"
              bold>
              BASE IMPONIBLE IVA
            </Text>
          </View>
          <View width="30%" flexDirection="column">
            <Text fontSize="11px" padding="2px 3px">$us.</Text>
            <Text fontSize="11px" padding="2px 3px">$us.</Text>
            <Text fontSize="11px" padding="2px 3px">$us.</Text>
            <Text fontSize="11px" padding="2px 3px">$us.</Text>
            <Text fontSize="11px" padding="2px 3px">$us.</Text>
            <Text fontSize="11px" padding="2px 3px">$us.</Text>
            <Text fontSize="11px" padding="2px 3px">$us.</Text>
            <Text fontSize="11px" padding="2px 3px">$us.</Text>
          </View>
          <View width="30%" flexDirection="column">
            <Text fontSize="11px" padding="2px 3px"></Text>
            <Text fontSize="11px" padding="2px 3px"></Text>
            <Text fontSize="11px" padding="2px 3px"></Text>
            <Text fontSize="11px" padding="2px 3px"></Text>
            <Text fontSize="11px" padding="2px 3px"></Text>
            <Text fontSize="11px" padding="2px 3px"></Text>
            <Text fontSize="11px" padding="2px 3px">Bs.</Text>
            <Text fontSize="11px" padding="2px 3px">Bs.</Text>
          </View>
          <View
            width="10%"
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Text fontSize="11px" padding="2px 3px">{data.valorFob}</Text>
            <Text fontSize="11px" padding="2px 3px">{data.flete1y2}</Text>
            <Text fontSize="11px" padding="2px 3px">{data.seguro}</Text>
            <Text fontSize="11px" padding="2px 3px">2,0%</Text>
            <Text fontSize="11px" padding="2px 3px">{data.valorCif}</Text>
            <Text fontSize="11px" padding="2px 3px">0</Text>
            <Text fontSize="11px" padding="2px 3px">0</Text>
          </View>
        </View>

        <View border={{ top: true }} style={{ marginBottom: "1px" }}></View>
        <View border={{ top: true }} >
          <Text
            style={{ marginBottom: "10px" }}
            fontSize="11px"

            padding="2px 3px"
            bold>
            DERECHOS Y TRIBUTOS DE IMPORTACION
          </Text>
        </View>

        <View >
          <View width="30%" flexDirection="column">

            <Text
              style={{ margin: "0px" }}
              fontSize="11px"
              padding="2px 3px"
              bold>
              G.A.
            </Text>
            <Text
              style={{ margin: "0px" }}
              fontSize="11px"
              padding="2px 3px"
              bold>
              I.V.A.
            </Text>
            <Text
              style={{ margin: "0px" }}
              fontSize="11px"
              padding="2px 3px"
              bold>
              DIM
            </Text>

          </View>
          <View width="30%" flexDirection="column">
            <Text fontSize="11px" padding="2px 3px">Bs.</Text>
            <Text fontSize="11px" padding="2px 3px">Bs.</Text>
            <Text fontSize="11px" padding="2px 3px">Bs.</Text>
          </View>
          <View width="30%" flexDirection="column">
            <Text fontSize="11px" padding="2px 3px"></Text>
            <Text fontSize="11px" padding="2px 3px"></Text>
            <Text fontSize="11px" padding="2px 3px"></Text>
          </View>
          <View
            width="10%"
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Text fontSize="11px" padding="2px 3px">5%</Text>
            <Text fontSize="11px" padding="2px 3px">14,94%</Text>
            <Text fontSize="11px" padding="2px 3px"></Text>
          </View>

        </View>

        <View>
          <View width="31%" flexDirection="column">
            <Text
              style={{ margin: "0px" }}
              fontSize="11px"
              padding="2px 3px"
              bold>
              ALAMACENAJE APROX
            </Text>

          </View>
          <View width="3%" flexDirection="column">
            <Text fontSize="11px" padding="2px 3px">Bs.</Text>
          </View>
          <View width="40%" flexDirection="column">
            <Text fontSize="11px" padding="2px 3px"></Text>
          </View>
          <View
            width="30%"
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Text fontSize="11px" padding="2px 3px">APROX. POR CONFIRMAR</Text>
          </View>
        </View>

        <View border={{ bottom: true }}>
          <View width="30%" flexDirection="column">
            <Text
              style={{ margin: "0px" }}
              fontSize="11px"
              padding="2px 3px"
              bold>
              GASTOS DE DESPACHO
            </Text>
            <Text
              style={{ margin: "0px" }}
              fontSize="11px"
              padding="2px 3px"
              bold>
              HONORARIO AGENCIA
            </Text>

          </View>
          <View width="30%" flexDirection="column">
            <Text fontSize="11px" padding="2px 3px">Bs.</Text>
            <Text fontSize="11px" padding="2px 3px">Bs</Text>
          </View>
          <View width="30%" flexDirection="column">
            <Text fontSize="11px" padding="2px 3px"></Text>
            <Text fontSize="11px" padding="2px 3px"></Text>
          </View>
          <View
            width="10%"
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Text fontSize="11px" padding="2px 3px"></Text>
            <Text fontSize="11px" padding="2px 3px"></Text>
          </View>
        </View>

        <View>
          <View width="30%" flexDirection="column">
            <Text
              style={{ marginBottom: "10px" }}
              fontSize="11px"

              padding="2px 3px"
              bold>
              TOTAL
            </Text>
          </View>
          <View width="30%" flexDirection="column">
            <Text fontSize="11px" padding="2px 3px">Bs.</Text>
          </View>
          <View width="30%" flexDirection="column">
            <Text fontSize="11px" padding="2px 3px">0</Text>
          </View>
        </View>

        <View>
          <View width="30%" flexDirection="column">
            <Text
              style={{ marginBottom: "10px" }}
              fontSize="11px"

              padding="2px 3px"
              bold>
              TRIBUTOS FISCALES
            </Text>
          </View>
          <View width="30%" flexDirection="column">
            <Text fontSize="11px" padding="2px 3px">Bs.</Text>
          </View>
          <View width="30%" flexDirection="column">
            <Text fontSize="11px" padding="2px 3px">0</Text>
          </View>
        </View>


        <View
          borderContent
          flexDirection="column"
          padding="5px"
          border={{ top: true, bottom: true, right: true, left: true }}
          style={{ marginTop: "10px" }}
        >
          <Text
            textAlign="center"
            fontSize="8px"
            bold
          >
            Corriente Nº 1-00000 29902140 del Banco Unión S.A. en Bs., a nombre de "LOGADEX" AGENCIA DESPACHANTE DE ADUANA S.R.L.
          </Text>
        </View>

        <View 
        width="17%" flexDirection="column"
        justifyContent="flex-end"
            alignItems="flex-end"
        >
          <Image src={qrLogadex} style={{ width: "70px", marginTop: "10px" }} />
        </View>

        <View
          flexDirection="column"
          padding="10px"
          style={{ marginTop: "30px" }}>
          <Text
            color="#15bb6e"
            textAlign="center"
            fontSize="8px"
            bold>
            Av. Jaimes Freyre No. 2354 Edificio “Torre Karito” PIso 1 Of. 2 Zona Sopocachi
          </Text>
          <Text
            color="#15bb6e"
            textAlign="center"
            fontSize="8px"
            bold>
            Telf.: (591)2417290 -  (591) 71551797 - (591) 72501500
          </Text>
        </View>
      </Page>
    </Document>
  );

  // async function handleOnClick() {
  //   await pdf(MyDoc)
  //     .toBlob()
  //     .then((r) => {
  //       var file = new File([r], `Proforma-${dayjs().format("DD-MM-YYYY")}`);
  //       uploadFile(file);
  //     });
  // }

  return (
    <div>
      {/* <BlobProvider document={MyDoc}>
        {({ blob }) => {
          return <div onClick={() => uploadFile(blob)}>Subir documento</div>
        }}
      </BlobProvider> */}
      <PDFViewer
        style={{
          width: 900,
          height: window.innerHeight - 100,
        }}
        children={MyDoc}
      />
    </div>
  );
};

export default PdfProform;
