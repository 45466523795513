import { Button } from "../../../components/Buttons";
import { headerList } from "../../../utils/constant";
import { NewProcedure } from "../../../containers/steps";
import { useModal } from "../../../contexts/ModalContext";
import { useNavigate, useParams } from "react-router-dom";
import CallServiceTable from "../../../components/Tables/CallServiceTable";

const FolderList = () => {
  const { rolId } = useParams();
  const navigate = useNavigate();
  const { openModal, onClose } = useModal();

  function handleAdd(reload) {
    openModal(<NewProcedure reload={reload} onClose={onClose} />);
  }

  function handleFolderStep(procedure) {
    navigate(`/rol/${rolId}/menu/folderList/step-procedure/${procedure.id}`);
  }

  return (
    <CallServiceTable
      component={({ reload }) => {
        return (
          <Button onClick={() => handleAdd(reload)}>
            Agregar nuevo trámite
          </Button>
        );
      }}
      onClick={handleFolderStep}
      filters={[
        {
          name: "procedureType",
          url: "/procedureType",
          default: "Todos los tramites",
          valueOption: "id",
          labelOption: "name",
        },
        {
          name: "companyId",
          url: "/company/getallcompanies",
          default: "Todas las compañías",
          valueOption: "id",
          labelOption: "razonSocial",
        },
        {
          name: "Year",
          default: "",
          label: "Fecha de inicio",
          options: [
            {
              label: "2024",
              name: "2024",
              initial: true,
            },
            {
              label: "2023",
              name: "2023",
            },
            {
              label: "2022",
              name: "2022",
            },
            {
              label: "2021",
              name: "2021",
            },
          ],
        },
        //   {
        //     // url: '/company/getallcompanies',
        //     // name: 'companyId',
        //     // valueOption: 'id',
        //     // labelOption: 'razonSocial',
        //     type: "rangeDate",
        //   },
      ]}
      urlApi="/procedure/openprocedures"
      addFilters={[
        {
          name: "procedureNumber",
          label: "Tramite",
          filter: true,
        },
        {
          name: "internCode",
          label: "Nro. interno",
          filter: true,
        },
        {
          name: "dimNumber",
          label: "Nro. DIM/DEX",
          filter: true,
        },
      ]}
      header={headerList}
    />
  );
};

export default FolderList;
