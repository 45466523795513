import { useEffect, useState } from "react";
import Select from "react-select";
import { ChartContainer } from "../../components/Chart";
import { filtersDefaultChart } from "../../utils/constant";
import { useDialog } from "../../contexts/DialogContext";
import { requestAuth } from "../../components/services/RequestService";
import { SimpleTableManagement } from "../../containers/Systems/TableManagement";
import { Circle } from "../../components/Animation";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Management = () => {
  return (
    <div>
      <ManagementContainer />
      <ManagementContainer />
    </div>
  );
};

const ManagementContainer = () => {
  const [year, setYear] = useState("2024");
  const [chartType, setChartType] = useState("Clientes");
  const { openDialog } = useDialog();
  const navigate = useNavigate()
  const location = useLocation()

  const graficas = [
    { value: "Clientes", label: "Clientes" },
    { value: "Liquidadores", label: "Liquidadores" },
    { value: "Gestores", label: "Gestores" },
    { value: "Comisiones", label: "Comisiones" },
    { value: "Problemas Del Despacho", label: "Problemas Del Despacho" },
    { value: "Observaciones Aforo", label: "Observaciones Aforo" },
    { value: "Canales", label: "Canales" },
    { value: "Mercadería", label: "Mercadería" },
    { value: "Aduanas De Despachos", label: "Aduanas De Despachos" },
    { value: "Examen Previo", label: "Examen Previo" },
    { value: "Multas", label: "Multas" },
    { value: "Reintegros", label: "Reintegros" },
    { value: "Multa Fiscalización", label: "Multa Fiscalización" },
    { value: "Reintegro Fiscalización", label: "Reintegro Fiscalización" },
    {
      value: "Gestoria Lista De Observaciones",
      label: "Gestoria Lista De Observaciones",
    },
    {
      value: "Contabilidad Lista De Observaciones",
      label: "Contabilidad Lista De Observaciones",
    },
  ];
  const gestiones = [
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
  ];

  const handleChartTypeChange = (selectedOption) => {
    setChartType(selectedOption.value);
  };

  const handleYearChange = (selectedOption) => {
    setYear(selectedOption.value);
  };

  const ContentData = ({ actualData, selectedData }) => {
    let urlApi = `${actualData.urlApiMain}&value=${actualData.fieldValue}&company=${selectedData.companyId}&month=${actualData.numMonth}&demo=emo`

    const [data, setData] = useState(null)
    const [load, setLoad] = useState(false)
    useEffect(() => {
      setLoad(true)
      requestAuth('GET', urlApi)
        .then(function (response) {
          setData(response.data)
          setLoad(false)
        })
        .catch(function (error) {
          console.log("error")
          setLoad(false)
        })
    }, [urlApi])

    const nav = (i) => {
      console.log(location.pathname)
      if (location.pathname.includes('/rol/6/')) {
        navigate(`./step/${i.step}`)
      }
      else {
        navigate(`/rol/6/menu/ManageProcedures`)
        const timeoutId = setTimeout(() => {
          navigate(`/rol/6/procedure-type/1/step/${i.step}`)
          return () => clearTimeout(timeoutId);
        }, 2500);
      }
      // dialogClose()
    }
    return (
      load ?
        <Circle spinnerColor='text-orange-400' />
        :
        <SimpleTableManagement
          header={[
            {
              name: "codigoDeInterno",
              label: "Código de interno",
            },
            {
              name: "stepName",
              label: "Nombre de paso",
            },
            {
              name: "edit",
              type: "action",
              label: "Acciones",
              actions: [
                {
                  label: "Añadir campo",
                  icon: "fas fa-share",
                  action: nav,
                  color: "text-[#1d4ed8]",
                },
              ],
            },
          ]}
          data={data}
          labelGrafic="trámite"
        />
    )
  }

  function handleClickMonth(label, data, urlApi, params, generalData, urlApiMain) {
    openDialog(
      <div style={{ width: "80vw" }}>
        <ChartContainer
          // title={label}
          title={`${label} - Mes: ${data?.label}`}
          type="SubChart"
          urlApi={`${urlApi}Month`}
          generalData={{ ...generalData, fieldValue: label, numMonth: data?.id, onClick: console.log(generalData) }}
          addParams={{
            dateQuery: "fechaDeFacturación",
            month: data?.id,
            value: label,
            ...params,
          }}
          ContentData={ContentData}
          actualData={{ ...generalData, fieldValue: label, numMonth: data?.id, urlApiMain: urlApiMain.replace(urlApi, `${urlApi}Table`) }}
        />
      </div>
    );
  }
  const monthList = [
    {
      label: "Enero",
      name: "1",
    },
    {
      label: "Febrero",
      name: "2",
    },
    {
      label: "Marzo",
      name: "3",
    },
    {
      label: "Abril",
      name: "4",
    },
    {
      label: "Mayo",
      name: "5",
    },
    {
      label: "Junio",
      name: "6",
    },
    {
      label: "Julio",
      name: "7",
    },
    {
      label: "Agosto",
      name: "8",
    },
    {
      label: "Septiembre",
      name: "9",
    },
    {
      label: "Octubre",
      name: "10",
    },
    {
      label: "Noviembre",
      name: "11",
    },
    {
      label: "Diciembre",
      name: "12",
    },
  ]

  function handleClickClient(label, _, urlApi, params) {
    openDialog(
      <div style={{ width: "80vw" }}>
        <ChartContainer
          title={`Mes: ${label.label}`}
          type="SubChart"
          urlApi={`${urlApi.substring(0, urlApi.length - 6)}Number`}
          addParams={{
            dateQuery: "fechaDeFacturación",
            month: label.id,
            ...params,
          }}
        />
      </div>
    );
  }

  return (
    <div>
      <div className="flex gap-4 items-center mb-2">
        <h3>Gráfica de gerencia: </h3>
        <Select
          options={graficas}
          value={graficas.find((option) => option.value === chartType)}
          onChange={handleChartTypeChange}
        />
        <h3>Gestión: {year}</h3>
        <Select
          options={gestiones}
          value={gestiones.find((option) => option.value === year)}
          onChange={handleYearChange}
        />
      </div>
      {
        {
          Clientes: (
            <ChartContainer
              title="Cantidad de tramites por cliente"
              urlApi={`/procedure/company/year/${year}/chart`}
              filters={filtersDefaultChart}
              addParams={{ dateQuery: "fechaDeFacturación" }}
              tableHeaders={[
                {
                  label: "Compañia",
                  name: "option",
                  aditionalClass: "bg-[#ffeadd]",
                },
                {
                  label: "Cantidad",
                  name: "total",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true
                },
                {
                  label: "Porcentaje %",
                  name: "percentage",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotalPercentage: true
                },
              ]}
            />
          ),
          Liquidadores: (
            <ChartContainer
              onClick={handleClickMonth}
              title="Cantidad de tramites por liquidador"
              urlApi={`/Procedure/field/designacionDeLiquidador/year/${year}/chart`}
              generalData={{ year, fieldname: 'designacionDeLiquidador' }}
              filters={[
                ...[
                  {
                    name: "procedureType",
                    url: "/procedureType",
                    default: "Todos los tramites",
                    valueOption: "id",
                    labelOption: "name",
                  },
                  {
                    name: "liquidator",
                    url: "/Dropdown/rol_liquidator/options",
                    label: "Todos los liquidadores",
                    valueOption: "name",
                    labelOption: "name",
                  },
                  {
                    name: "gestor",
                    url: "/Dropdown/rol_gestor/options",
                    label: "Todos los gestores",
                    valueOption: "name",
                    labelOption: "name",
                  },
                  {
                    name: "inCharge",
                    url: "/Dropdown/rol_InCharge/options",
                    label: "Todos los encargados",
                    valueOption: "id",
                    labelOption: "name",
                  },
                  {
                    name: "prioridad",
                    url: "/Dropdown/prioridad/options",
                    label: "Prioridad",
                    valueOption: "name",
                    labelOption: "name",
                  },
                  {
                    name: "complejidad",
                    url: "/Dropdown/ComplejidadDelDespacho/options",
                    label: "Complejidad",
                    valueOption: "name",
                    labelOption: "name",
                  },
                  {
                    name: "dateQuery",
                    label: "Filtrar fecha por: ",
                    options: [
                      {
                        label: "Fecha de facturación",
                        name: "fechaDeFacturación",
                      },
                      {
                        label: "Fecha aceptación",
                        name: "a2FechaDeAceptación",
                      },
                    ],
                  },
                  {
                    name: "months",
                    label: "Todos los meses",
                    options: monthList

                  },
                ],
              ]}
              addParams={{ dateQuery: "fechaDeFacturación" }}
              tableHeaders={[
                {
                  label: "Liquidador",
                  name: "option",
                  aditionalClass: "bg-[#ffeadd]",
                },
                {
                  label: "Cantidad",
                  name: "total",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
                {
                  label: "Porcentaje %",
                  name: "percentage",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotalPercentage: true
                },
              ]}
            />
          ),
          Gestores: (
            <ChartContainer
              onClick={handleClickMonth}
              title="Cantidad de tramites por gestor"
              urlApi={`/Procedure/field/asignarGestor/year/${year}/chart`}
              filters={[
                ...[
                  {
                    name: "procedureType",
                    url: "/procedureType",
                    default: "Todos los tramites",
                    valueOption: "id",
                    labelOption: "name",
                  },
                  {
                    name: "liquidator",
                    url: "/Dropdown/rol_liquidator/options",
                    label: "Todos los liquidadores",
                    valueOption: "name",
                    labelOption: "name",
                  },
                  {
                    name: "gestor",
                    url: "/Dropdown/rol_gestor/options",
                    label: "Todos los gestores",
                    valueOption: "name",
                    labelOption: "name",
                  },
                  {
                    name: "inCharge",
                    url: "/Dropdown/rol_InCharge/options",
                    label: "Todos los encargados",
                    valueOption: "id",
                    labelOption: "name",
                  },
                  {
                    name: "prioridad",
                    url: "/Dropdown/prioridad/options",
                    label: "Prioridad",
                    valueOption: "name",
                    labelOption: "name",
                  },
                  {
                    name: "complejidad",
                    url: "/Dropdown/ComplejidadDelDespacho/options",
                    label: "Complejidad",
                    valueOption: "name",
                    labelOption: "name",
                  },
                  {
                    name: "dateQuery",
                    label: "Filtrar fecha por:",
                    // defaultValue: "fechaDeFacturación",
                    options: [
                      {
                        label: "Fecha de facturación",
                        name: "fechaDeFacturación",
                      },
                      {
                        label: "Fecha aceptación",
                        name: "a2FechaDeAceptación",
                      },
                    ],
                  },
                  {
                    name: "months",
                    label: "Todos los meses",
                    options: monthList
                  },
                  // { type: "rangeDate" },
                ],
              ]}
              addParams={{ dateQuery: "fechaDeFacturación" }}
              tableHeaders={[
                {
                  label: "Gestor",
                  name: "option",
                  aditionalClass: "bg-[#ffeadd]",
                },
                {
                  label: "Cantidad",
                  name: "total",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
                {
                  label: "Porcentaje %",
                  name: "percentage",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotalPercentage: true
                },
              ]}
            />
          ),
          Comisiones: (
            <ChartContainer
              onClick={handleClickClient}
              title="Monto de comisiones por mes"
              urlApi={`/Procedure/field/d-HonorariosProfesionales/year/${year}/chartAmount`}
              filters={filtersDefaultChart}
              type="SimpleBar"
              addParams={{ dateQuery: "fechaDeFacturación" }}
              amountTableHeaders={[
                {
                  label: "Mes",
                  name: "mes",
                  aditionalClass: "bg-[#ffeadd]",
                },
                {
                  label: "Monto",
                  name: "amount",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
                {
                  label: "Cantidad",
                  name: "cantidad",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
              ]}
            />
          ),
          "Problemas Del Despacho": (
            <ChartContainer
              onClick={handleClickMonth}
              title="Cantidad de tramites por problemas del despacho"
              urlApi={`/Procedure/field/problemasDeDespacho/year/${year}/chart`}
              filters={filtersDefaultChart}
              addParams={{ dateQuery: "fechaDeFacturación" }}
              tableHeaders={[
                {
                  label: "Problema del Despacho",
                  name: "option",
                  aditionalClass: "bg-[#ffeadd]",
                },
                {
                  label: "Cantidad",
                  name: "total",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
                {
                  label: "Porcentaje %",
                  name: "percentage",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotalPercentage: true
                },
              ]}
            />
          ),
          "Observaciones Aforo": (
            <ChartContainer
              onClick={handleClickMonth}
              title="Cantidad de tramites por observaciones aforo"
              urlApi={`/Procedure/field/observaciones(Aforo)/year/${year}/chart`}
              filters={filtersDefaultChart}
              addParams={{ dateQuery: "fechaDeFacturación" }}
              tableHeaders={[
                {
                  label: "Observación Aforo",
                  name: "option",
                  aditionalClass: "bg-[#ffeadd]",
                },
                {
                  label: "Cantidad",
                  name: "total",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
                {
                  label: "Porcentaje %",
                  name: "percentage",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotalPercentage: true
                },
              ]}
            />
          ),
          Canales: (
            <ChartContainer
              onClick={handleClickMonth}
              title="Cantidad de tramites por canal"
              urlApi={`/Procedure/field/canal(Rojo,AmarilloOVerde)/year/${year}/chart`}
              filters={filtersDefaultChart}
              addParams={{ dateQuery: "fechaDeFacturación" }}
              tableHeaders={[
                {
                  label: "Canal",
                  name: "option",
                  aditionalClass: "bg-[#ffeadd]",
                },
                {
                  label: "Cantidad",
                  name: "total",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
                {
                  label: "Porcentaje %",
                  name: "percentage",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotalPercentage: true
                },
              ]}
            />
          ),
          Mercadería: (
            <ChartContainer
              onClick={handleClickMonth}
              title="Cantidad de tramites por tipo de mercadería"
              urlApi={`/Procedure/field/tipoDeMercadería/year/${year}/chart`}
              filters={filtersDefaultChart}
              addParams={{ dateQuery: "fechaDeFacturación" }}
              tableHeaders={[
                {
                  label: "tipo de mercadería",
                  name: "option",
                  aditionalClass: "bg-[#ffeadd]",
                },
                {
                  label: "Cantidad",
                  name: "total",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
                {
                  label: "Porcentaje %",
                  name: "percentage",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotalPercentage: true
                },
              ]}
            />
          ),
          "Aduanas De Despachos": (
            <ChartContainer
              onClick={handleClickMonth}
              title="Cantidad de tramites por aduana de despacho"
              urlApi={`/Procedure/field/a4AduanaDeDespacho/year/${year}/chart`}
              filters={filtersDefaultChart}
              addParams={{ dateQuery: "fechaDeFacturación" }}
              tableHeaders={[
                {
                  label: "Compañia",
                  name: "option",
                  aditionalClass: "bg-[#ffeadd]",
                },
                {
                  label: "Cantidad",
                  name: "total",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
                {
                  label: "Porcentaje %",
                  name: "percentage",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotalPercentage: true
                },
              ]}
            />
          ),
          "Examen Previo": (
            <ChartContainer
              onClick={handleClickMonth}
              title="Cantidad de tramites por examen previo"
              urlApi={`/Procedure/field/examenPrevioRealizado/year/${year}/chart`}
              filters={filtersDefaultChart}
              addParams={{ dateQuery: "fechaDeFacturación" }}
              tableHeaders={[
                {
                  label: "Exámen prévio",
                  name: "option",
                  aditionalClass: "bg-[#ffeadd]",
                },
                {
                  label: "Cantidad",
                  name: "total",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
                {
                  label: "Porcentaje %",
                  name: "percentage",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotalPercentage: true
                },
              ]}
            />
          ),
          Multas: (
            <ChartContainer
              onClick={handleClickClient}
              title="Monto de multas por mes"
              urlApi={`/Procedure/field/montoDeMultaPorContravencion/year/${year}/chartAmount`}
              filters={filtersDefaultChart}
              type="SimpleBar"
              addParams={{ dateQuery: "fechaDeFacturación" }}
              amountTableHeaders={[
                {
                  label: "Mes",
                  name: "mes",
                  aditionalClass: "bg-[#ffeadd]",
                },
                {
                  label: "Monto",
                  name: "amount",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
                {
                  label: "Cantidad",
                  name: "cantidad",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
              ]}
            />
          ),
          Reintegros: (
            <ChartContainer
              onClick={handleClickClient}
              title="Monto de reintegros por mes"
              urlApi={`/Procedure/field/montoDeReintregro/year/${year}/chartAmount`}
              filters={filtersDefaultChart}
              type="SimpleBar"
              addParams={{ dateQuery: "fechaDeFacturación" }}
              amountTableHeaders={[
                {
                  label: "Mes",
                  name: "mes",
                  aditionalClass: "bg-[#ffeadd]",
                },
                {
                  label: "Monto",
                  name: "amount",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
                {
                  label: "Cantidad",
                  name: "cantidad",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
              ]}
            />
          ),
          "Multa Fiscalización": (
            <ChartContainer
              onClick={handleClickClient}
              title="Monto de multa fiscalización por mes"
              urlApi={`/Procedure/field/multaPorContravencion(ControlDiferido)/year/${year}/chartAmount`}
              filters={filtersDefaultChart}
              type="SimpleBar"
              addParams={{ dateQuery: "fechaDeFacturación" }}
              amountTableHeaders={[
                {
                  label: "Mes",
                  name: "mes",
                  aditionalClass: "bg-[#ffeadd]",
                },
                {
                  label: "Monto",
                  name: "amount",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
                {
                  label: "Cantidad",
                  name: "cantidad",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
              ]}
            />
          ),
          "Reintegro Fiscalización": (
            <ChartContainer
              onClick={handleClickClient}
              title="Monto reintegro fiscalización por mes"
              urlApi={`/Procedure/field/montoDeReintegro-ControlDiferido(Bs)/year/${year}/chartAmount`}
              filters={filtersDefaultChart}
              type="SimpleBar"
              addParams={{ dateQuery: "fechaDeFacturación" }}
              amountTableHeaders={[
                {
                  label: "Mes",
                  name: "mes",
                  aditionalClass: "bg-[#ffeadd]",
                },
                {
                  label: "Monto",
                  name: "amount",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
                {
                  label: "Cantidad",
                  name: "cantidad",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
              ]}
            />
          ),
          "Gestoria Lista De Observaciones": (
            <ChartContainer
              onClick={handleClickMonth}
              title="Gestoria Lista De Observaciones"
              urlApi={`/Procedure/field/gestoriaListaDeObservaciones(ProblemasEnElDespachoConcluido)/year/${year}/chart`}
              filters={filtersDefaultChart}
              addParams={{ dateQuery: "fechaDeFacturación" }}
              tableHeaders={[
                {
                  label: "Gestoria Lista De Observaciones",
                  name: "option",
                  aditionalClass: "bg-[#ffeadd]",
                },
                {
                  label: "Cantidad",
                  name: "total",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
                {
                  label: "Porcentaje %",
                  name: "percentage",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotalPercentage: true
                },
              ]}
            />
          ),
          "Contabilidad Lista De Observaciones": (
            <ChartContainer
              onClick={handleClickMonth}
              title="Contabilidad Lista De Observaciones"
              urlApi={`/Procedure/field/contabilidadListaDeObservaciones(ProblemasEnElDespachoConcluido)/year/${year}/chart`}
              filters={filtersDefaultChart}
              addParams={{ dateQuery: "fechaDeFacturación" }}
              tableHeaders={[
                {
                  label: "Contabilidad Lista De Observaciones",
                  name: "option",
                  aditionalClass: "bg-[#ffeadd]",
                },
                {
                  label: "Cantidad",
                  name: "total",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
                {
                  label: "Porcentaje %",
                  name: "percentage",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotalPercentage: true
                },
              ]}
            />
          ),
        }[chartType]
      }
    </div>
  );
};

export default Management;
