import * as Yup from "yup";
import { toast } from "react-toastify";
import Circle from "../Animation/Circle";
import { useEffect, useState } from "react";
import { Request } from "../../hooks/Request";
import TableComplete from "../Tables/TableComplete";
import { validateArray } from "../../utils/validate";
import { viewLinkExcel } from "../../utils/constGenerate";
import { requestAuth } from "../services/RequestService";
import { useDialog } from "../../contexts/DialogContext";
import { useModal } from "../../contexts/ModalContext";
import { DialogConfirmation } from "../Modal";
import { H2, H3, Label, Anchor } from "../Text";
import { Button } from "../Buttons";
import FormDefault from "../Forms/FormDefault";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { Title } from "chart.js";
import axios from "axios";
import FormFilesMultiple from "./FormFilesMultiple";

const ListDocument = (props) => {
  const {
    procedure,
    addDocument = false,
    deleteDocument = true,
    editFile = false,
    step,
  } = props;

  const [listFile, setListFile] = useState([]);
  const [reloadList, setReloadList] = useState(false);
  const { openDialog, dialogClose } = useDialog();
  const [load, setLoad] = useState(true);
  const { openModal, onClose } = useModal();
  const { data, loading, call } = Request({
    urlApi: `/field/uploadedfileFields/${procedure.id}`,
  });
  useEffect(() => {
    async function handleBreakDown(list) {
      setListFile([]);
      setLoad(true);
      await Promise.all(
        list.map(async (item) => {
          if (item.field.type === "fileStatus") {
            const fileContents = await requestAuth(
              "get",
              `/fileState/procedure/${procedure.id}/field/${item.field.id}`
            ).then((res) => {
              return res.data;
            });
            setListFile((a) => [
              ...a,
              ...fileContents.map((file) => ({
                ...file,
                name: item.field.label,
              })),
            ]);
          } else {
            setListFile((a) => [
              ...a,
              { ...item, url: item.value, name: item.field.label },
            ]);
          }
        })
      );
      setLoad(false);
    }
    if (loading) {
      handleBreakDown(data);
    }
  }, [data, loading]);

  function handleDelete(data) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres eliminar el archivo?</H3>}
        onClose={dialogClose}
        method="delete"
        url={
          data?.field?.type === "file" || data?.field?.type === "fileRead"
            ? `/dataSet/${data.id}`
            : `/fileState/${data.id}`
        }
        texBtn="Eliminar"
        handleSuccess={call}
      />
    );
  }
  function handleForm(values) {
    const { field, FieldId, isNew } = values;
    openDialog(
      <FormAdd
        data={{}}
        onClose={dialogClose}
        reload={handleReload}
        newDocument={isNew ? FieldId : null}
        field={{
          ...field,
          fieldId: field?.id ? field.id : null,
          id: procedure.id,
        }}
        step={step}
      />
    );
  }

  function handleFormEdit(data) {
    if (data.field) {
      openDialog(
        <FormEditFile
          data={data}
          procedureId={procedure.id}
          onClose={dialogClose}
          reload={call}
        />
      );
    } else {
      openDialog(
        <FormEdit
          data={data}
          procedureId={procedure.id}
          onClose={dialogClose}
          reload={call}
          step={step}
        />
      );
    }
  }

  function handleFormEditField(data) {
    openDialog(
      <FormEditField
        data={data}
        procedureId={procedure.id}
        onClose={dialogClose}
        reload={call}
      />
    );
  }

  function handleReload() {
    setReloadList((a) => !a);
    call();
  }

  function handleAddFiles() {
    openDialog(<FormFilesMultiple onClose={dialogClose} reload={call} />);
  }

  function handleViewDocument(list) {
    openDialog(<ViewDocuments listDocument={list} />);
  }

  function handleDownloadZip() {
    openDialog(
      <DialogConfirmation
        children={
          <H3>
            ¿Estas seguro que quieres descargar los documentos en un Excel?
          </H3>
        }
        onClose={dialogClose}
        method="get"
        url={`/files/FileRar/${procedure.id}`}
        texBtn="Descargar"
        handleSuccess={(data) => {
          viewLinkExcel(data, openDialog);
        }}
      />
    );
  }

  return (
    <div>
      <AddDocument
        procedureId={procedure.id}
        addDocument={addDocument}
        handleForm={handleForm}
        reload={call}
        reloadList={reloadList}
      />
      {!loading ? (
        <Circle />
      ) : validateArray(data) && !load ? (
        <>
          <div className="flex gap-2 justify-end mb-1">
            <Button onClick={() => handleAddFiles()}>
              <i className="fas fa-folder-plus"></i>
            </Button>
            <Button onClick={() => handleDownloadZip(listFile)}>
              <i className="fas fa-file-archive"></i>
            </Button>
            <Button onClick={() => handleViewDocument(listFile)}>
              <i className="fas fa-list"></i>
            </Button>
          </div>
          <TableComplete
            header={[
              {
                name: "name",
                label: "Documento",
              },
              {
                name: "originalName",
                label: "Nombre original",
              },
              {
                name: "url",
                label: "Enlace",
                type: "custom",
                builder: (item) => {
                  if (item) {
                    return (
                      <Anchor
                        href={item}
                        className="text-[#1d4ed8] underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Abrir
                      </Anchor>
                    );
                  } else {
                    return "-";
                  }
                },
              },
              {
                name: "number",
                label: "Nro",
              },

              {
                label: "Fecha",
                name: "creationDate",
                type: "date",
              },
              {
                name: "presentation",
                label: "Presentación",
              },
              {
                name: "status",
                label: "Estado",
              },
              {
                label: "Proveedor/Emisor",
                name: "provider",
              },
              {
                name: "add",
                type: "action",
                label: "Opciones",
                sticky: true,
                actions: [
                  editFile
                    ? {
                        label: "Editar nombre",
                        icon: "fas fa-spell-check",
                        action: handleFormEditField,
                        color: "text-[#1d4ed8]",
                      }
                    : null,
                  {
                    label: "Editar",
                    icon: "fas fa-edit",
                    action: handleFormEdit,
                    color: "text-[#1d4ed8]",
                  },
                  deleteDocument
                    ? {
                        label: "Eliminar",
                        icon: "fas fa-trash",
                        action: handleDelete,
                        color: "text-red-500",
                      }
                    : {},
                ],
              },
            ]}
            data={listFile}
          />
        </>
      ) : null}
    </div>
  );
};

const ViewDocuments = ({ listDocument }) => {
  const [viewImage, setviewImage] = useState();
  const listExtension = ["png", "gif", "jpeg", "jpg"];

  return (
    <>
      <div className="flex flex-col gap-2">
        <H2>Lista de documentos</H2>
        {listDocument.map((document) => {
          const extension = document.url ? document.url.split(".") : "";
          return listExtension.indexOf(extension[extension.length - 1]) > -1 ? (
            <div
              onClick={() => setviewImage(document.url)}
              className="flex items-center gap-2 cursor-pointer"
            >
              <img
                className="w-[200px] h-20 bg-white rounded-md"
                src={document.url}
                alt=""
              />
              <div className="flex flex-col items-start max-w-[400px]">
                <h3 className="font-semibold">{document.name}</h3>
                <h3>{document.originalName}</h3>
              </div>
            </div>
          ) : (
            <Anchor
              href={document.url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2"
            >
              <div className="w-[200px] h-20 bg-white rounded-md flex justify-center items-center">
                <H3 className="text-blue-500 text-2xl uppercase">
                  {extension[extension.length - 1]}
                </H3>
              </div>
              <div className="flex flex-col items-start max-w-[400px]">
                <h3 className="font-semibold">{document.name}</h3>
                <h3>{document.originalName}</h3>
              </div>
            </Anchor>
          );
        })}
        <br />
      </div>

      {viewImage ? (
        <div
          className={
            "z-40 h-screen w-full bg-black/50 fixed top-0 right-0 duration-300 left-0 flex justify-center items-center"
          }
          onClick={() => setviewImage(null)}
        >
          <div className="h-[100%] w-[100%] absolute"></div>
          <div className="max-h-screen overflow-y-auto bg-slate-200 relative bg-inherit rounded-lg">
            <img className="h-[90vh] rounded-md" src={viewImage} alt="" />
          </div>
        </div>
      ) : null}
    </>
  );
};

const FormAdd = ({ data, onClose, reload, field, newDocument, step }) => {
  async function uploadFile(values) {
    let formData = new FormData();
    formData.append("Name", field.label);
    formData.append("File", values.url);
    formData.append("originalName", values.url.name);
    formData.append("Status", "verified");
    formData.append("ProcedureId", field.id);
    await requestAuth("post", "/files", formData)
      .then((res) => {
        onSubmit({ ...values, url: res.data.url, name: field.label });
      })
      .catch(() => {
        toast.error("Se produjo un error al subir el archivo");
      });
  }

  async function onSubmit(values) {
    if (values.provider_isNew) {
      await handleCreateOption(values);
    }
    if (newDocument) {
      await handleNewOption(values);
    } else {
      await handleRegister(values);
    }
  }

  async function handleNewOption(values) {
    await requestAuth("post", "field", {
      AutoGenerated: false,
      ClientDisplay: false,
      Label: newDocument,
      ProcessStepIdList: [],
      TableDisplay: false,
      Type: "fileStatus",
      Unique: false,
      Url: "",
      Validate: false,
    })
      .then(async (resp) => {
        await requestAuth(
          "post",
          `/fileState/procedure/${field.id}/field/${resp.data.id}`,
          values
        )
          .then(() => {
            reload();
            onClose();
            toast.success("Registro exitoso");
          })
          .catch(() => {
            toast.error("Se produjo un error al guardar");
          });
      })
      .catch(() => {
        toast.error("Se produjo un error al guardar");
      });
  }
  async function handleRegister(values) {
    await requestAuth(
      data.id ? "put" : "post",
      data.id
        ? `/fileState/${data.id}`
        : `/fileState/procedure/${field.id}/field/${field.fieldId}`,
      values
    )
      .then(() => {
        reload();
        onClose();
        toast.success("Registro exitoso");
      })
      .catch(() => {
        toast.error("Se produjo un error al guardar");
      });
  }

  async function handleCreateOption(value) {
    await requestAuth("post", "/dropdown/option", {
      Name: value.provider,
      DropdownListLabel: "providers",
    });
  }
  return (
    <>
      <FormDefault
        tittle={field.label}
        fields={[
          {
            label: "Archivo",
            name: "url",
            type: "file",
            typeInput: "file",
          },
          {
            label: "Número",
            name: "number",
            placeholder: "Número",
            type: "text",
            typeInput: "input",
          },
          {
            label: "Presentar",
            name: "presentation",
            type: "select",
            typeInput: "select",
            options: [
              {
                label: "Digital",
                value: "Digital",
              },
              {
                label: "Físico",
                value: "Físico",
              },
            ],
          },
          {
            label: "Estado",
            name: "status",
            type: "select",
            typeInput: "select",
            options: [
              {
                label: "Original",
                value: "Original",
              },
              {
                label: "Copia",
                value: "Copia",
              },
              {
                label: "Fotocopia simple",
                value: "Fotocopia simple",
              },
              {
                label: "Fotocopia legalizada",
                value: "Fotocopia legalizada",
              },
            ],
          },
          {
            label: "Proveedor/Emisor",
            name: "provider",
            typeInput: "selectNewOption",
            urlApi: "/dropdown/providers/options",
            value: "name",
            labelOption: "name",
          },
        ]}
        initialValues={{
          url: data?.url ?? "",
          number: data?.number ?? "",
          presentation: data?.presentation ?? "Digital",
          status: data?.status ?? "Original",
          provider: data?.provider ?? "",
        }}
        validationSchema={Yup.object().shape({
          url:
            step === 1 || step === 2 || step === 25
              ? null
              : Yup.mixed().required("Campo requerido.").nullable(),
          // number: Yup.string().required('Campo requerido'),
          status: Yup.string().required("Campo requerido"),
          // provider: Yup.string().required('Campo requerido'),
        })}
        onSubmit={(values) =>
          typeof values.url === "string"
            ? onSubmit({ ...values, name: data.name })
            : uploadFile(values)
        }
        buttonName="Registrar"
      />
      <br />
    </>
  );
};

const AddDocument = (props) => {
  const { procedureId, handleForm, addDocument, reload, reloadList } = props;
  const [load, setload] = useState(false);
  const [values, setValues] = useState({
    FieldId: "",
    File: "",
    Description: "",
    field: null,
    isNew: false,
  });
  const { data, loading, call } = Request({
    urlApi: `/field/fileFields`,
  });

  useEffect(() => {
    call();
  }, [reloadList]);

  function handleChange(e) {
    setValues((a) => ({
      ...a,
      FieldId: e.value,
      isNew: e.__isNew__ ? true : false,
      field: data.filter((field) => field.id === e.value)[0],
    }));
  }

  function handleFile(e) {
    const { name, files } = e.target;
    setValues((a) => ({
      ...a,
      [name]: files[0],
    }));
  }

  async function handleSubmit() {
    setload(true);
    let formData = new FormData();
    formData.append("Name", values.FieldId);
    formData.append("File", values.File);
    formData.append("originalName", values.File.name);
    formData.append("Status", "verified");
    formData.append("ProcedureId", procedureId);
    const fileContent = await requestAuth("post", "/files", formData)
      .then((res) => {
        return {
          Value: res.data.url,
          ProcedureId: procedureId,
          FieldId: parseInt(values.FieldId),
          FileId: res.data.id,
        };
      })
      .catch((error) => {
        setload(false);
        toast.error(
          error.response.data
            ? error.response.data
            : "Se produjo un error al subir el archivo"
        );
      });
    submitDataset(fileContent);
  }

  async function submitDataset(value) {
    await requestAuth("post", "/dataSet/NewDataSet", [value])
      .then(() => {
        setload(false);
        reload();
        toast.success("El archivo se subió exitosamente");
      })
      .catch(() => {
        setload(false);
        toast.error("No se pudo subir el archivo");
      });
  }

  if (!loading) {
    return <Circle />;
  }
  return (
    <div className="bg-slate-50 p-2 mb-2 rounded-md">
      <form className="flex flex-col">
        <Label className={`mt-2`}>Lista de documentos</Label>
        {addDocument ? (
          <CreatableSelect
            isSearchable={true}
            onChange={handleChange}
            styles={{
              placeholder: (base) => ({
                ...base,
                color: "black",
              }),
            }}
            // defaultInputValue={values[field.name]}
            placeholder={"Seleccionar..."}
            isOptionSelected={false}
            // isLoading={field.urlApi ? !loading : false}
            options={data.map((option) => ({
              value: option.id,
              label: option.label,
            }))}
          />
        ) : (
          <Select
            isSearchable={true}
            onChange={handleChange}
            styles={{
              placeholder: (base) => ({
                ...base,
                color: "black",
              }),
            }}
            // defaultInputValue={values[field.name]}
            placeholder={"Seleccionar..."}
            isOptionSelected={false}
            // isLoading={field.urlApi ? !loading : false}
            options={data.map((option) => ({
              value: option.id,
              label: option.label,
            }))}
          />
        )}
        {!values.field && !values.isNew ? null : values.isNew ||
          values.field.type === "fileStatus" ? (
          <Button
            onClick={() => handleForm(values)}
            // onClick={() => handleForm(values.field, values.isNew)}
            className="py-2 mt-1"
          >
            Adicionar documento
          </Button>
        ) : (
          <>
            <Label className={`my-0 mt-4`}>Archivo</Label>
            <div className="flex gap-2">
              <div
                className="w-full rounded-[7px] placeholder-black placeholder:text-[15px]
                placeholder:opacity-50 placeholder:font-normal
                px-[17px] py-1 font-light
                text-[15px] bg-white/50 md:bg-white/75"
              >
                <input
                  // value={values.File}
                  onChange={handleFile}
                  className="text-sm text-grey-500
                  file:mr-5 file:py-2 file:px-6
                  file:rounded-full file:border-0
                  file:text-sm file:font-medium
                  file:bg-[#1d4ed8] file:text-white
                  hover:file:cursor-pointer hover:file:bg-[#1d4fd8ce]"
                  type="file"
                  name="File"
                />
              </div>
              {load ? (
                <Circle />
              ) : (
                <Button onClick={handleSubmit} className="px-3 py-0">
                  Adicionar
                </Button>
              )}
            </div>
          </>
        )}
      </form>
    </div>
  );
};

const FormEdit = ({ data, procedureId, onClose, reload, step }) => {
  async function uploadFile(values) {
    let formData = new FormData();
    formData.append("Name", data.name);
    formData.append("File", values.url);
    formData.append("originalName", values.url.name);
    formData.append("Status", "verified");
    formData.append("ProcedureId", procedureId);
    await requestAuth("post", "/files", formData)
      .then((res) => {
        onSubmit({ ...values, url: res.data.url, name: data.name });
      })
      .catch(() => {
        toast.error("Se produjo un error al subir el archivo");
      });
  }

  async function onSubmit(values) {
    if (values.provider_isNew) {
      await handleCreateOption(values);
    }
    await requestAuth(
      data.id ? "put" : "post",
      //   data.id
      // ?
      `/fileState/${data.id}`,
      // : `/fileState/procedure/${field.id}/field/${field.fieldId}`,
      values
    )
      .then(() => {
        reload();
        onClose();
        toast.success("Registro exitoso");
      })
      .catch(() => {
        toast.error("Se produjo un error al guardar");
      });
  }
  async function handleCreateOption(value) {
    await requestAuth("post", "/dropdown/option", {
      Name: value.provider,
      DropdownListLabel: "providers",
    });
  }
  return (
    <>
      <FormDefault
        tittle="Editar archivo"
        fields={[
          {
            label: "Archivo",
            name: "url",
            type: "file",
            typeInput: "file",
          },
          {
            label: "Número",
            name: "number",
            placeholder: "Número",
            type: "text",
            typeInput: "input",
          },
          {
            label: "Presentar",
            name: "presentation",
            type: "select",
            typeInput: "select",
            options: [
              {
                label: "Digital",
                value: "Digital",
              },
              {
                label: "Físico",
                value: "Físico",
              },
            ],
          },
          {
            label: "Estado",
            name: "status",
            type: "select",
            typeInput: "select",
            options: [
              {
                label: "Original",
                value: "Original",
              },
              {
                label: "Copia",
                value: "Copia",
              },
              {
                label: "Fotocopia simple",
                value: "Fotocopia simple",
              },
              {
                label: "Fotocopia legalizada",
                value: "Fotocopia legalizada",
              },
            ],
          },
          {
            label: "Proveedor/Emisor",
            name: "provider",
            typeInput: "selectNewOption",
            urlApi: "/dropdown/providers/options",
            value: "name",
            labelOption: "name",
          },
        ]}
        initialValues={{
          url: data.url ?? "",
          number: data.number ?? "",
          presentation: data.presentation ?? "Digital",
          status: data.status ?? "Original",
          provider: data.provider ?? "",
        }}
        validationSchema={Yup.object().shape({
          url:
            step === 1 || step === 2 || step === 25
              ? null
              : Yup.mixed().required("Campo requerido.").nullable(),
          // number: Yup.string().required('Campo requerido'),
          status: Yup.string().required("Campo requerido"),
          // provider: Yup.string().required('Campo requerido'),
        })}
        onSubmit={(values) =>
          typeof values.url === "string"
            ? onSubmit({ ...values, name: data.name })
            : uploadFile(values)
        }
        buttonName="Registrar"
      />
      <br />
    </>
  );
};

const FormEditFile = ({ data, procedureId, onClose, reload }) => {
  async function uploadFile(values) {
    let formData = new FormData();
    formData.append("Name", data.name);
    formData.append("File", values.url);
    formData.append("originalName", values.url.name);
    formData.append("Status", "verified");
    formData.append("ProcedureId", procedureId);
    await requestAuth("post", "/files", formData)
      .then((res) => {
        submitDataset([
          {
            Value: res.data.url,
            ProcedureId: procedureId,
            FieldId: data.fieldId,
            FileId: res.data.id,
          },
        ]);
      })
      .catch(() => {
        toast.error("Se produjo un error al subir el archivo");
      });
  }

  function submitDataset(values) {
    requestAuth("post", "/dataset", values)
      .then(() => {
        reload();
        onClose();
        toast.success("Envio exitoso");
      })
      .catch(() => {
        toast.error("Error en el envio");
      });
  }

  return (
    <>
      <FormDefault
        tittle="Adicionar archivo"
        fields={[
          {
            label: "Archivo",
            name: "url",
            type: "file",
            typeInput: "file",
          },
        ]}
        initialValues={{
          url: data.url ?? null,
        }}
        validationSchema={Yup.object().shape({
          url: Yup.mixed().required("Campo requerido.").nullable(),
        })}
        onSubmit={uploadFile}
        buttonName="Registrar"
      />
      <br />
    </>
  );
};

const FormEditField = (props) => {
  const { data, onClose, reload } = props;

  async function onSubmit(values) {
    await requestAuth(
      "put",
      `/fileState/${data.id}/field/${values.fieldId}/change`
    )
      .then(() => {
        reload();
        onClose();
        toast.success("Envió exitoso");
      })
      .catch(() => {
        toast.error("Error en el envió");
      });
  }

  async function handleNewOption(values) {
    await requestAuth("post", "field", {
      AutoGenerated: false,
      ClientDisplay: false,
      Label: values.fieldId,
      ProcessStepIdList: [],
      TableDisplay: false,
      Type: "fileStatus",
      Unique: false,
      Url: "",
      Validate: false,
    })
      .then(async (resp) => {
        await onSubmit({ fieldId: resp.data.id });
      })
      .catch(() => {
        toast.error("Se produjo un error al guardar");
      });
  }

  return (
    <div style={{ overflowX: "hidden" }} className="min-h-[450px]">
      <FormDefault
        tittle="Editar nombre del documento"
        fields={[
          {
            label: "Nombre del documento a cambiar",
            name: "fieldId",
            type: "selectNewOption",
            typeInput: "selectNewOption",
            urlApi: "/field/fileFields",
            labelOption: "label",
            value: "id",
          },
        ]}
        initialValues={{}}
        validationSchema={Yup.object().shape({
          fieldId: Yup.mixed().required("Campo requerido.").nullable(),
        })}
        onSubmit={(values) => {
          if (values.fieldId_isNew) {
            handleNewOption(values);
          } else {
            onSubmit(values);
          }
        }}
        buttonName="Guardar"
      />
      <br />
    </div>
  );
};

export default ListDocument;
