import React from "react";
import {
  headerList,
  filtersDefault,
  addFiltersDefault,
} from "../../utils/constant";
import { useParams } from "react-router-dom";
import { useDialog } from "../../contexts/DialogContext";
import { useModal } from "../../contexts/ModalContext";
import CallServiceTable from "../../components/Tables/CallServiceTable";

import RenderPdf from "../../containers/ManegeProcedure/RenderPdf";
import GenerateRoadmap from "../../containers/ManegeProcedure/GenerateRoadmap";
import RenderLiquidaciónProforma from "../../containers/ManegeProcedure/RenderLiquidaciónProforma";

const History = () => {
  const { rolId } = useParams();
  const { openModal } = useModal();
  const { openDialog } = useDialog();

  function listDocumentPdf(procedure) {
    openDialog(<RenderPdf procedure={procedure} />);
  }
  function generateSettlementProforma(procedure) {
    openModal(<RenderLiquidaciónProforma procedure={procedure} />);
  }
  function generateRoadmap(procedure) {
    openDialog(<GenerateRoadmap procedure={procedure} />);
  }

  return (
    <CallServiceTable
      urlApi="/procedure/user"
      filters={filtersDefault}
      addFilters={addFiltersDefault}
      header={[
        ...headerList,
        {
          name: "Reports",
          type: "action",
          label: "Reportes",
          sticky: true,
          actions: [
            {
              label: "Generar informe de la recepción de documentos",
              icon: "fas fa-tasks",
              action: listDocumentPdf,
              color: "text-[#1d4ed8]",
            },
            {
              label: "Generar liquidación - proforma",
              icon: "fas fa-coins",
              action: generateSettlementProforma,
              color: "text-[#1d4ed8]",
            },
            {
              label: "Generar hoja de ruta",
              icon: "fas fa-route",
              action: generateRoadmap,
              color: "text-[#1d4ed8]",
            },
          ],
        },
      ]}
      addUrl={`designation=liquidator&rolId=${rolId}`}
      statusPaination={true}
    />
  );
};

export default History;
