import View from './View'
import Text from './Text'
import { validateArray } from '../../utils/validate'
import * as dayjs from 'dayjs'
import { Link } from '@react-pdf/renderer'

const CustomTablePDF = (props) => {
  const { fields = [], data = [], border } = props

  function validateType(value, head, idx = 0) {
    const listData = {
      date: dayjs(value).format('DD/MM/YYYY HH:mm'),
      custom: head.builder ? head.builder(value, head) : null,
      translate: head.translate ? head.translate[value] : '',
    }
    return listData[head.type] || (head?.counter ? idx + 1 : value)
  }
  if (!validateArray(fields)) {
    return null
  }
  return (
    <View flexDirection="column">
      <View border={border} width="100%">
        {fields.map((field, index) => (
          <View
            key={index}
            border={{ right: index + 1 < fields.length ? true : false }}
            width={field.width ? `${field.width}%` : 'auto'}
          >
            <Text bold style={{ margin: '0 auto' }}>
              {field.label}
            </Text>
          </View>
        ))}
      </View>
      {validateArray(data)
        ? data.map((item, idx) => (
          <View
            key={idx}
            border={{ right: true, bottom: true, left: true }}
            width="100%"
          >
            {fields.map((field, idy) => (
              <View
                key={idy}
                border={{ right: idy + 1 < fields.length ? true : false }}
                width={field.width ? `${field.width}%` : 'auto'}
              >
                {field.type !== 'link' ? (
                  <Text>{field.name === "userInChange" ? (item[field.name] != null ? item[field.name].replace(',', '\n') : validateType(item[field.name], field)) : validateType(item[field.name], field, idx)}</Text>
                ) : item[field.name] && item[field.name] !== 'null' ? (
                  <Link src={item[field.name]}>
                    <Text color="#1d4ed8">Documento</Text>
                  </Link>
                ) : (
                  <Text color="#1d4ed8">--</Text>
                )}
              </View>
            ))}
          </View>
        ))
        : null}
    </View>
  )
}

export default CustomTablePDF
