import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "../../components/Buttons";
import { useModal } from "../../contexts/ModalContext";
import { useDialog } from "../../contexts/DialogContext";
import { useSocket } from "../../contexts/SocketContext";
import { useSocketPending } from "../../contexts/SocketPendingContext";
import { requestAuth } from "../../components/services/RequestService";
import CallServiceTable from "../../components/Tables/CallServiceTable";
import ListDocument from "../../components/common/ListDocument";
import RenderLiquidaciónProforma from "../../containers/ManegeProcedure/RenderLiquidaciónProforma";
import GenerateFileDelivery from "../../containers/ManegeProcedure/GenerateFileDelivery";
import { H3, Anchor } from "../../components/Text";
import { NewProcedure, FillForm, SendProcedure } from "../../containers/steps";
import { DialogConfirmation } from "../../components/Modal";
import ListDocumentVerify from "../../components/common/ListDocumentVerify";
import TableLisDocument from "../../components/common/TableLisDocument";
import { useAuth } from "../../contexts/AuthContext";
import { validateArray } from "../../utils/validate";
import { addFiltersDefault, headerList } from "../../utils/constant";
import config from "../../config.json";
import { Tooltip } from "react-tippy";

const StepsTramites = () => {
  const { rolId, step, typeId } = useParams();
  const { connectionPending, setConnectionId } = useSocketPending();
  const { openModal, onClose } = useModal();
  const { openDialog, dialogClose } = useDialog();
  const { user } = useAuth();

  const [reload, setReload] = useState(false);

  useEffect(() => {
    const connect = connectionPending.connect;
    if (connect) {
      connect.start().then(() => {
        connect.on("ListTypeProcedure", () => {
          // console.log("ListTypeProcedure table");
          setReload((a) => !a);
        });
        requestAuth(
          "post",
          `/auth/SocketConnection/${connect.connection.connectionId}`
        ).then(() => {
          setConnectionId(connect.connection.connectionId);
        });
      });
      // .catch((e) => console.log("Connection failed: ", e));
    }
  }, []);

  function handleAdd(reloadTable) {
    openModal(
      <NewProcedure
        reload={reloadTable}
        onClose={onClose}
        openModal={openModal}
      />
    );
  }

  function handleFillForm(procedure, reloadTable) {
    openModal(
      <FillForm
        procedure={procedure}
        reload={reloadTable}
        onClose={onClose}
        openModal={openModal}
        rolId={rolId}
        step={step}
        typeId={typeId}
        handleSendProcedure={handleSendProcedure}
      />
    );
  }
  // function listDocumentPdf(procedure) {
  //   openDialog(<RenderPdf procedure={procedure} />)
  // }
  function generateFileDelivery(procedure) {
    openDialog(<GenerateFileDelivery procedure={procedure} />);
  }
  function handleSendProcedure(procedure, reloadTable) {
    openModal(
      <SendProcedure
        openModal={openModal}
        procedure={procedure}
        reload={reloadTable}
        onClose={onClose}
      />
    );
  }
  function generateSettlementProforma(procedure) {
    openModal(<RenderLiquidaciónProforma procedure={procedure} />);
  }
  function handleDocument(procedure) {
    openModal(<ListDocument procedure={procedure} />);
  }
  function handleDocumentVerify(procedure) {
    openModal(<ListDocumentVerify procedure={procedure} />);
  }
  function handleBreakdownDocument(procedure) {
    if (config.hostname === window.location.hostname) {
      console.log(
        user?.institutionParam?.find((i) => i.code === "mediaRequest")?.value
      );
    } else {
      console.log(
        user?.institutionParam?.find((i) => i.code === "mediaRequest2")?.value
      );
    }
    openDialog(
      <DialogConfirmation
        children={
          <H3>
            ¿Estas seguro que desea unir los documentos en un PDF?
            {/* <br />
            {
              config.hostname === window.location.hostname ?
              user?.institutionParam?.find(i => i.code === "mediaRequest")?.value
              :
              user?.institutionParam?.find(i => i.code === "mediaRequest2")?.value
            } */}
          </H3>
        }
        onClose={dialogClose}
        method="post"
        url={`/Utility/joinPdfs/${procedure.id}`}
        texBtn="Generar"
        handleSuccess={viewLinkDocument}
      />
    );
  }
  function viewLinkDocument(data) {
    openDialog(
      <div className="w-[300px]">
        <H3>Archivo generado</H3>
        <center>
          <Anchor
            className="text-[#264fbe] text-[1.4rem]"
            href={data.url}
            // href={`${validateUrlToOpen(data.url, user?.institutionParam)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Descargar archivo
          </Anchor>
        </center>
        <br />
      </div>
    );
  }

  return (
    <>
      <CallServiceTable
        urlApi={`/procedure/bystepbyproceduretypeid/${step}/proceduretypeid/${typeId}/rol/${rolId}`}
        downloadUrl={`/tradecruz/bystepbyproceduretypeid/${step}/proceduretypeid/${typeId}/rol/${rolId}`}
        // download={true}
        addUrl={`userId=${localStorage.getItem("id")}`}
        reload={reload}
        component={({ reload }) => {
          return step === "1" ? (
            <Button onClick={() => handleAdd(reload)}>Aperturar carpeta</Button>
          ) : null;
        }}
        filters={[
          {
            name: "companyId",
            url: "/company/getallcompanies",
            label: "Todas las compañías",
            valueOption: "id",
            labelOption: "razonSocial",
          },
          {
            name: "liquidator",
            url: "/Dropdown/rol_liquidator/options",
            label: "Todos los liquidadores",
            valueOption: "name",
            labelOption: "name",
          },
          {
            name: "gestor",
            url: "/Dropdown/rol_gestor/options",
            label: "Todos los gestores",
            valueOption: "name",
            labelOption: "name",
          },
          // {
          //   name: "inCharge",
          //   url: "/Dropdown/rol_InCharge/options",
          //   label: "Todos los encargados",
          //   valueOption: "id",
          //   labelOption: "name",
          // },
        ]}
        addFilters={addFiltersDefault}
        header={[
          ...headerList,
          {
            name: "Action",
            type: "custom",
            label: "Acciones",
            disabledOnClick: true,
            sticky: true,
            builder: (_, __, data, call, buttons) => {
              const listButton = buttons ? JSON.parse(buttons) : [];
              return (
                <div className="flex items-center justify-center gap-1 px-1">
                  <div
                    className={`text-red-500 text-xl cursor-pointer`}
                    onClick={() => handleSendProcedure(data, call)}
                  >
                    <Tooltip
                      title="Enviar"
                      position="right"
                      trigger="mouseenter"
                    >
                      <i className={"fas fa-share-square" + " p-1"}></i>
                    </Tooltip>
                  </div>
                  {listButton && validateArray(listButton) ? (
                    <TableLisDocument
                      call={call}
                      listButton={listButton}
                      procedure={data}
                    />
                  ) : null}
                </div>
              );
            },
          },
        ]}
        onClick={handleFillForm}
      />
    </>
  );
};

export default StepsTramites;
