import { useState } from "react";
import { Anchor, H3, Label } from "../Text";
import { toast } from "react-toastify";
import Circle from "../Animation/Circle";
import { Button } from "../Buttons";
import { Request } from "../../hooks/Request";
import TableComplete from "../Tables/TableComplete";
import { requestAuth } from "../services/RequestService";
import { useDialog } from "../../contexts/DialogContext";
import { DialogConfirmation } from "../Modal";
import CreatableSelect from "react-select/creatable";
import { validateArray } from "../../utils/validate";

const InputDocumentsDataAdd = ({ field }) => {
  const { openDialog, dialogClose } = useDialog();

  const { data, loading, call } = Request({
    urlApi: `/dataSet/procedure/${field.id}/field/${field.fieldId}/subdata`,
  });
  const {
    data: document,
    loading: loadDocument,
    call: callDocument,
  } = Request({
    urlApi: field.urlApi,
  });

  function handleForm(item) {
    openDialog(
      <>
        {loadDocument && (
          <FormAddDocument
            data={document}
            item={item}
            reload={call}
            field={field}
            onClose={dialogClose}
            callDocument={callDocument}
          />
        )}
        <br />
      </>
    );
  }

  function handleDelete(data) {
    openDialog(
      <DialogConfirmation
        children={<H3>¿Estas seguro que quieres eliminar este item?</H3>}
        onClose={dialogClose}
        method="delete"
        url={`/dataSet/procedure/subData/${data.id}`}
        texBtn="Eliminar"
        handleSuccess={call}
      />
    );
  }

  return (
    <div className="bg-gray-300/40 p-2 rounded-md">
      <Label htmlFor={field.name} className={`${field.className} my-0`}>
        {field.labelCustom ? field.labelCustom : field.label}
      </Label>
      <div className="bg-gray-100 rounded-lg p-3 pt-2">
        {loadDocument && (
          <FormAddDocument
            data={document}
            reload={call}
            field={field}
            onClose={() => {}}
            callDocument={callDocument}
          />
        )}
      </div>
      <br />
      {!loading ? (
        <Circle />
      ) : (
        <div className="w-full">
          <TableComplete
            header={[
              {
                name: "label",
                label: "Campo",
              },
              {
                name: "description",
                label: "Numero",
              },
              {
                name: "description2",
                label: "Enlace",
                type: "custom",
                builder: (item) => {
                  if (item) {
                    return (
                      <Anchor
                        href={item}
                        className="text-[#1d4ed8] underline"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Abrir
                      </Anchor>
                    );
                  } else {
                    return "-";
                  }
                },
              },
              {
                name: "description3",
                label: "Obs.",
              },
              {
                name: "creationDate",
                label: "Fecha / hora",
                type: "date",
              },
              {
                name: "add",
                type: "action",
                label: "Opciones",
                sticky: true,
                actions: [
                  {
                    label: "Editar",
                    icon: "fas  fa-edit",
                    action: handleForm,
                    color: "text-[#1d4ed8]",
                  },
                  {
                    label: "Añadir",
                    icon: "fas fa-trash",
                    action: handleDelete,
                    color: "text-red-500",
                  },
                ],
              },
            ]}
            data={data}
          />
        </div>
      )}
    </div>
  );
};

const FormAddDocument = (props) => {
  const { data, item = null, reload, field, onClose, callDocument } = props;
  const [values, setValues] = useState({
    Label: item?.label ? item.label : "",
    NumberValue: item?.numberValue ? item.numberValue : 0,
    Description: item?.description ? item.description : "",
    Description2: item?.description2 ? item.description2 : null,
    Description3: item?.description3 ? item.description3 : "",
    isNew: false,
  });
  const [load, setLoad] = useState(false);

  async function handleSubmitFile() {
    if (values.Description2) {
      setLoad(true);
      let formData = new FormData();
      formData.append("Name", values.label);
      formData.append("File", values.Description2);
      formData.append("originalName", values.Description2.name);
      formData.append("Status", "verified");
      formData.append("ProcedureId", field.id);
      await requestAuth("post", "/files", formData)
        .then((res) => {
          handleSubmit({
            ...values,
            Description2: res.data.url,
          });
        })
        .catch(() => {
          setLoad(false);
          toast.error("Se produjo un error al subir el archivo");
        });
    }
  }
  async function handleSubmit(values) {
    setLoad(true);
    if (values.isNew) {
      await handleCreateOption(values);
    }
    await requestAuth(
      item ? "put" : "post",
      item
        ? `/dataSet/procedure/subData/${item.id}`
        : `/dataSet/procedure/${field.id}/field/${field.fieldId}/subdata`,
      values
    )
      .then(() => {
        setLoad(false);
        reload();
        onClose();
        setValues({
          ...values,
          Label: "",
          NumberValue: 0,
          Description: "",
          Description2: null,
          Description3: "",
        });
        toast.success("Registro exitoso");
      })
      .catch(() => {
        setLoad(false);
        toast.error("Se produjo un error al guardar");
      });
  }

  async function handleCreateOption(value) {
    await requestAuth("post", "/Dropdown/Option", {
      Name: value.Label,
      DropdownListLabel: field.urlInitial,
    }).then(() => {
      callDocument();
    });
  }

  function handleChangeInp(e) {
    const { name, value } = e.target;
    setValues((a) => ({
      ...a,
      [name]: value,
    }));
  }

  function handleChangeFile(e) {
    const { name, files } = e.target;
    setValues((a) => ({
      ...a,
      [name]: files[0],
    }));
  }

  function handleChange(e) {
    setValues((a) => ({
      ...a,
      Label: e?.value ? e.value : "",
      isNew: e?.__isNew__ || false,
    }));
  }

  return (
    <form className={`flex flex-col ${item ? "max-w-lg" : ""}`}>
      <Label className={`mt-2`}>Lista de campos</Label>
      <CreatableSelect
        isSearchable={true}
        isClearable={true}
        onChange={handleChange}
        styles={{
          placeholder: (base) => ({
            ...base,
            color: "black",
          }),
        }}
        // defaultInputValue={values.Label}
        defaultValue={{
          value: values.Label,
          label: values.Label,
        }}
        placeholder={"Seleccionar..."}
        isOptionSelected={false}
        // isLoading={field.urlApi ? !loading : false}
        options={data.map((option) => ({
          value: option.name,
          label: option.name,
        }))}
      />
      <Label className={`my-0 mt-2`}>Numero</Label>
      <input
        value={values.Description}
        onChange={handleChangeInp}
        className="w-full p-2 rounded-md"
        type="text"
        name="Description"
      />
      <Label className={`my-0 mt-2`}>Archivo</Label>
      {item?.description2 ? (
        <H3 className="font-medium  text-start mb-[10px]">
          Archivo subido:{" "}
          <Anchor
            className="text-[#1d4ed8]"
            href={item.description2}
            target="_blank"
            rel="noopener noreferrer"
          >
            Ver archivo
          </Anchor>
        </H3>
      ) : null}
      <div
        className="rounded-[7px] placeholder-black placeholder:text-[15px]
        placeholder:opacity-50 placeholder:font-normal
        px-[17px] sm:py-[8px] font-light
        text-[15px] bg-white/50 md:bg-white/75"
      >
        <input
          onChange={handleChangeFile}
          type="file"
          className="text-sm text-grey-500
          file:mr-5 file:py-2 file:px-6
          file:rounded-full file:border-0
          file:text-sm file:font-medium
          file:bg-[#1d4ed8] file:text-white
          hover:file:cursor-pointer hover:file:bg-[#1d4fd8ce]"
          name="Description2"
        />
      </div>
      <Label className={`my-0 mt-2`}>Observación</Label>
      <div className="flex gap-2">
        <input
          value={values.Description3}
          onChange={handleChangeInp}
          className="w-full p-2 rounded-md"
          type="text"
          name="Description3"
        />
        <Button
          disabled={load}
          onClick={() =>
            typeof values.Description2 === "string"
              ? handleSubmit(values)
              : handleSubmitFile()
          }
          className="px-3 py-0 flex items-center gap-2"
        >
          {load ? <Circle /> : "Guardar"}
        </Button>
      </div>
    </form>
  );
};

export default InputDocumentsDataAdd;
